import { ElLoading } from 'element-plus'

let loading

export const showLoading = (to, from, next) => {
  // 预防下一个页面懒加载导致“无响应假象”
  loading = ElLoading.service({
    lock: true,
    text: 'Processing',
  })

  // 不能删
  next()
}

export const closeLoading = (to, from, next) => {
  if (loading) {
    loading.close()
  }
}
