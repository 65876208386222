import request from '@/utils/request'

/**
 * 走道列表
 */
export const shelfAwayList = () => {
  return request({
    url: '/shop/shelf/shelfAwayList',
    method: 'GET',
  })
}

/**
 * 湾列表
 */
export const shelfBayList = (id) => {
  return request({
    url: `/shop/shelf/shelfBayList/${id}`,
    method: 'GET',
  })
}

/**
 * 货架商品分页
 */
export const shelfGoodsList = (params) => {
  return request({
    url: '/shop/shelf/shelfGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 走道新增
 */
export const addShelfAway = (data) => {
  return request({
    url: '/shop/shelf/addShelfAway',
    method: 'POST',
    data,
  })
}

/**
 * 走道修改
 */
export const updateShelfAway = (data) => {
  return request({
    url: '/shop/shelf/updateShelfAway',
    method: 'POST',
    data,
  })
}

/**
 * 湾新增
 */
export const addShelfBay = (data) => {
  return request({
    url: '/shop/shelf/addShelfBay',
    method: 'POST',
    data,
  })
}

/**
 * 湾修改
 */
export const updateShelfBay = (data) => {
  return request({
    url: '/shop/shelf/updateShelfBay',
    method: 'POST',
    data,
  })
}

/**
 * 走道删除
 */
export const deleteShelfAway = (id) => {
  return request({
    url: `/shop/shelf/deleteShelfAway/${id}`,
    method: 'DELETE',
  })
}

/**
 * 湾删除
 */
export const deleteShelfBay = (id) => {
  return request({
    url: `/shop/shelf/deleteShelfBay/${id}`,
    method: 'DELETE',
  })
}

/**
 * 商品分类列表
 */
export const queryGoodsCategoryList = () => {
  return request({
    url: '/shop/shelf/queryGoodsCategoryList',
    method: 'GET',
  })
}

/**
 * 查询没有添加到货架中的商品
 */
export const queryNotInShelfGoodsList = (params) => {
  return request({
    url: '/shop/shelf/queryNotInShelfGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 上货架的商品详情
 */
export const toShelfGoodsDetails = (id) => {
  return request({
    url: `/shop/shelf/toShelfGoodsDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 上货架
 */
export const toSelf = (data) => {
  return request({
    url: '/shop/shelf/toSelf',
    method: 'POST',
    data,
  })
}

/**
 * 货架的商品详情
 */
export const shelfGoodsDetails = (id) => {
  return request({
    url: `/shop/shelf/shelfGoodsDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 货架商品编辑
 */
export const editShelfGoods = (data) => {
  return request({
    url: '/shop/shelf/editShelfGoods',
    method: 'POST',
    data,
  })
}

/**
 * 货架补货的商品详情
 */
export const replenishmentDetails = (id) => {
  return request({
    url: `/shop/shelf/replenishmentDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 货架补货
 */
export const replenishment = (data) => {
  return request({
    url: '/shop/shelf/replenishment',
    method: 'POST',
    data,
  })
}

/**
 * 退回仓库的商品详情
 */
export const returnWarehouseDetails = (id) => {
  return request({
    url: `/shop/shelf/returnWarehouseDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 退回仓库
 */
export const returnWarehouse = (data) => {
  return request({
    url: '/shop/shelf/returnWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 货架预警商品分页
 */
export const skuWarnList = (params) => {
  return request({
    url: '/shop/warn/skuWarnList',
    method: 'GET',
    params,
  })
}

/**
 * 货架预警补货的商品详情
 */
export const replenishmentDetailsWarn = (id) => {
  return request({
    url: `/shop/warn/replenishmentDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 货架预警补货
 */
export const replenishmentWarn = (data) => {
  return request({
    url: '/shop/warn/replenishment',
    method: 'POST',
    data,
  })
}

/**
 * 货架预警设置商品分页
 */
export const spuList = (params) => {
  return request({
    url: '/shop/warnsetting/spuList',
    method: 'GET',
    params,
  })
}

/**
 * 查询spu下的sku列表
 */
export const skuSettingList = (id, params) => {
  return request({
    url: `/shop/warnsetting/skuSettingList/${id}`,
    method: 'GET',
    params,
  })
}

/**
 * 货架预警设置
 */
export const setting = (data) => {
  return request({
    url: '/shop/warnsetting/setting',
    method: 'POST',
    data,
  })
}

/**
 * 查询没有添加到货架中的充值卡
 */
export const queryNotInShelfCardList = (params) => {
  return request({
    url: '/shop/shelf/queryNotInShelfCardList',
    method: 'GET',
    params,
  })
}

/**
 * 充值卡上货架的详情
 */
export const toShelfCardDetails = (id) => {
  return request({
    url: `/shop/shelf/toShelfCardDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 货架的充值卡详情
 */
export const shelfCardDetails = (id) => {
  return request({
    url: `/shop/shelf/shelfCardDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 充值卡上货架
 */
export const toSelfCard = (data) => {
  return request({
    url: '/shop/shelf/toSelfCard',
    method: 'POST',
    data,
  })
}

/**
 * 货架充值卡编辑
 */
export const editShelfCard = (data) => {
  return request({
    url: '/shop/shelf/editShelfCard',
    method: 'POST',
    data,
  })
}

/**
 * 货架补货的充值卡详情
 */
export const cardReplenishmentDetails = (id) => {
  return request({
    url: `/shop/shelf/cardReplenishmentDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 货架充值卡补货
 */
export const cardReplenishment = (data) => {
  return request({
    url: '/shop/shelf/cardReplenishment',
    method: 'POST',
    data,
  })
}

/**
 * 退回仓库的充值卡详情
 */
export const returnWarehouseCardDetails = (id) => {
  return request({
    url: `/shop/shelf/returnWarehouseCardDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 充值卡退回仓库
 */
export const cardReturnWarehouse = (data) => {
  return request({
    url: '/shop/shelf/cardReturnWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 出库信息分页
 */
export const shelfOutList = (params) => {
  return request({
    url: '/shop/shelfout/shelfOutList',
    method: 'GET',
    params,
  })
}

/**
 * 出库信息详情
 */
export const shelfOutDetail = (id) => {
  return request({
    url: `/shop/shelfout/shelfOutDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 根据条码查询对应的商品/充值卡
 */
export const shelfOutItemByCode = (params) => {
  return request({
    url: '/shop/shelfout/shelfOutItemByCode',
    method: 'GET',
    params,
  })
}

/**
 * 商品分类列表
 */
export const getGoodsCategoryList = () => {
  return request({
    url: '/shop/shelfout/getGoodsCategoryList',
    method: 'GET',
  })
}

/**
 * 出库新建筛选商品/充值卡分页/充值卡
 */
export const shelfOutItemPage = (params) => {
  return request({
    url: '/shop/shelfout/shelfOutItemPage',
    method: 'GET',
    params,
  })
}

/**
 * 新增出库单
 */
export const addShelfOut = (data) => {
  return request({
    url: '/shop/shelfout/addShelfOut',
    method: 'POST',
    data,
  })
}

/**
 * 批量转移
 */
export const batchTransfer = (data) => {
  return request({
    url: '/shop/shelf/batchTransfer',
    method: 'POST',
    data,
  })
}

/**
 * 走道湾拖拽排序
 */
export const newSorting = (data) => {
  return request({
    url: '/shop/shelf/newSorting',
    method: 'POST',
    data,
  })
}

/**
 * 货架移除商品
 */
export const removeFromShelf = (data) => {
  return request({
    url: '/shop/shelf/removeFromShelf',
    method: 'POST',
    data,
  })
}

/**
 * 货架移除多规格商品
 */
export const removeMultipleSpecificationsFromShelf = (data) => {
  return request({
    url: '/shop/shelf/removeMultipleSpecificationsFromShelf',
    method: 'POST',
    data,
  })
}

/**
 * 批量上货架商品sku列表
 */
export const batchShelfProductList = (data) => {
  return request({
    url: '/shop/shelf/batchShelfProductList',
    method: 'POST',
    data,
  })
}

/**
 * 批量上货架
 */
export const toSelfBatch = (data) => {
  return request({
    url: '/shop/shelf/toSelfBatch',
    method: 'POST',
    data,
  })
}

/**
 * 查询含有库存的商品列表
 */
export const getInventoryProductList = (id) => {
  return request({
    url: `/shop/shelf/getInventoryProductList/${id}`,
    method: 'GET',
  })
}
