import path from 'path'
import { jsonBig } from './json'
import router from '@/router'
import store from '@/store'

/**
 * 返回所有子路由
 */
const getChildrenRoutes = (routes) => {
  const result = []
  routes.forEach((route) => {
    if (route.children && route.children.length > 0) {
      result.push(...route.children)
    }
  })
  return result
}
/**
 * 处理脱离层级的路由：某个一级路由为其他子路由，则剔除该一级路由，保留路由层级
 * @param {*} routes router.getRoutes()
 */
export const filterRouters = (routes) => {
  const childrenRoutes = getChildrenRoutes(routes)
  return routes.filter((route) => {
    return !childrenRoutes.find((childrenRoute) => {
      return childrenRoute.path === route.path
    })
  })
}

/**
 * 判断数据是否为空值
 */
function isNull(data) {
  if (!data) return true
  if (JSON.stringify(data) === '{}') return true
  if (JSON.stringify(data) === '[]') return true
  return false
}
/**
 * 根据 routes 数据，返回对应 menu 规则数组
 */
export function generateMenus(routes, basePath = '') {
  const result = []
  // 遍历路由表
  routes.forEach((oldItem) => {
    const item = {}
    if (oldItem.meta) {
      item.meta = jsonBig.parse(JSON.stringify(oldItem.meta))
    }
    if (oldItem.children) {
      item.children = jsonBig.parse(JSON.stringify(oldItem.children))
    }
    if (oldItem.path) {
      item.path = jsonBig.parse(JSON.stringify(oldItem.path))
    }
    // 不存在 children && 不存在 meta 直接 return
    if (isNull(item.meta) && isNull(item.children)) return
    // 不能是 Shop 的
    if (item.meta.flag === 'shop') return
    delete item.meta.sortFlag
    // 存在 children 不存在 meta，进入迭代
    if (isNull(item.meta) && !isNull(item.children)) {
      result.push(...generateMenus(item.children))
      return
    }
    // 合并 path 作为跳转路径
    const routePath = path.resolve(basePath, item.path)
    // 路由分离之后，存在同名父路由的情况，需要单独处理
    let route = result.find((item) => item.path === routePath)
    if (!route) {
      route = {
        ...item,
        path: routePath,
        children: [],
      }

      // icon 与 title 必须全部存在
      if (route.meta.icon && route.meta.title) {
        // meta 存在生成 route 对象，放入 arr
        result.push(route)
      }
    }

    // 存在 children 进入迭代到children
    if (item.children) {
      route.children.push(...generateMenus(item.children, route.path))
    }
  })
  return result
}
/**
 * Shop 的根据 routes 数据，返回对应 menu 规则数组
 */
export function generateMenusByShop(routes, basePath = '') {
  const result = []
  // 遍历路由表
  routes.forEach((oldItem) => {
    const item = {}
    if (oldItem.meta) {
      item.meta = jsonBig.parse(JSON.stringify(oldItem.meta))
    }
    if (oldItem.children) {
      item.children = jsonBig.parse(JSON.stringify(oldItem.children))
    }
    if (oldItem.path) {
      item.path = jsonBig.parse(JSON.stringify(oldItem.path))
    }
    // 必须是 Shop 的
    if (item.meta.flag !== 'shop') return
    delete item.meta.flag
    delete item.meta.sortFlag
    // 不存在 children && 不存在 meta 直接 return
    if (isNull(item.meta) && isNull(item.children)) return
    // 存在 children 不存在 meta，进入迭代
    if (isNull(item.meta) && !isNull(item.children)) {
      result.push(...generateMenusByShop(item.children))
      return
    }
    // 合并 path 作为跳转路径
    const routePath = path.resolve(basePath, item.path)
    // 路由分离之后，存在同名父路由的情况，需要单独处理
    let route = result.find((item) => item.path === routePath)
    if (!route) {
      route = {
        ...item,
        path: routePath,
        children: [],
      }

      // icon 与 title 必须全部存在
      if (route.meta.icon && route.meta.title) {
        // meta 存在生成 route 对象，放入 arr
        result.push(route)
      }
    }

    // 存在 children 进入迭代到children
    if (item.children) {
      route.children.push(...generateMenusByShop(item.children, route.path))
    }
  })
  return result
}

/* const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
} */

/* const stringify = (obj, depth) => {
  const cache = new Set()
  return JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return
      }
      cache.add(value)
    }
    if (depth && depth <= key.split('.').length) {
      return
    }
    return value
  })
} */

export const genRouter = (resPerms, privateRoutes) => {
  // 清空第一次建立好的路由表，在切换店铺或做其他时要清空之前的
  for (const val in privateRoutes) {
    if (privateRoutes[val].children && privateRoutes[val].children.length > 0) {
      const children = privateRoutes[val].children
      let i = 0
      while (i < children.length) {
        if (children[i].meta && children[i].meta.resFlag) {
          children.splice(i, 1)
        } else {
          i++
        }
      }
    }
  }

  const finalPrivateRouteArr = []
  const finalPrivateRouteMap = {}

  resPerms.forEach((permName) => {
    const page = privateRoutes[permName]
    if (page.p) {
      // 存在父类
      const p = privateRoutes[page.p]
      p.children.push(page)
      if (!p.redirect) {
        p.redirect = page.path
      }
      finalPrivateRouteMap[page.p] = p
    } else {
      // 不存在父类
      finalPrivateRouteMap[permName] = page
    }
  })

  for (const val in finalPrivateRouteMap) {
    finalPrivateRouteArr.push(finalPrivateRouteMap[val])
  }

  return finalPrivateRouteArr
}

export const resetNotFound = (router) => {
  router.removeRoute('catchAllView')
  router.addRoute({
    name: 'catchAllView',
    path: '/:catchAll(.*)',
    redirect: '/404',
  })
}

export const clearNotFound = (router) => {
  router.removeRoute('catchAllView')
}

export const pushToConsiderCaching = (name, query) => {
  if (clearCacheList.includes(name)) {
    store.commit('app/closeNameTag', name)
  }
  router.push({ name, query })
}

const clearCacheList = [
  'GoodsListInfoViewGoodsView',
  'GoodsListInfoEditGoodsView',
  'GoodsClassifyDscView',
  'GoodsClassifyEditView',
  'RawMaterialClassifyDscView',
  'RawMaterialClassifyEditView',
  'OfflineOrderDesView',
  'PurchaseOperDetailsView',
  'PurchaseOperReviewView',
  'InboundDetailsView',
  'OutboundDetailsView',
  'PutToShelfView',
  'OperShelfGoodsView',
  'ViewShelfGoodsView',
  'ViewGuaranteeTemplateView',
  'EditGuaranteeTemplateView',
  'PushGoodsReleaseGoodsView',
  'ShelfOutboundDetailsView',
  'EditReleaseGoodsView',
  'ViewReleaseGoodsView',
  'OnlineOrderDesView',
  'OrderDeductionDetailsView',
  'PaymentDetailsView',
  'TipDetailsView',
  'TaxesDetailsView',
  'RefundDetailsView',
  'SummaryTableView',
]
