import request from '@/utils/request'

/**
 * 获取通知类型
 */
export const getGuaranteeServiceTypeList = () => {
  return request({
    url: '/shop/systemConfiguration/getNotifyType',
    method: 'GET',
  })
}

/**
 * 配置信息
 */
export const configurationInformation = () => {
  return request({
    url: '/shop/systemConfiguration/configurationInformation',
    method: 'GET',
  })
}

/**
 * 已配置信息
 */
export const configuredInformation = () => {
  return request({
    url: '/shop/systemConfiguration/configuredInformation',
    method: 'GET',
  })
}

/**
 * 保存通知配置
 */
export const saveNotification = (data) => {
  return request({
    url: '/shop/systemConfiguration/saveNotification',
    method: 'POST',
    data,
  })
}
