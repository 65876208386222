<template>
  <div>
    <!-- <el-badge v-if="notifyNum > 0" :value="notifyNum" :max="99" class="mr-20">
      <i
        class="icon-massho-b-tongzhi hover:cursor-pointer"
        style="font-size: 1.5rem"
        @click="openNotifyListDialog"
      />
    </el-badge>
    <i
      v-else
      class="icon-massho-b-tongzhi hover:cursor-pointer"
      style="font-size: 1.5rem"
      @click="openNotifyListDialog"
    /> -->

    <!-- 通知列表弹窗 -->
    <el-dialog
      :title="$t('msg.notifyManage.automaticOutboundNotification')"
      v-model="notifyListDialogFlag"
      width="35%"
    >
      <div>
        <!-- 顶部搜索框 -->
        <div>
          <el-row>
            <!-- 状态 -->
            <el-col :span="11">
              <div style="display: flex; align-items: baseline">
                <p>{{ $t('msg.common.status') }}:</p>
                <el-select class="ml-5" v-model="searchStatus" @change="search">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item"
                    :value="item.value"
                    :label="item.label"
                  />
                </el-select>
              </div>
            </el-col>
            <!-- 查询、重置按钮 -->
            <el-col class="ml-20" :span="8">
              <div>
                <el-row>
                  <el-button type="primary" @click="search">{{
                    $t('msg.common.search')
                  }}</el-button>
                  <el-button @click="resetSearch">{{
                    $t('msg.common.reset')
                  }}</el-button>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="mt-30">
          <div
            class="mt-20 text-[16px]"
            style="border-bottom: 1px solid #f2f2f2"
            v-for="item in notifyList"
            :key="item"
          >
            <div class="flex justify-between">
              <el-text
                class="text-[16px] hover:cursor-pointer w-2/3"
                @click="openNotifyDesDialog(item)"
                type="primary"
              >
                <LineText
                  class="line-clamp-2"
                  :copy="false"
                  :content="
                    $t('msg.indexManage.productNameSpecification') +
                    ': ' +
                    item.goodsName +
                    (item.goodsSku ? `/${item.goodsSku}` : '')
                  "
                />
              </el-text>
              <span class="w-1/3 flex justify-end">{{
                timestampFormatTzDatetime(item.createTime)
              }}</span>
            </div>
            <div class="my-10">
              <span>{{ $t('msg.notifyManage.quantityOfOutbound') }}:</span>
              <span class="ml-5">{{ item.number + ' ' + item.unitName }}</span>
            </div>
            <div v-if="item.userName && item.userName !== ''" class="my-10">
              <span>{{ $t('msg.stockManage.operator') }}:</span>
              <span class="ml-5">{{ item.userName }}</span>
            </div>
            <div v-if="item.status === 1" class="my-10 text-end">
              <el-button type="primary" @click="toVerify(item)">{{
                $t('msg.notifyManage.verified')
              }}</el-button>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div>
          <el-pagination
            :hide-on-single-page="false"
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </template>
    </el-dialog>

    <!-- 通知详情弹窗 -->
    <el-dialog
      :title="$t('msg.notifyManage.notice')"
      v-model="notifyDesDialogFlag"
      width="40%"
    >
      <div class="text-[18px]">
        <div>
          <span class="font-[700]">{{
            $t('msg.notifyManage.subject') + ': '
          }}</span>
          <span>{{
            $t('msg.notifyManage.automaticUpdateNotificationForProductOutbound')
          }}</span>
        </div>
        <div class="mt-10">
          {{ $t('msg.notifyManage.dearAdministrator') }}
        </div>
        <div class="font-[700] mt-10">
          {{ $t('msg.notifyManage.eventDetails') + ': ' }}
        </div>
        <div class="mt-10">
          {{ '· ' + $t('msg.notifyManage.triggerShelfStockDepleted') }}
        </div>
        <div class="mt-10">
          <span>
            {{ '· ' + $t('msg.indexManage.productNameSpecification') + ': ' }}
          </span>
          <span>
            {{
              currNotifyDes.goodsName +
              (currNotifyDes.goodsSku ? `(${currNotifyDes.goodsSku})` : '')
            }}
          </span>
        </div>
        <div class="mt-10">
          <span>{{
            '· ' + $t('msg.notifyManage.quantityOfOutbound') + ': '
          }}</span>
          <span>
            {{ currNotifyDes.number }}
          </span>
        </div>
        <div class="font-[700] mt-10">
          {{ $t('msg.notifyManage.requiredOperation') + ':' }}
        </div>
        <div class="mt-10">
          {{
            '1. ' +
            $t(
              'msg.notifyManage.verifyInventoryPleaseCarefullyCheckTheCurrentInventoryToEnsureAccuracy',
            )
          }}
        </div>
        <div class="mt-5">
          {{
            '2. ' +
            $t(
              'msg.notifyManage.adjustmentsWhenNecessaryIfAnyDiscrepanciesAreFound',
            )
          }}
        </div>
        <div class="mt-5">
          {{
            '3. ' +
            $t(
              'msg.notifyManage.confirmAndIgnoreIfTheInventoryHasBeenConfirmedAndNoDiscrepanciesAreFound',
            )
          }}
        </div>
        <div class="mt-20">
          {{
            $t(
              'msg.notifyManage.thankYouVeryMuchForYourPromptAttentionToThisMatter',
            )
          }}
        </div>
        <div class="mt-20">
          {{
            $t(
              'msg.notifyManage.thankYouForYourDedicationToMaintainingAccurateInventoryRecords',
            )
          }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
// import { loadStore, loadStoreNoLoading } from '@/utils/store'
// import { ref, reactive, onUnmounted } from 'vue'
import { loadStore } from '@/utils/store'
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
// import store from '@/store'
import LineText from '@/components/text/LineText.vue'
import { timestampFormatTzDatetime } from '@/utils/date'

const i18n = useI18n()

// onUnmounted(() => {
//   // 记得清除定时器
//   clearInterval(time)
// })

// const time = setInterval(() => {
//   if (store.getters.currLocation === 2) {
//     init()
//   }
// }, 5000)

// #region Init
// const notifyNum = ref(0)
// const init = () => {
//   loadStoreNoLoading('shopNotify/notificationCount').then((data) => {
//     notifyNum.value = data
//   })
// }
// init()
// #endregion

// #region 通知弹窗
const notifyListDialogFlag = ref(false)
// const openNotifyListDialog = () => {
//   initStatusOptions()
//   loadNotifyList()
//   notifyListDialogFlag.value = true
// }
// 加载通知数据
const notifyList = ref([])
const loadNotifyList = () => {
  initStatusOptions()
  // 加载通知数据
  loadStore('shopNotify/notificationList', {
    status: searchStatus.value,
    currentPage: currentPage.value,
    pageSize: pageSize.value,
  }).then((data) => {
    notifyList.value = data.records
    total.value = data.total
  })
}
const toVerify = (item) => {
  loadStore('shopNotify/verificationNotification', item.id).then((data) => {
    ElMessage.success(i18n.t('msg.common.success'))
    loadNotifyList()
  })
}
/* 顶部搜索 */
const searchStatus = ref('')
const statusOptions = reactive([])
const search = () => {
  loadNotifyList()
}
const resetSearch = () => {
  searchStatus.value = ''
  loadNotifyList()
}
const initStatusOptions = () => {
  statusOptions.splice(0, statusOptions.length)
  statusOptions.push({
    label: i18n.t('msg.common.all'),
    value: '',
  })
  statusOptions.push({
    label: i18n.t('msg.notifyManage.notVerified'),
    value: 1,
  })
  statusOptions.push({
    label: i18n.t('msg.notifyManage.verified'),
    value: 2,
  })
}
/* 分页 */
// 分页的 mode
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(0)
// 当前页
// 当前页改变
const handleCurrentChange = (val) => {
  currentPage.value = val
  loadNotifyList()
}
// 每页条数改变
const handleSizeChange = (val) => {
  pageSize.value = val
  loadNotifyList()
}
// #endregion

// #region 通知详情弹窗
const notifyDesDialogFlag = ref(false)
const currNotifyDes = ref({})
const openNotifyDesDialog = (item) => {
  currNotifyDes.value = item
  notifyDesDialogFlag.value = true
}
// #endregion
</script>

<style lang="scss" scoped></style>
