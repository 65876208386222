<template>
  <el-breadcrumb
    id="guide-breadcrumb"
    class="breadcrumb"
    :separator-icon="ArrowRight"
  >
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumbData"
        :key="item.path"
      >
        <!-- 不可点击 -->
        <span v-if="index === breadcrumbData.length - 1" class="no-redirect">{{
          generateTitle(item.meta.title)
        }}</span>
        <!-- 可点击 -->
        <a v-else class="redirect" @click="onLink(item)">{{
          generateTitle(item.meta.title)
        }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import router from '@/router'
import store from '@/store'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { generateTitle } from '@/utils/i18n'
import { ArrowRight } from '@element-plus/icons-vue'

const route = useRoute()

// 生成面包屑数据
const breadcrumbData = ref([])
const getBreadcrumbBdata = () => {
  breadcrumbData.value = route.matched.filter(
    (item) => item.meta && item.meta.title,
  )
}

// 监听面包屑数据
watch(route, getBreadcrumbBdata, {
  immediate: true,
})

const onLink = (item) => {
  router.push(item.path)
}

const linkHoverColor = ref(store.getters.cssVar.subMenuActiveText)
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  :deep(.no-redirect) {
    color: #97a8be;
    cursor: text;
  }

  .redirect {
    color: #666;
    font-weight: 600;
  }
  .redirect:hover {
    color: v-bind(linkHoverColor);
  }
}
</style>
