import { createI18n } from 'vue-i18n'
import zhLocale from './lang/zh'
import enLocale from './lang/en'
import { getCookie, setCookie } from '@/utils/cookies'
import { LANG } from '@/constant'
// #region 导入 vxe-table 语言包
import zhCNForVxeTable from 'vxe-table/lib/locale/lang/zh-CN'
import enUSForVxeTable from 'vxe-table/lib/locale/lang/en-US'
// #endregion

const messages = {
  'zh-CN': {
    msg: {
      ...zhLocale,
    },
    ...zhCNForVxeTable,
  },
  en: {
    msg: {
      ...enLocale,
    },
    ...enUSForVxeTable,
  },
}

const getLocale = () => {
  if (getCookie(LANG)) {
    return getCookie(LANG)
  } else {
    setCookie(LANG, 'en')
    return 'en'
  }
}

const i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为 false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: getLocale(),
  messages,
})

export default i18n
