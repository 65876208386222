import request from '@/utils/request'

/**
 * 获取在线商品列表
 * @returns 在线商品列表
 */
export const getOnlineGoodsList = (params) => {
  return request({
    url: '/shop/onlineGoods/getOnlineGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品列表为上线做准备
 */
export const getGoodsListByOnline = (params) => {
  return request({
    url: '/shop/onlineGoods/getGoodsListByOnline',
    method: 'GET',
    params,
  })
}

/**
 * 把线下商品上线
 */
export const onlineGoodsByOffline = (data) => {
  return request({
    url: '/shop/onlineGoods/onlineGoodsByOffline',
    method: 'POST',
    data,
  })
}

/**
 * 批量上架
 */
export const batchUp = (data) => {
  return request({
    url: '/shop/onlineGoods/batchUp',
    method: 'POST',
    data,
  })
}

/**
 * 批量下架
 */
export const batchDown = (data) => {
  return request({
    url: '/shop/onlineGoods/batchDown',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取在线商品详情
 */
export const getOnlineGoodsDesById = (id) => {
  return request({
    url: `/shop/onlineGoods/getOnlineGoodsDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 获取平台分类列表
 */
export const getPlatformClassifyList = () => {
  return request({
    url: '/shop/onlineGoods/getPlatformClassifyList',
    method: 'GET',
  })
}

/**
 * 上传商品
 */
export const uploadOnlineGoods = (data) => {
  return request({
    url: '/shop/onlineGoods/uploadOnlineGoods',
    method: 'POST',
    data,
  })
}

/**
 * 下架商品
 */
export const downOnlineGoods = (id) => {
  return request({
    url: `/shop/onlineGoods/downOnlineGoods/${id}`,
    method: 'POST',
  })
}

/**
 * 根据ID获取驳回原因
 */
export const getRebutReason = (id) => {
  return request({
    url: `/shop/onlineGoods/getRebutReason/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID删除在线商品
 */
export const deleteOnlineGoods = (id) => {
  return request({
    url: `/shop/onlineGoods/deleteOnlineGoods/${id}`,
    method: 'DELETE',
  })
}

/**
 * 根据ID获取Sku列表
 */
export const getSkuListById = (id) => {
  return request({
    url: `/shop/onlineGoods/getSkuListById/${id}`,
    method: 'GET',
  })
}

/**
 * 补货
 */
export const onlineGoodsReplenish = (data) => {
  return request({
    url: '/shop/onlineGoods/onlineGoodsReplenish',
    method: 'POST',
    data,
  })
}

/**
 * 获取平台分类列表详情
 */
export const getPlatformCategoryListDes = (params) => {
  return request({
    url: '/shop/onlineGoods/getPlatformCategoryListDes',
    method: 'GET',
    params,
  })
}

/**
 * 新增平台分类
 */
export const addPlatformCategory = (data) => {
  return request({
    url: '/shop/onlineGoods/addPlatformCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取平台分类详情
 */
export const getPlatformCategoryDesById = (id) => {
  return request({
    url: `/shop/onlineGoods/getPlatformCategoryDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 更新平台分类
 */
export const updatePlatformCategory = (data) => {
  return request({
    url: '/shop/onlineGoods/updatePlatformCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除平台分类详情
 */
export const deletePlatformCategoryById = (id) => {
  return request({
    url: `/shop/onlineGoods/deletePlatformCategoryById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取未发布的商品列表
 */
export const getUnpublishedGoods = (params) => {
  return request({
    url: '/shop/onlineGoods/getUnpublishedGoods',
    method: 'GET',
    params,
  })
}

/**
 * 批量发布商品
 */
export const publishGoods = (data) => {
  return request({
    url: '/shop/onlineGoods/publishGoods',
    method: 'POST',
    data,
  })
}

/**
 * 查询在线商品详情
 */
export const getGoodsOlDetails = (id) => {
  return request({
    url: `/shop/onlineGoods/getGoodsOlDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取未发布的商品详情
 */
export const getUnPublishGoodsDetails = (id) => {
  return request({
    url: `/shop/onlineGoods/getUnPublishGoodsDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 精准发布商品
 */
export const accurateReleaseGoods = (data) => {
  return request({
    url: '/shop/onlineGoods/accurateReleaseGoods',
    method: 'POST',
    data,
  })
}

/**
 * 编辑商品
 */
export const editOnLineGoods = (data) => {
  return request({
    url: '/shop/onlineGoods/editOnLineGoods',
    method: 'POST',
    data,
  })
}

/**
 * 批量上架或下架商品
 */
export const bulkOperationGoodsOl = (data) => {
  return request({
    url: '/shop/onlineGoods/bulkOperationGoodsOl',
    method: 'POST',
    data,
  })
}

/**
 * 删除已发布的商品
 */
export const deleteGoodsOl = (id) => {
  return request({
    url: `/shop/onlineGoods/deleteGoodsOl/${id}`,
    method: 'DELETE',
  })
}

/**
 * 补货sku列表
 */
export const getSkuSalesList = (id) => {
  return request({
    url: `/shop/onlineGoods/getSkuSalesList/${id}`,
    method: 'GET',
  })
}

/**
 * 补货
 */
export const addSalesLimit = (data) => {
  return request({
    url: '/shop/onlineGoods/addSalesLimit',
    method: 'POST',
    data,
  })
}

/**
 * 获取配送方式信息
 */
export const getDeliveryConfigured = () => {
  return request({
    url: '/shop/onlineGoods/getDeliveryConfigured',
    method: 'GET',
  })
}

/**
 * 根据ID获取在线商品Sku信息
 */
export const getOnlineSkuInfo = (params) => {
  return request({
    url: '/shop/onlineGoods/getOnlineSkuInfo',
    method: 'GET',
    params,
  })
}

/**
 * 获取未发布的充值卡列表
 */
export const getUnpublishedRechargeCardList = (params) => {
  return request({
    url: '/shop/onlineGoods/getUnpublishedRechargeCardList',
    method: 'GET',
    params,
  })
}

/**
 * 批量发布充值卡
 */
export const publishRechargeCard = (data) => {
  return request({
    url: '/shop/onlineGoods/publishRechargeCard',
    method: 'POST',
    data,
  })
}

/**
 * 获取线下充值卡详情
 */
export const getOfflineRechargeCardDetails = (id) => {
  return request({
    url: `/shop/onlineGoods/getOfflineRechargeCardDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 获取在线充值卡详情
 */
export const getRechargeCardDetails = (id) => {
  return request({
    url: `/shop/onlineGoods/getRechargeCardDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 精准发布充值卡
 */
export const editPublishRechargeCard = (data) => {
  return request({
    url: '/shop/onlineGoods/editPublishRechargeCard',
    method: 'POST',
    data,
  })
}

/**
 * 编辑在线充值卡
 */
export const editOnLineRechargeCard = (data) => {
  return request({
    url: '/shop/onlineGoods/editOnLineRechargeCard',
    method: 'POST',
    data,
  })
}

/**
 * 要上架的商品是否在货架
 */
export const ifOnShelf = (id) => {
  return request({
    url: `/shop/onlineGoods/ifOnShelf/${id}`,
    method: 'GET',
  })
}
