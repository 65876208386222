import request from '@/utils/request'

/**
 * 获取保障类型列表
 */
export const getGuaranteeServiceTypeList = (params) => {
  return request({
    url: '/shop/guaranteeServiceType/getGuaranteeServiceTypeList',
    method: 'GET',
    params,
  })
}

/**
 * 新增保障类型
 */
export const addGuaranteeServiceType = (data) => {
  return request({
    url: '/shop/guaranteeServiceType/addGuaranteeServiceType',
    method: 'POST',
    data,
  })
}

/**
 * 编辑保障类型
 */
export const editGuaranteeServiceType = (data) => {
  return request({
    url: '/shop/guaranteeServiceType/editGuaranteeServiceType',
    method: 'POST',
    data,
  })
}

/**
 * 删除保障类型
 */
export const deleteGuaranteeServiceType = (id) => {
  return request({
    url: `/shop/guaranteeServiceType/deleteGuaranteeServiceType/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取保障类型名称列表
 */
export const getGuaranteeServiceType = () => {
  return request({
    url: '/shop/guaranteeService/getGuaranteeServiceType',
    method: 'GET',
  })
}

/**
 * 获取保障服务列表
 */
export const getGuaranteeServiceList = (params) => {
  return request({
    url: '/shop/guaranteeService/getGuaranteeServiceList',
    method: 'GET',
    params,
  })
}

/**
 * 删除保障服务
 */
export const deleteGuaranteeService = (id) => {
  return request({
    url: `/shop/guaranteeService/deleteGuaranteeService/${id}`,
    method: 'DELETE',
  })
}

/**
 * 新增保障服务
 */
export const addGuaranteeService = (data) => {
  return request({
    url: '/shop/guaranteeService/addGuaranteeService',
    method: 'POST',
    data,
  })
}

/**
 * 获取保障服务详情
 */
export const getGuaranteeServiceDetails = (id) => {
  return request({
    url: `/shop/guaranteeService/getGuaranteeServiceDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 编辑保障服务
 */
export const editGuaranteeService = (data) => {
  return request({
    url: '/shop/guaranteeService/editGuaranteeService',
    method: 'POST',
    data,
  })
}
