import request from '@/utils/request'

/**
 * 获取钱包现金列表汇总
 */
export const getWalletCash = (params) => {
  return request({
    url: '/shop/wallet/getWalletCash',
    method: 'GET',
    params,
  })
}

/**
 * 获取钱包现金列表详情
 */
export const getWalletCashListDes = (params) => {
  return request({
    url: '/shop/wallet/getWalletCashListDes',
    method: 'GET',
    params,
  })
}

/**
 * 获取钱包Macou列表汇总
 */
export const getWalletMacou = () => {
  return request({
    url: '/shop/wallet/getWalletMacou',
    method: 'GET',
  })
}

/**
 * 获取钱包Macou列表详情
 */
export const getWalletMacouListDes = (params) => {
  return request({
    url: '/shop/wallet/getWalletMacouListDes',
    method: 'GET',
    params,
  })
}

/**
 * 检查账号
 */
export const checkingUsername = (data) => {
  return request({
    url: '/shop/wallet/checkingUsername',
    method: 'POST',
    data,
  })
}

/**
 * 开始转账
 */
export const startTransfer = (data) => {
  return request({
    url: '/shop/wallet/startTransfer',
    method: 'POST',
    data,
  })
}

/**
 * 获取抵押资金列表详情
 */
export const getMortgageFundsListDes = (params) => {
  return request({
    url: '/shop/wallet/getMortgageFundsListDes',
    method: 'GET',
    params,
  })
}

/**
 * 获取抵押资金银行卡列表详情
 */
export const getMortgageFundsCardListDes = () => {
  return request({
    url: '/shop/wallet/getMortgageFundsCardListDes',
    method: 'GET',
  })
}

/**
 * 获取可赎回金额
 */
export const getRedeemableAmount = () => {
  return request({
    url: '/shop/wallet/getRedeemableAmount',
    method: 'GET',
  })
}

/**
 * 提交赎回请求
 */
export const submitRedeemReq = (data) => {
  return request({
    url: '/shop/wallet/submitRedeemReq',
    method: 'POST',
    data,
  })
}

/**
 * 信用评估
 */
export const getCreditEvaluation = () => {
  return request({
    url: '/shop/credit/getCreditEvaluation',
    method: 'GET',
  })
}

/**
 * 是否授权信用评估
 */
export const getAuthorizationQuery = () => {
  return request({
    url: '/shop/credit/getAuthorizationQuery',
    method: 'GET',
  })
}

/**
 * 额度查询
 */
export const getQuotaInquiry = () => {
  return request({
    url: '/shop/credit/getQuotaInquiry',
    method: 'GET',
  })
}

/**
 * 评估记录
 */
export const getEvaluationRecord = () => {
  return request({
    url: '/shop/credit/getEvaluationRecord',
    method: 'GET',
  })
}

/**
 * 借据
 */
export const getReceiptForLoan = () => {
  return request({
    url: '/shop/credit/getReceiptForLoan',
    method: 'GET',
  })
}

/**
 * 借据-新合同信息
 */
export const getNewContractInformation = () => {
  return request({
    url: '/shop/credit/getNewContractInformation',
    method: 'GET',
  })
}

/**
 * 借款
 */
export const loan = (data) => {
  return request({
    url: '/shop/credit/loan',
    method: 'POST',
    data,
  })
}

/**
 * 还款信息
 */
export const getRepaymentInfo = (params) => {
  return request({
    url: '/shop/credit/getRepaymentInfo',
    method: 'GET',
    params,
  })
}

/**
 * 还款
 */
export const repayment = (data) => {
  return request({
    url: '/shop/credit/repayment',
    method: 'POST',
    data,
  })
}

/**
 * 借款记录
 */
export const getLoanPage = (params) => {
  return request({
    url: '/shop/credit/getLoanPage',
    method: 'GET',
    params,
  })
}

/**
 * 合同回显详情
 */
export const getContractInformation = (id) => {
  return request({
    url: `/shop/credit/getContractInformation/${id}`,
    method: 'GET',
  })
}

/**
 * 获取店铺的欠款记录
 */
export const getDebtDetailsList = (params) => {
  return request({
    url: '/shop/credit/getDebtDetailsList',
    method: 'GET',
    params,
  })
}
