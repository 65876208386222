import {
  getWalletCash,
  getWalletCashListDes,
  getWalletMacou,
  getWalletMacouListDes,
  checkingUsername,
  startTransfer,
  getMortgageFundsListDes,
  getMortgageFundsCardListDes,
  getRedeemableAmount,
  submitRedeemReq,
  getCreditEvaluation,
  getAuthorizationQuery,
  getQuotaInquiry,
  getEvaluationRecord,
  getReceiptForLoan,
  getNewContractInformation,
  loan,
  getRepaymentInfo,
  repayment,
  getLoanPage,
  getContractInformation,
  getDebtDetailsList,
} from '@/api/shop/wallet'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getWalletCash(context) {
      return await getWalletCash()
    },
    async getWalletCashListDes(context, params) {
      return await getWalletCashListDes(params)
    },
    async getWalletMacou(context) {
      return await getWalletMacou()
    },
    async getWalletMacouListDes(context, params) {
      return await getWalletMacouListDes(params)
    },
    async checkingUsername(context, data) {
      return await checkingUsername(data)
    },
    async startTransfer(context, data) {
      return await startTransfer(data)
    },
    async getMortgageFundsListDes(context, params) {
      return await getMortgageFundsListDes(params)
    },
    async getMortgageFundsCardListDes(context) {
      return await getMortgageFundsCardListDes()
    },
    async getRedeemableAmount(context) {
      return await getRedeemableAmount()
    },
    async submitRedeemReq(context, data) {
      return await submitRedeemReq(data)
    },
    async getCreditEvaluation(context) {
      return await getCreditEvaluation()
    },
    async getAuthorizationQuery(context) {
      return await getAuthorizationQuery()
    },
    async getQuotaInquiry(context) {
      return await getQuotaInquiry()
    },
    async getEvaluationRecord(context) {
      return await getEvaluationRecord()
    },
    async getReceiptForLoan(context) {
      return await getReceiptForLoan()
    },
    async getNewContractInformation(context) {
      return await getNewContractInformation()
    },
    async loan(context, data) {
      return await loan(data)
    },
    async getRepaymentInfo(context, params) {
      return await getRepaymentInfo(params)
    },
    async repayment(context, data) {
      return await repayment(data)
    },
    async getLoanPage(context, params) {
      return await getLoanPage(params)
    },
    async getContractInformation(context, id) {
      return await getContractInformation(id)
    },
    async getDebtDetailsList(context, params) {
      return await getDebtDetailsList(params)
    },
  },
}
