import {
  getAnalysisReportList,
  getSaleChart,
  getOrderQuantityChart,
  getHotChart,
  getShopRanking,
  homeIndex,
  analysisReport,
  getTaskCount,
} from '@/api/shop/index'

export default {
  namespaced: true,
  state: () => ({
    analysisReportList: [],
  }),
  mutations: {
    setAnalysisReportList(state, analysisReportList) {
      state.analysisReportList = analysisReportList
    },
  },
  actions: {
    // 获取分析报告列表
    async getAnalysisReportList(context, params) {
      const analysisReportList = await getAnalysisReportList(params)
      this.commit('shopIndex/setAnalysisReportList', analysisReportList)
    },
    async getSaleChart(context, timeInterval) {
      return await getSaleChart(timeInterval)
    },
    async getOrderQuantityChart(context, timeInterval) {
      return await getOrderQuantityChart(timeInterval)
    },
    async getHotChart(context, timeInterval) {
      return await getHotChart(timeInterval)
    },
    async getShopRanking(context, timeInterval) {
      return await getShopRanking(timeInterval)
    },
    async homeIndex(context) {
      return await homeIndex()
    },
    async analysisReport(context, params) {
      return await analysisReport(params)
    },
    async getTaskCount(context) {
      return await getTaskCount()
    },
  },
}
