import {
  checkWriteOwn,
  uploadSignature,
  saveBusinessInfo,
  ownInfo,
  updateBusinessInfo,
} from '@/api/org/user'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async checkWriteOwn(context) {
      return await checkWriteOwn()
    },
    async uploadSignature(context, data) {
      return await uploadSignature(data)
    },
    async saveBusinessInfo(context, data) {
      return await saveBusinessInfo(data)
    },
    async ownInfo(context) {
      return await ownInfo()
    },
    async updateBusinessInfo(context, data) {
      return await updateBusinessInfo(data)
    },
  },
}
