import request from '@/utils/request'

/**
 * 获取分析报告列表
 * @returns 分析报告列表
 */
export const getAnalysisReportList = (params) => {
  return request({
    url: '/shop/index/getAnalysisReportList',
    method: 'GET',
    params,
  })
}

/**
 * 获取销售额图表数据
 */
export const getSaleChart = (params) => {
  return request({
    url: '/shop/index/getSaleChart',
    method: 'GET',
    params,
  })
}

/**
 * 获取订单数图表数据
 */
export const getOrderQuantityChart = (params) => {
  return request({
    url: '/shop/index/getOrderQuantityChart',
    method: 'GET',
    params,
  })
}

/**
 * 获取热销商品图表数据
 */
export const getHotChart = (params) => {
  return request({
    url: '/shop/index/getHotChart',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品排名数据
 */
export const getShopRanking = (params) => {
  return request({
    url: '/shop/index/getShopRanking',
    method: 'GET',
    params,
  })
}

/**
 * 首页
 */
export const homeIndex = () => {
  return request({
    url: '/shop/homeIndex',
    method: 'GET',
  })
}

/**
 * 分析报告
 */
export const analysisReport = (params) => {
  return request({
    url: '/shop/analysisReport',
    method: 'GET',
    params,
  })
}

/**
 * 获取拣货任务的数量
 */
export const getTaskCount = () => {
  return request({
    url: '/shop/getTaskCount',
    method: 'GET',
  })
}
