import request from '@/utils/request'

/**
 * 获取经营报表-收款构成
 */
export const getCollectionComposition = (params) => {
  return request({
    url: '/shop/report/getCollectionComposition',
    method: 'GET',
    params,
  })
}

/**
 * 获取经营报表-退款统计
 */
export const getRefundStatistics = (params) => {
  return request({
    url: '/shop/report/getRefundStatistics',
    method: 'GET',
    params,
  })
}

/**
 * 获取汇总表
 */
export const getSummaryTable = (params) => {
  return request({
    url: '/shop/report/getSummaryTable',
    method: 'GET',
    params,
  })
}

/**
 * 汇总表-总计
 */
export const getSummaryTableCount = (params) => {
  return request({
    url: '/shop/report/getSummaryTableCount',
    method: 'GET',
    params,
  })
}

/**
 * 获取订单扣款明细
 */
export const getOrderDeductionDetails = (params) => {
  return request({
    url: '/shop/report/getOrderDeductionDetails',
    method: 'GET',
    params,
  })
}

/**
 * 获取渠道明细
 */
export const getChannelDetails = (params) => {
  return request({
    url: '/shop/report/getChannelDetails',
    method: 'GET',
    params,
  })
}

/**
 * 渠道明细-合计
 */
export const getChannelDetailsCount = (params) => {
  return request({
    url: '/shop/report/getChannelDetailsCount',
    method: 'GET',
    params,
  })
}

/**
 * 获取小费明细
 */
export const getTipDetails = (params) => {
  return request({
    url: '/shop/report/getTipDetails',
    method: 'GET',
    params,
  })
}

/**
 * 小费明细-合计
 */
export const getTipDetailsCount = (params) => {
  return request({
    url: '/shop/report/getTipDetailsCount',
    method: 'GET',
    params,
  })
}

/**
 * 获取退款明细
 */
export const getRefundDetails = (params) => {
  return request({
    url: '/shop/report/getRefundDetails',
    method: 'GET',
    params,
  })
}

/**
 * 获取税费明细
 */
export const getTaxDetails = (params) => {
  return request({
    url: '/shop/report/getTaxDetails',
    method: 'GET',
    params,
  })
}

/**
 * 税费明细-合计
 */
export const getTaxDetailsCount = (params) => {
  return request({
    url: '/shop/report/getTaxDetailsCount',
    method: 'GET',
    params,
  })
}
