import request from '@/utils/request'

/**
 * 获取商品列表
 * @returns 商品列表
 */
export const getGoodsList = (params) => {
  return request({
    url: '/shop/goods/getGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品 SKU 详情
 */
export const getGoodsSkuList = (params) => {
  return request({
    url: '/shop/goods/getGoodsSkuList',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品分类
 * @returns 商品分类
 */
export const getGoodsCategoryList = () => {
  return request({
    url: '/shop/goods/getGoodsCategoryList',
    method: 'GET',
  })
}

/**
 * 获取商品计量单位
 * @returns 商品计量单位
 */
export const getGoodsUnitList = () => {
  return request({
    url: '/shop/goods/getGoodsUnitList',
    method: 'GET',
  })
}

/**
 * 新增商品
 */
export const addGoods = (data) => {
  return request({
    url: '/shop/goods/addGoods',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取商品详情
 */
export const getGoodsDesById = (id) => {
  return request({
    url: `/shop/goods/getGoodsDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 更新商品
 */
export const updateGoods = (data) => {
  return request({
    url: '/shop/goods/updateGoods',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除商品
 */
export const deleteGoodsById = (id) => {
  return request({
    url: `/shop/goods/deleteGoodsById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取商品分类列表详情
 */
export const getGoodsCategoryListDes = (params) => {
  return request({
    url: '/shop/goods/getGoodsCategoryListDes',
    method: 'GET',
    params,
  })
}

/**
 * 新增商品分类
 */
export const addGoodsCategory = (data) => {
  return request({
    url: '/shop/goods/addGoodsCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取商品分类详情
 */
export const getGoodsCategoryDesById = (id) => {
  return request({
    url: `/shop/goods/getGoodsCategoryDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取商品分类关联的商品
 */
export const getRelationGoodsById = (params) => {
  return request({
    url: '/shop/goods/getRelationGoodsById',
    method: 'GET',
    params,
  })
}

/**
 * 更新商品分类
 */
export const updateGoodsCategory = (data) => {
  return request({
    url: '/shop/goods/updateGoodsCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除商品分类详情
 */
export const deleteGoodsCategoryById = (id) => {
  return request({
    url: `/shop/goods/deleteGoodsCategoryById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取原材料分类列表详情
 */
export const getRawMaterialCategoryListDes = (params) => {
  return request({
    url: '/shop/goods/getRawMaterialCategoryListDes',
    method: 'GET',
    params,
  })
}

/**
 * 新增原材料分类
 */
export const addRawMaterialCategory = (data) => {
  return request({
    url: '/shop/goods/addRawMaterialCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取原材料分类详情
 */
export const getRawMaterialCategoryDesById = (id) => {
  return request({
    url: `/shop/goods/getRawMaterialCategoryDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取原材料分类关联的原材料
 */
export const getRelationRawMaterialById = (params) => {
  return request({
    url: '/shop/goods/getRelationRawMaterialById',
    method: 'GET',
    params,
  })
}

/**
 * 更新原材料分类
 */
export const updateRawMaterialCategory = (data) => {
  return request({
    url: '/shop/goods/updateRawMaterialCategory',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除原材料分类详情
 */
export const deleteRawMaterialCategoryById = (id) => {
  return request({
    url: `/shop/goods/deleteRawMaterialCategoryById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取原材料列表详情
 */
export const getRawMaterialListDes = (params) => {
  return request({
    url: '/shop/goods/getRawMaterialListDes',
    method: 'GET',
    params,
  })
}

/**
 * 获取原材料分类列表
 */
export const getRawMaterialCategoryList = () => {
  return request({
    url: '/shop/goods/getRawMaterialCategoryList',
    method: 'GET',
  })
}

/**
 * 获取计量单位列表
 */
export const getMeasureUnitOptionsList = () => {
  return request({
    url: '/shop/goods/getMeasureUnitOptionsList',
    method: 'GET',
  })
}

/**
 * 新增原材料
 */
export const addRawMaterial = (data) => {
  return request({
    url: '/shop/goods/addRawMaterial',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取原材料详情
 */
export const getRawMaterialDesById = (id) => {
  return request({
    url: `/shop/goods/getRawMaterialDesById/${id}`,
    method: 'GET',
  })
}

/**
 * 更新原材料
 */
export const updateRawMaterial = (data) => {
  return request({
    url: '/shop/goods/updateRawMaterial',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除原材料
 */
export const deleteRawMaterialById = (id) => {
  return request({
    url: `/shop/goods/deleteRawMaterialById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取计量单位列表详情
 */
export const getMeasureUnitListDes = (params) => {
  return request({
    url: '/shop/goods/getMeasureUnitListDes',
    method: 'GET',
    params,
  })
}

/**
 * 新增计量单位
 */
export const addMeasureUnit = (data) => {
  return request({
    url: '/shop/goods/addMeasureUnit',
    method: 'POST',
    data,
  })
}

/**
 * 更改计量单位状态
 */
export const updateMeasureUnitStatus = (data) => {
  return request({
    url: '/shop/goods/updateMeasureUnitStatus',
    method: 'POST',
    data,
  })
}

/**
 * 获取店铺税率
 */
export const getShopTaxRate = () => {
  return request({
    url: '/shop/goods/getShopTaxRate',
    method: 'GET',
  })
}

/**
 * 根据条形码获取商品sku信息
 */
export const getGoodsDesByCode = (data) => {
  return request({
    url: '/shop/goods/getGoodsDesByCode',
    method: 'POST',
    data,
  })
}

/**
 * 获取货架列表
 */
export const getAreaList = (params) => {
  return request({
    url: '/shop/storage/getAreaList',
    method: 'GET',
    params,
  })
}

/**
 * 新增货架区域
 */
export const addStorageRackArea = (data) => {
  return request({
    url: '/shop/storage/addStorageRackArea',
    method: 'POST',
    data,
  })
}

/**
 * 获取区域下的货架号列表
 */
export const getStorageRackList = (params) => {
  return request({
    url: `/shop/storage/getStorageRackList/${params}`,
    method: 'GET',
  })
}

/**
 * 添加货架号
 */
export const addStorageRackNumber = (data) => {
  return request({
    url: '/shop/storage/addStorageRackNumber',
    method: 'POST',
    data,
  })
}

/**
 * 获取区域下详情
 */
export const getAreaDetail = (id) => {
  return request({
    url: `/shop/storage/getAreaDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID删除区域
 */
export const deleteStorageRackArea = (id) => {
  return request({
    url: `/shop/storage/deleteStorageRackArea/${id}`,
    method: 'DELETE',
  })
}

/**
 * 修改货架号
 */
export const updateStorageRackNumber = (data) => {
  return request({
    url: '/shop/storage/updateStorageRackNumber',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID删除货架号
 */
export const deleteStorageRackNumber = (id) => {
  return request({
    url: `/shop/storage/deleteStorageRackNumber/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取货架号关联的商品列表
 */
export const getGoodSkuList = (params) => {
  return request({
    url: '/shop/storage/getGoodSkuList',
    method: 'GET',
    params,
  })
}

/**
 * 获取区域下的货架号 下拉框
 */
export const getStorageRackNumberList = (id) => {
  return request({
    url: `/shop/storage/getStorageRackNumberList/${id}`,
    method: 'GET',
  })
}

/**
 * 获取货架区域名称
 */
export const getStorageRackAreaList = () => {
  return request({
    url: '/shop/goods/getStorageRackAreaList',
    method: 'GET',
  })
}

/**
 * 获取货架号名称
 */
export const getStorageRackNumberListById = (id) => {
  return request({
    url: `/shop/goods/getStorageRackNumberList/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取商品详情
 */
export const getGoodsDesByIdV2 = (id) => {
  return request({
    url: `/shop/goods/getGoodsDesByIdV2/${id}`,
    method: 'GET',
  })
}

/**
 * 新增商品
 */
export const addGoodsV2 = (data) => {
  return request({
    url: '/shop/goods/addGoodsV2',
    method: 'POST',
    data,
  })
}

/**
 * 更新商品
 */
export const updateGoodsV2 = (data) => {
  return request({
    url: '/shop/goods/updateGoodsV2',
    method: 'POST',
    data,
  })
}

/**
 * 检查商品是否发布
 */
export const checkGoodsPublishStatus = (id) => {
  return request({
    url: `/shop/goods/checkGoodsPublishStatus/${id}`,
    method: 'GET',
  })
}

/**
 * 查询店铺下所有的加油卡信息
 */
export const getFuelCardList = (params) => {
  return request({
    url: '/shop/fuelCard/getFuelCardList',
    method: 'GET',
    params,
  })
}

/**
 * 添加加油卡
 */
export const addFuelCard = (data) => {
  return request({
    url: '/shop/fuelCard/addFuelCard',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID查询加油卡
 */
export const getFuelCardById = (id) => {
  return request({
    url: `/shop/fuelCard/getFuelCardById/${id}`,
    method: 'GET',
  })
}

/**
 * 编辑加油卡
 */
export const updateFuelCard = (data) => {
  return request({
    url: '/shop/fuelCard/updateFuelCard',
    method: 'POST',
    data,
  })
}

/**
 * 删除加油卡
 */
export const deleteFuelCard = (id) => {
  return request({
    url: `/shop/fuelCard/deleteFuelCard/${id}`,
    method: 'DELETE',
  })
}

/**
 * 获取商品包装计量单位
 */
export const getGoodsPackUnitList = () => {
  return request({
    url: '/shop/goods/getGoodsPackUnitList',
    method: 'GET',
  })
}

/**
 * 获取保障类型列表
 */
export const getGuaranteeTypeList = () => {
  return request({
    url: '/shop/goods/getGuaranteeTypeList',
    method: 'GET',
  })
}

/**
 * 获取保障名称列表
 */
export const getGuaranteeNameList = (id) => {
  return request({
    url: `/shop/goods/getGuaranteeNameList/${id}`,
    method: 'GET',
  })
}

/**
 * 获取保障服务详情
 */
export const getGuaranteeDetail = (id) => {
  return request({
    url: `/shop/goods/getGuaranteeDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 获取计量包装单位列表
 */
export const getMeasureUnitPackOptionsList = () => {
  return request({
    url: '/shop/goods/getMeasureUnitPackOptionsList',
    method: 'GET',
  })
}

/**
 * 获取商品分类lable
 */
export const getGoodsCategoryLable = () => {
  return request({
    url: '/shop/goods/getGoodsCategoryLable',
    method: 'GET',
  })
}

/**
 * 获取原材料关联的sku列表
 */
export const getSkuListByRawMaterial = (params) => {
  return request({
    url: '/shop/goods/getSkuListByRawMaterial',
    method: 'GET',
    params,
  })
}
