import request from '@/utils/request'

/**
 * 获取邮编列表
 */
export const getOnlineGoodsList = () => {
  return request({
    url: '/shop/shopManage/getPostCodeOptions',
    method: 'GET',
  })
}

/**
 * 获取备餐时间
 */
export const getTimePeriod = () => {
  return request({
    url: '/shop/shopManage/getTimePeriod',
    method: 'GET',
  })
}

/**
 * 获取店铺配置
 */
export const getShopConfig = () => {
  return request({
    url: '/shop/shopManage/getShopConfig',
    method: 'GET',
  })
}

/**
 * 获取店铺银行卡
 */
export const getCards = () => {
  return request({
    url: '/shop/shopManage/getCards',
    method: 'GET',
  })
}

/**
 * 保存店铺配置
 */
export const saveShopConfig = (data) => {
  return request({
    url: '/shop/shopManage/saveShopConfig',
    method: 'POST',
    data,
  })
}

/**
 * 添加银行卡
 */
export const addCard = (data) => {
  return request({
    url: '/shop/shopManage/addCard',
    method: 'POST',
    data,
  })
}

/**
 * 获取服务行业列表
 */
export const getServiceIndustryList = () => {
  return request({
    url: '/shop/shopManage/getServiceIndustryList',
    method: 'GET',
  })
}

/**
 * 获取店铺信息
 */
export const getShopInfo = () => {
  return request({
    url: '/shop/shopManage/getShopInfo',
    method: 'GET',
  })
}

/**
 * 发送邮件验证码
 */
export const sendEmailCode = (data) => {
  return request({
    url: '/shop/shopManage/sendEmailCode',
    method: 'POST',
    data,
  })
}

/**
 * 验证邮箱验证码
 */
export const checkingEmailCode = (data) => {
  return request({
    url: '/shop/shopManage/checkingEmailCode',
    method: 'POST',
    data,
  })
}

/**
 * 发送店铺手机号验证码
 */
export const sendShopPhoneCode = (data) => {
  return request({
    url: '/shop/shopManage/sendShopPhoneCode',
    method: 'POST',
    data,
  })
}

/**
 * 验证店铺手机号验证码
 */
export const checkingShopPhoneCode = (data) => {
  return request({
    url: '/shop/shopManage/checkingShopPhoneCode',
    method: 'POST',
    data,
  })
}

/**
 * 保存店铺信息
 */
export const saveShopInfo = (data) => {
  return request({
    url: '/shop/shopManage/saveShopInfo',
    method: 'POST',
    data,
  })
}

/**
 * 获取店铺去关联 Square 信息
 */
export const genAuthorizeUrl = () => {
  return request({
    url: '/shop/genAuthorizeUrl',
    method: 'GET',
  })
}

/**
 * 获取店铺去关联 Square 信息
 */
export const callback = (params) => {
  return request({
    url: '/oauth/callback',
    method: 'GET',
    params,
  })
}

/**
 * 店铺基本配置信息是否完毕
 */
export const shopConfigurationCompleted = () => {
  return request({
    url: '/shop/shopConfigurationCompleted',
    method: 'GET',
  })
}

/**
 * 获取收银台列表
 */
export const getCashierCounterList = () => {
  return request({
    url: '/shop/getCashierCounterList',
    method: 'GET',
  })
}

/**
 * 新增收银台
 */
export const addCashierCounter = (data) => {
  return request({
    url: '/shop/addCashierCounter',
    method: 'POST',
    data,
  })
}

/**
 * 给收银台绑定 POS 机
 */
export const bindingPos = (id) => {
  return request({
    url: `/shop/bindingPos/${id}`,
    method: 'POST',
  })
}

/**
 * 获取可用收银台
 */
export const getAvailableCashierCounterList = () => {
  return request({
    url: '/shop/getAvailableCashierCounterList',
    method: 'GET',
  })
}

/**
 * 获取可用并且带有打票机的收银台
 */
export const getAvailableAndPrintCashierCounterList = () => {
  return request({
    url: '/shop/getAvailableAndPrintCashierCounterList',
    method: 'GET',
  })
}

/**
 * 获取店铺配置V2
 */
export const getShopConfigV2 = () => {
  return request({
    url: '/shop/shopManage/v2/getShopConfig',
    method: 'GET',
  })
}

/**
 * 保存店铺配置V2
 */
export const saveShopConfigV2 = (data) => {
  return request({
    url: '/shop/shopManage/v2/saveShopConfig',
    method: 'POST',
    data,
  })
}

/**
 * 获取店铺信息V2
 */
export const getShopInfoV2 = () => {
  return request({
    url: '/shop/shopManage/v2/getShopInfo',
    method: 'GET',
  })
}

/**
 * 保存店铺信息
 */
export const saveShopInfoV2 = (data) => {
  return request({
    url: '/shop/shopManage/v2/saveShopInfo',
    method: 'POST',
    data,
  })
}

/**
 * 获取自提设置
 */
export const getPickupSettings = () => {
  return request({
    url: '/shop/logistics/getPickupSettings',
    method: 'GET',
  })
}

/**
 * 自提设置
 */
export const pickupSettings = (data) => {
  return request({
    url: '/shop/logistics/pickupSettings',
    method: 'POST',
    data,
  })
}

/**
 * 获取所有的州
 */
export const getState = () => {
  return request({
    url: '/shop/logistics/getState',
    method: 'GET',
  })
}

/**
 * 根据州id获取对应的城市
 */
export const getCity = (id) => {
  return request({
    url: `/shop/logistics/getCity/${id}`,
    method: 'GET',
  })
}

/**
 * 根据城市id获取对应的编码
 */
export const getZipCode = (id) => {
  return request({
    url: `/shop/logistics/getZipCode/${id}`,
    method: 'GET',
  })
}

/**
 * 获取店铺的配送设置
 */
export const getDeliverySettings = () => {
  return request({
    url: '/shop/logistics/getDeliverySettings',
    method: 'GET',
  })
}

/**
 * 根据邮编查城市
 */
export const searchCityByPostalCode = (data) => {
  return request({
    url: '/shop/logistics/searchCityByPostalCode',
    method: 'POST',
    data,
  })
}

/**
 * 配送设置
 */
export const deliverySettings = (data) => {
  return request({
    url: '/shop/logistics/deliverySettings',
    method: 'POST',
    data,
  })
}

/**
 * 小票机列表
 */
export const getTicketList = (params) => {
  return request({
    url: '/shop/ticket/getTicketList',
    method: 'GET',
    params,
  })
}

/**
 * 刷新小票机状态
 */
export const reloadTicket = (id) => {
  return request({
    url: `/shop/ticket/reloadTicket/${id}`,
    method: 'GET',
  })
}

/**
 * 收银台列表
 */
export const cashCounterList = () => {
  return request({
    url: '/shop/ticket/cashCounterList',
    method: 'GET',
  })
}

/**
 * 新增小票机
 */
export const addTicket = (data) => {
  return request({
    url: '/shop/ticket/addTicket',
    method: 'POST',
    data,
  })
}

/**
 * 小票机详情
 */
export const ticketDetails = (id) => {
  return request({
    url: `/shop/ticket/ticketDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 编辑小票机
 */
export const updateTicket = (data) => {
  return request({
    url: '/shop/ticket/updateTicket',
    method: 'POST',
    data,
  })
}

/**
 * 测试打印小票机
 */
export const printTest = (id) => {
  return request({
    url: `/shop/ticket/printTest/${id}`,
    method: 'GET',
  })
}

/**
 * 小票机copy url
 */
export const copyUrl = (id) => {
  return request({
    url: `/shop/ticket/copyUrl/${id}`,
    method: 'GET',
  })
}

/**
 * 获取设置小票机列表
 */
export const setTicketList = () => {
  return request({
    url: '/shop/ticket/setTicketList',
    method: 'GET',
  })
}

/**
 * 更新主小票机
 */
export const updateMainTicket = (data) => {
  return request({
    url: '/shop/ticket/updateMainTicket',
    method: 'POST',
    data,
  })
}

/**
 * 解绑pos机
 */
export const unBindPos = (id) => {
  return request({
    url: `/shop/unBindPos/${id}`,
    method: 'POST',
  })
}

/**
 * 编辑收银台
 */
export const editCashierCounter = (data) => {
  return request({
    url: '/shop/editCashierCounter',
    method: 'POST',
    data,
  })
}

/**
 * 编辑收银台
 */
export const deleteCashierCounter = (id) => {
  return request({
    url: `/shop/deleteCashierCounter/${id}`,
    method: 'DELETE',
  })
}
