import request, { requestDownload } from '@/utils/request'

/**
 * 采购单列表
 */
export const getProcureList = (params) => {
  return request({
    url: '/shop/procure/getProcureList',
    method: 'GET',
    params,
  })
}

/**
 * 根据条码查询商品
 */
export const getGoodsByCode = (code, params) => {
  return request({
    url: `/shop/procure/getGoodsByCode/${code}`,
    method: 'GET',
    params,
  })
}

/**
 * 商品列表
 */
export const getGoodsList = (params) => {
  return request({
    url: '/shop/procure/getGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 原材料列表
 */
export const getRawMaterialList = (params) => {
  return request({
    url: '/shop/procure/getRawMaterialList',
    method: 'GET',
    params,
  })
}

/**
 * 获取商品分类列表
 */
export const getShopCategoryList = () => {
  return request({
    url: '/shop/procure/getShopCategoryList',
    method: 'GET',
  })
}

/**
 * 获取原材料分类列表
 */
export const getRawMateList = () => {
  return request({
    url: '/shop/procure/getRawMateList',
    method: 'GET',
  })
}

/**
 * 新增采购单
 */
export const addProcure = (data) => {
  return request({
    url: '/shop/procure/addProcure',
    method: 'POST',
    data,
  })
}

/**
 * 删除采购单
 */
export const delProcure = (id) => {
  return request({
    url: `/shop/procure/delProcure/${id}`,
    method: 'DELETE',
  })
}

/**
 * 完结采购单
 */
export const endProcure = (id) => {
  return request({
    url: `/shop/procure/endProcure/${id}`,
    method: 'POST',
  })
}

/**
 * 采购单详情
 */
export const getProcureDetails = (id) => {
  return request({
    url: `/shop/procure/getProcureDetails/${id}`,
    method: 'GET',
  })
}

/**
 * 复核采购单
 */
export const editProcure = (data) => {
  return request({
    url: '/shop/procure/editProcure',
    method: 'POST',
    data,
  })
}

/**
 * 删除采购项
 */
export const delProcureItem = (id) => {
  return request({
    url: `/shop/procure/delProcureItem/${id}`,
    method: 'DELETE',
  })
}

/**
 * 下载采购单
 */
export const download = (id) => {
  return requestDownload({
    url: `/shop/procure/download/${id}`,
    method: 'GET',
    responseType: 'blob',
  })
}

/**
 * 充值卡列表
 */
export const getFuelCardList = (params) => {
  return request({
    url: '/shop/procure/getFuelCardList',
    method: 'GET',
    params,
  })
}
