export const accessMethod = (to, from, next) => {
  // 判断页面是否刷新
  if (from.name) {
    // VUE route 路由
    // console.log('Access method: route')
  } else {
    // f5 刷新、浏览器输入网址访问
    // console.log('Access method: f5')
  }
  // 不能删
  next()
}
