import {
  getGuaranteeServiceTypeList,
  addGuaranteeServiceType,
  editGuaranteeServiceType,
  deleteGuaranteeServiceType,
  getGuaranteeServiceType,
  getGuaranteeServiceList,
  deleteGuaranteeService,
  addGuaranteeService,
  getGuaranteeServiceDetails,
  editGuaranteeService,
} from '@/api/shop/guarantee'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getGuaranteeServiceTypeList(context, params) {
      return await getGuaranteeServiceTypeList(params)
    },
    async addGuaranteeServiceType(context, data) {
      return await addGuaranteeServiceType(data)
    },
    async editGuaranteeServiceType(context, data) {
      return await editGuaranteeServiceType(data)
    },
    async deleteGuaranteeServiceType(context, id) {
      return await deleteGuaranteeServiceType(id)
    },
    async getGuaranteeServiceType(context) {
      return await getGuaranteeServiceType()
    },
    async getGuaranteeServiceList(context, params) {
      return await getGuaranteeServiceList(params)
    },
    async deleteGuaranteeService(context, id) {
      return await deleteGuaranteeService(id)
    },
    async addGuaranteeService(context, data) {
      return await addGuaranteeService(data)
    },
    async getGuaranteeServiceDetails(context, id) {
      return await getGuaranteeServiceDetails(id)
    },
    async editGuaranteeService(context, data) {
      return await editGuaranteeService(data)
    },
  },
}
