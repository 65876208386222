import { SHOP, SHOP_DOMAIN, SHOP_ID } from '@/constant'
import { setCookie } from './cookies'
import { ElLoading } from 'element-plus'
// import { loadStore } from './store'
import i18n from '@/i18n'
import store from '@/store'
import { loadStoreNoLoading } from './store'
import { getUrlProtocolDoubleSlash } from './path'
import { clearShopDomainTimer } from './shopDomainTimer'

/**
 * 进入店铺的功能
 *
 * @param {*} shop 店铺信息
 * @param {*} refresh 是否要刷新，老的逻辑，新逻辑用不到了
 * @param {*} domain 店铺自定义域名，比如：aaaaaaaa
 */
export const enterTheShop = async (shop) => {
  // 删除所有 Tag
  store.commit('app/removeAllTagsView')
  // 关闭店铺域名定时器
  clearShopDomainTimer()
  // 设置店铺 ID
  setCookie(SHOP_ID, shop.id)
  setCookie(SHOP, shop)
  setCookie(SHOP_DOMAIN, shop.domain)
  const loading = ElLoading.service({
    lock: true,
    text: i18n.global.t('msg.storeSelect.openTheShop'),
  })
  await loadStoreNoLoading('user/getShopPerm', shop.id)
  loading.close()

  // 相当于 'http(s)://' + 'aaaaaaaa' + '.test.lynchj.com' + '/' = 'aaaaaaaa.test.lynchj.com/'
  window.location.href =
    getUrlProtocolDoubleSlash() +
    shop.domain +
    process.env.VUE_APP_BASE_COOKIE_DOMAIN +
    store.getters.shopDefaultIndex
}
