import request from '@/utils/request'

/**
 * 获取售后列表详情
 */
export const getAfterSalesListDes = (params) => {
  return request({
    url: '/shop/afterSales/getAfterSalesListDes',
    method: 'GET',
    params,
  })
}

/**
 * 操作售后单
 */
export const operAfterSales = (data) => {
  return request({
    url: '/shop/afterSales/operAfterSales',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取售后单详情
 */
export const getAfterSalesDesById = (id) => {
  return request({
    url: `/shop/afterSales/getAfterSalesDesById/${id}`,
    method: 'GET',
  })
}
