import { whiteList } from '@/router/before_each/permission'

const noTagList = [...whiteList]

/**
 * 不是所有路径都需要添加到 tagsView
 */
export function isTags(path) {
  return !noTagList.includes(path)
}
