import {
  getGoodsList,
  getGoodsSkuList,
  getGoodsCategoryList,
  getGoodsUnitList,
  addGoods,
  getGoodsDesById,
  updateGoods,
  deleteGoodsById,
  getGoodsCategoryListDes,
  addGoodsCategory,
  getGoodsCategoryDesById,
  getRelationGoodsById,
  updateGoodsCategory,
  deleteGoodsCategoryById,
  getRawMaterialCategoryListDes,
  addRawMaterialCategory,
  getRawMaterialCategoryDesById,
  getRelationRawMaterialById,
  updateRawMaterialCategory,
  deleteRawMaterialCategoryById,
  getRawMaterialListDes,
  getRawMaterialCategoryList,
  getMeasureUnitOptionsList,
  addRawMaterial,
  getRawMaterialDesById,
  updateRawMaterial,
  deleteRawMaterialById,
  getMeasureUnitListDes,
  addMeasureUnit,
  updateMeasureUnitStatus,
  getShopTaxRate,
  getGoodsDesByCode,
  getAreaList,
  addStorageRackArea,
  getStorageRackList,
  addStorageRackNumber,
  getAreaDetail,
  deleteStorageRackArea,
  updateStorageRackNumber,
  deleteStorageRackNumber,
  getGoodSkuList,
  getStorageRackNumberList,
  getStorageRackAreaList,
  getStorageRackNumberListById,
  getGoodsDesByIdV2,
  addGoodsV2,
  updateGoodsV2,
  checkGoodsPublishStatus,
  getFuelCardList,
  addFuelCard,
  getFuelCardById,
  updateFuelCard,
  deleteFuelCard,
  getGoodsPackUnitList,
  getGuaranteeTypeList,
  getGuaranteeNameList,
  getGuaranteeDetail,
  getMeasureUnitPackOptionsList,
  getGoodsCategoryLable,
  getSkuListByRawMaterial,
} from '@/api/shop/goods'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {
    setGoodsList(state, goodsList) {
      state.goodsList = goodsList
    },
  },
  actions: {
    // 获取商品列表
    async getGoodsList(context, params) {
      return await getGoodsList(params)
    },
    // 获取商品 SKU 详情
    async getGoodsSkuList(context, params) {
      return await getGoodsSkuList(params)
    },
    // 获取商品分类
    async getGoodsCategoryList(context) {
      return await getGoodsCategoryList()
    },
    // 获取商品计量单位
    async getGoodsUnitList(context) {
      return await getGoodsUnitList()
    },
    async addGoods(context, data) {
      return await addGoods(data)
    },
    async getGoodsDesById(context, id) {
      return await getGoodsDesById(id)
    },
    async updateGoods(context, data) {
      return await updateGoods(data)
    },
    async deleteGoodsById(context, id) {
      return await deleteGoodsById(id)
    },
    async getGoodsCategoryListDes(context, params) {
      return await getGoodsCategoryListDes(params)
    },
    async addGoodsCategory(context, data) {
      return await addGoodsCategory(data)
    },
    async getGoodsCategoryDesById(context, id) {
      return await getGoodsCategoryDesById(id)
    },
    async getRelationGoodsById(context, params) {
      return await getRelationGoodsById(params)
    },
    async updateGoodsCategory(context, data) {
      return await updateGoodsCategory(data)
    },
    async deleteGoodsCategoryById(context, id) {
      return await deleteGoodsCategoryById(id)
    },
    async getRawMaterialCategoryListDes(context, params) {
      return await getRawMaterialCategoryListDes(params)
    },
    async addRawMaterialCategory(context, data) {
      return await addRawMaterialCategory(data)
    },
    async getRawMaterialCategoryDesById(context, id) {
      return await getRawMaterialCategoryDesById(id)
    },
    async getRelationRawMaterialById(context, params) {
      return await getRelationRawMaterialById(params)
    },
    async updateRawMaterialCategory(context, data) {
      return await updateRawMaterialCategory(data)
    },
    async deleteRawMaterialCategoryById(context, id) {
      return await deleteRawMaterialCategoryById(id)
    },
    async getRawMaterialListDes(context, params) {
      return await getRawMaterialListDes(params)
    },
    async getRawMaterialCategoryList(context, params) {
      return await getRawMaterialCategoryList(params)
    },
    async getMeasureUnitOptionsList(context, params) {
      return await getMeasureUnitOptionsList(params)
    },
    async addRawMaterial(context, data) {
      return await addRawMaterial(data)
    },
    async getRawMaterialDesById(context, id) {
      return await getRawMaterialDesById(id)
    },
    async updateRawMaterial(context, data) {
      return await updateRawMaterial(data)
    },
    async deleteRawMaterialById(context, id) {
      return await deleteRawMaterialById(id)
    },
    async getMeasureUnitListDes(context, params) {
      return await getMeasureUnitListDes(params)
    },
    async addMeasureUnit(context, data) {
      return await addMeasureUnit(data)
    },
    async updateMeasureUnitStatus(context, data) {
      return await updateMeasureUnitStatus(data)
    },
    async getShopTaxRate(context) {
      return await getShopTaxRate()
    },
    async getGoodsDesByCode(context, barCode) {
      return await getGoodsDesByCode(barCode)
    },
    async getAreaList(context, params) {
      return await getAreaList(params)
    },
    async addStorageRackArea(context, data) {
      return await addStorageRackArea(data)
    },
    async getStorageRackList(context, data) {
      return await getStorageRackList(data)
    },
    async addStorageRackNumber(context, data) {
      return await addStorageRackNumber(data)
    },
    async getAreaDetail(context, id) {
      return await getAreaDetail(id)
    },
    async deleteStorageRackArea(context, id) {
      return await deleteStorageRackArea(id)
    },
    async updateStorageRackNumber(context, data) {
      return await updateStorageRackNumber(data)
    },
    async deleteStorageRackNumber(context, id) {
      return await deleteStorageRackNumber(id)
    },
    async getGoodSkuList(context, params) {
      return await getGoodSkuList(params)
    },
    async getStorageRackNumberList(context, id) {
      return await getStorageRackNumberList(id)
    },
    async getStorageRackAreaList(context) {
      return await getStorageRackAreaList()
    },
    async getStorageRackNumberListById(context, id) {
      return await getStorageRackNumberListById(id)
    },
    async getGoodsDesByIdV2(context, id) {
      return await getGoodsDesByIdV2(id)
    },
    async addGoodsV2(context, data) {
      return await addGoodsV2(data)
    },
    async updateGoodsV2(context, data) {
      return await updateGoodsV2(data)
    },
    async checkGoodsPublishStatus(context, id) {
      return await checkGoodsPublishStatus(id)
    },
    async getFuelCardList(context, params) {
      return await getFuelCardList(params)
    },
    async addFuelCard(context, data) {
      return await addFuelCard(data)
    },
    async getFuelCardById(context, id) {
      return await getFuelCardById(id)
    },
    async updateFuelCard(context, data) {
      return await updateFuelCard(data)
    },
    async deleteFuelCard(context, id) {
      return await deleteFuelCard(id)
    },
    async getGoodsPackUnitList(context) {
      return await getGoodsPackUnitList()
    },
    async getGuaranteeTypeList(context) {
      return await getGuaranteeTypeList()
    },
    async getGuaranteeNameList(context, id) {
      return await getGuaranteeNameList(id)
    },
    async getGuaranteeDetail(context, id) {
      return await getGuaranteeDetail(id)
    },
    async getMeasureUnitPackOptionsList(context) {
      return await getMeasureUnitPackOptionsList()
    },
    async getGoodsCategoryLable(context) {
      return await getGoodsCategoryLable()
    },
    async getSkuListByRawMaterial(context, params) {
      return await getSkuListByRawMaterial(params)
    },
  },
}
