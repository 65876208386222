import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zh from 'element-plus/dist/locale/zh-cn'
import en from 'element-plus/dist/locale/en'
import store from '@/store'

const locale = store.getters.language === 'zh' ? zh : en

export default (app) => {
  app.use(ElementPlus, {
    locale,
  })
}
