import request from '@/utils/request'

/**
 * 获取商品库存列表
 * @returns 商品库存列表
 */
export const getGoodsStockList = (params) => {
  return request({
    url: '/shop/stock/getGoodsStockList',
    method: 'GET',
    params,
  })
}

/**
 * 根据ID获取商品SKU库存列表
 */
export const getGoodsStockSkuListById = (id) => {
  return request({
    url: `/shop/stock/getGoodsStockSkuListById/${id}`,
    method: 'GET',
  })
}

/**
 * 根据ID获取商品库存详情
 */
export const getGoodsStockById = (params) => {
  return request({
    url: '/shop/stock/getGoodsStockById',
    method: 'GET',
    params,
  })
}

/**
 * 商品SKU入库操作
 */
export const changeGoodsInStock = (data) => {
  return request({
    url: '/shop/stock/changeGoodsInStock',
    method: 'POST',
    data,
  })
}

/**
 * 商品SKU出库操作
 */
export const changeGoodsOutStock = (data) => {
  return request({
    url: '/shop/stock/changeGoodsOutStock',
    method: 'POST',
    data,
  })
}

/**
 * 获取原材料库存列表
 */
export const getRawMaterialStockList = (params) => {
  return request({
    url: '/shop/stock/getRawMaterialStockList',
    method: 'GET',
    params,
  })
}

/**
 * 原材料入库操作
 */
export const changeRawMaterialInStock = (data) => {
  return request({
    url: '/shop/stock/changeRawMaterialInStock',
    method: 'POST',
    data,
  })
}

/**
 * 原材料出库操作
 */
export const changeRawMaterialOutStock = (data) => {
  return request({
    url: '/shop/stock/changeRawMaterialOutStock',
    method: 'POST',
    data,
  })
}

/**
 * 根据ID获取原材料库存详情
 */
export const getRawMaterialStockById = (params) => {
  return request({
    url: '/shop/stock/getRawMaterialStockById',
    method: 'GET',
    params,
  })
}

/**
 * 库存预警列表
 */
export const stockWarnList = (params) => {
  return request({
    url: '/shop/stockWarn/stockWarnList',
    method: 'GET',
    params,
  })
}

/**
 * 查询spu下的sku列表
 */
export const skuList = (id, params) => {
  return request({
    url: `/shop/stockWarn/skuList/${id}`,
    method: 'GET',
    params,
  })
}

/**
 * 预警采购列表
 */
export const stockWarnPurcList = (params) => {
  return request({
    url: '/shop/stockWarn/stockWarnPurcList',
    method: 'GET',
    params,
  })
}

/**
 * 生成采购单
 */
export const generatePurcOrder = (data) => {
  return request({
    url: '/shop/stockWarn/generatePurcOrder',
    method: 'POST',
    data,
  })
}

/**
 * 库存预警设置
 */
export const stockWarnListSetting = (params) => {
  return request({
    url: '/shop/stockWarnSetting/stockWarnList',
    method: 'GET',
    params,
  })
}

/**
 * 查询spu下的sku列表
 */
export const skuSettingList = (id, params) => {
  return request({
    url: `/shop/stockWarnSetting/skuSettingList/${id}`,
    method: 'GET',
    params,
  })
}

/**
 * 库存预警设置
 */
export const setting = (data) => {
  return request({
    url: '/shop/stockWarnSetting/setting',
    method: 'POST',
    data,
  })
}

/**
 * 查询sku的库区列表
 */
export const getSkuWarehouseList = (id) => {
  return request({
    url: `/shop/stock/getSkuWarehouseList/${id}`,
    method: 'GET',
  })
}

/**
 * 查询sku的仓库库存变化记录
 */
export const getSkuWarehouseStockRecordList = (params) => {
  return request({
    url: '/shop/stock/getSkuWarehouseStockRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 移库
 */
export const moveWarehouse = (data) => {
  return request({
    url: '/shop/stock/moveWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 查询sku的货架列表
 */
export const getSkuShelfList = (id) => {
  return request({
    url: `/shop/stock/getSkuShelfList/${id}`,
    method: 'GET',
  })
}

/**
 * 查询sku的货架库存变化记录
 */
export const getSkuShelfStockRecordList = (params) => {
  return request({
    url: '/shop/stock/getSkuShelfStockRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 查询原材料的库区列表
 */
export const getRawWarehouseList = (id) => {
  return request({
    url: `/shop/stock/getRawWarehouseList/${id}`,
    method: 'GET',
  })
}

/**
 * 查询原材料的库区库存记录
 */
export const getRawWarehouseStockRecordList = (params) => {
  return request({
    url: '/shop/stock/getRawWarehouseStockRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 移库
 */
export const rawMoveWarehouse = (data) => {
  return request({
    url: '/shop/stock/rawMoveWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 原材料追踪
 */
export const rawMaterTracking = (params) => {
  return request({
    url: '/shop/stock/rawMaterTracking',
    method: 'GET',
    params,
  })
}

/**
 * 充值卡库存分页
 */
export const getCardStockList = (params) => {
  return request({
    url: '/shop/cardStock/getCardStockList',
    method: 'GET',
    params,
  })
}

/**
 * 查询card的库区列表
 */
export const getCardWarehouseList = (id) => {
  return request({
    url: `/shop/cardStock/getCardWarehouseList/${id}`,
    method: 'GET',
  })
}

/**
 * Card 移库
 */
export const cardMoveWarehouse = (data) => {
  return request({
    url: '/shop/cardStock/moveWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 查询card的仓库库存变化记录
 */
export const getCardWarehouseStockRecordList = (params) => {
  return request({
    url: '/shop/cardStock/getCardWarehouseStockRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 查询card的货架列表
 */
export const getCardShelfList = (id) => {
  return request({
    url: `/shop/cardStock/getCardShelfList/${id}`,
    method: 'GET',
  })
}

/**
 * 查询Card的货架库存变化记录
 */
export const getCardShelfStockRecordList = (params) => {
  return request({
    url: '/shop/cardStock/getCardShelfStockRecordList',
    method: 'GET',
    params,
  })
}

/**
 * 获取对应的AI进货和备货时间
 */
export const getAiTime = (params) => {
  return request({
    url: '/shop/stockWarnSetting/getAiTime',
    method: 'GET',
    params,
  })
}
