import {
  getOnlineOrderList,
  getOnlineOrderDetail,
  manualStocking,
  confirmReceipt,
  getMacouUseList,
  getMacouRebateList,
  getSkuInfoByOrderId,
  requestRefund,
  guaranteeServiceList,
  getStatusTracking,
  checkOrderTimeExpire,
  getRefundOrder,
  getAvailableAndPrintCashierCounterList,
  printOnlineTicket,
  printOnlineRefundTicket,
  getRefundDesById,
  getOnlineOrderPickingTime,
  getOnlineAmountCollected,
} from '@/api/shop/onlineOrder'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getOnlineOrderList(context, params) {
      return await getOnlineOrderList(params)
    },
    async getOnlineOrderDetail(context, id) {
      return await getOnlineOrderDetail(id)
    },
    async manualStocking(context, orderId) {
      return await manualStocking(orderId)
    },
    async confirmReceipt(context, data) {
      return await confirmReceipt(data)
    },
    async getMacouUseList(context, orderId) {
      return await getMacouUseList(orderId)
    },
    async getMacouRebateList(context, orderId) {
      return await getMacouRebateList(orderId)
    },
    async getSkuInfoByOrderId(context, id) {
      return await getSkuInfoByOrderId(id)
    },
    async requestRefund(context, data) {
      return await requestRefund(data)
    },
    async guaranteeServiceList(context, orderId) {
      return await guaranteeServiceList(orderId)
    },
    async getStatusTracking(context, orderId) {
      return await getStatusTracking(orderId)
    },
    async checkOrderTimeExpire(context, orderId) {
      return await checkOrderTimeExpire(orderId)
    },
    async getRefundOrder(context, id) {
      return await getRefundOrder(id)
    },
    async getAvailableAndPrintCashierCounterList(context) {
      return await getAvailableAndPrintCashierCounterList()
    },
    async printOnlineTicket(context, data) {
      return await printOnlineTicket(data)
    },
    async printOnlineRefundTicket(context, data) {
      return await printOnlineRefundTicket(data)
    },
    async getRefundDesById(context, id) {
      return await getRefundDesById(id)
    },
    async getOnlineOrderPickingTime(context, id) {
      return await getOnlineOrderPickingTime(id)
    },
    async getOnlineAmountCollected(context, id) {
      return await getOnlineAmountCollected(id)
    },
  },
}
