<template>
  <div
    class="app-wrapper"
    :class="$store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar'"
  >
    <!-- 左侧 Menu -->
    <Sidebar
      id="guide-sidebar"
      class="sidebar-container"
      :style="{ backgroundColor: $store.getters.cssVar.menuBg }"
    ></Sidebar>
    <div class="main-container">
      <div class="fixed-header">
        <!-- 顶部 Navbar -->
        <Navbar />
        <TagsView />
      </div>
      <!-- 内容区 -->
      <AppMain></AppMain>
    </div>
  </div>
</template>

<script setup>
import AppMain from './components/AppMain.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/sidebar/Sidebar.vue'
import { loadStoreNoLoading } from '@/utils/store'
import router from '@/router'
import TagsView from '@/components/tagsview/TagsView.vue'
import { openShopDomainTimer } from '@/utils/shopDomainTimer'

loadStoreNoLoading('shopManage/shopConfigurationCompleted').then((data) => {
  if (!data) {
    // 基础配置没有配置齐全
    router.push('/shop/shopConfig')
  }
})
// #region 监听店铺页面域名是否变化弹框
openShopDomainTimer()
// #endregion
</script>

<script>
export default {
  name: 'ShopLayoutView',
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.module.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width #{$sidebarDuration};
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
