<template>
  <div class="tags-view-container">
    <el-scrollbar class="tags-view-wrapper">
      <div class="tags-view-flex mt-8 pl-5">
        <router-link
          class="tags-view-item ml-8 py-5 px-10"
          :class="isActive(tag) ? 'active' : 'inactive'"
          v-for="(tag, index) in $store.getters.tagsViewList"
          :key="tag.path"
          :to="{ path: tag.path, query: tag.query }"
          @contextmenu.prevent="openMenu($event, index)"
        >
          {{ tag.title }}
          <el-icon
            class="text-[16px] text-[#F56C6C] ml-5"
            v-show="!isActive(tag)"
            @click.prevent="onCloseClick(index)"
          >
            <CircleClose />
          </el-icon>
        </router-link>
      </div>
    </el-scrollbar>
  </div>
  <ContextMenu v-show="visible" :style="menuStyle" :index="selectIndex">
  </ContextMenu>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ContextMenu from './ContextMenu.vue'
import { CircleClose } from '@element-plus/icons-vue'

const route = useRoute()

// 是否激活 tag 判断
const isActive = (tag) => {
  return route.path === tag.path
}

const store = useStore()
// 删除单个 tag
const onCloseClick = (index) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index,
  })
}

const selectIndex = ref(0)
const visible = ref(false)
const menuStyle = reactive({
  top: '0px',
  left: '0px',
})
const openMenu = (event, index) => {
  const { x, y } = event
  menuStyle.left = x + 'px'
  menuStyle.top = y + 'px'
  selectIndex.value = index
  visible.value = true
}

const closeMenu = () => {
  visible.value = false
}
watch(visible, (to, from) => {
  if (to) {
    document.body.addEventListener('click', closeMenu)
  } else {
    document.body.removeEventListener('click', closeMenu)
  }
})

const router = useRouter()

// 监听 tag 变动时
watch(store.getters.tagsViewList, (to, from) => {
  const theCurrPageExis = to.find((tag) => {
    // tags 中还存在当前正在访问的页
    return tag.path === route.path
  })
  if (!theCurrPageExis && to.length > 0) {
    // 不存在了，取 tags 第一个展示
    router.push(to[0].path)
  }
})
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 3rem;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  .tags-view-flex {
    display: flex;
    white-space: nowrap;

    .tags-view-item {
      font-size: 1rem;
      height: 2rem;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    .active {
      background-color: #002865;
      color: white;
    }

    .inactive {
      border: 1px solid #d8dce5;
    }
  }
}
</style>
