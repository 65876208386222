import { shopDefaultIndex } from '@/router'

export const toHomeUrl = () => {
  window.location.href =
    getUrlProtocolDoubleSlash() + process.env.VUE_APP_BASE_ORG_DOMAIN
}

export const getUrlProtocol = () => {
  return window.location.protocol
}

export const getUrlProtocolDoubleSlash = () => {
  return getUrlProtocol() + '//'
}

export const toShopUrl = (currHostname) => {
  window.location.href =
    getUrlProtocolDoubleSlash() + currHostname + shopDefaultIndex
}
