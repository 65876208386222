import { NOT_IMAGE } from '@/constant'
import store from '@/store'
import { ElLoading, ElMessage } from 'element-plus'

export const loadStore = (uri, params, text, msg, callback, errorCallback) => {
  return new Promise((resolve, reject) => {
    const loading = ElLoading.service({
      lock: true,
      text: text || 'Processing',
    })
    setTimeout(() => {
      store
        .dispatch(uri, params)
        .then((data) => {
          loading.close()
          handleThen(msg, callback)
          resolve(data)
        })
        .catch((err) => {
          loading.close()
          handleErr(err, callback, errorCallback)
        })
    }, 100)
  })
}

export const loadStoreIdAndParams = (
  uri,
  id,
  params,
  text,
  msg,
  callback,
  errorCallback,
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const loading = ElLoading.service({
        lock: true,
        text: text || 'Processing',
      })
      params.id = id
      store
        .dispatch(uri, params)
        .then((data) => {
          loading.close()
          handleThen(msg, callback)
          resolve(data)
        })
        .catch((err) => {
          loading.close()
          handleErr(err, callback, errorCallback)
        })
    }, 100)
  })
}

export const loadStoreNoLoading = (
  uri,
  params,
  msg,
  callback,
  errorCallback,
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      store
        .dispatch(uri, params)
        .then((data) => {
          handleThen(msg, callback)
          resolve(data)
        })
        .catch((err) => {
          handleErr(err, callback, errorCallback)
        })
    }, 100)
  })
}

const handleThen = (msg, callback) => {
  if (msg) {
    ElMessage.success(msg)
  }
  if (callback) {
    callback()
  }
}

const handleErr = (err, callback, errorCallback) => {
  console.log('unknown exception: ', err)
  if (callback) {
    callback()
  }
  if (errorCallback) {
    errorCallback()
  }
}

const replaceImage = (img, size) => {
  if (!img) {
    return process.env.VUE_APP_BASE_IMG_DOMAIN + NOT_IMAGE
  }

  if (img.startsWith('http')) {
    return img
  }

  const imgIndex = img.lastIndexOf('.')
  img = img.substr(0, imgIndex) + size + img.substr(imgIndex)

  return process.env.VUE_APP_BASE_IMG_DOMAIN + img
}

export const assemblyImgThumb = (img) => {
  return replaceImage(img, '_thumb')
}

export const assemblyImgMedium = (img) => {
  return replaceImage(img, '_medium')
}

export const assemblyImg = (img) => {
  return replaceImage(img, '')
}
