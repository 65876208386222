import {
  queryBusiUserEmailSuffix,
  queryBusiRoleList,
  addAccount,
  employeeList,
  changeStatus,
  queryBusiRoleDetailsBySubId,
  updateAccount,
  delEmployeeById,
  queryShopRoleBySubId,
  updateUserSubShopRole,
  queryShopRoleByShopId,
  resetPwd,
  busiRoleList,
  queryBusiRoleRelAccountById,
  getBusiRoleResList,
  addBusiRole,
  queryBusiRoleById,
  updateBusiRole,
  delBusiRoleById,
  getShopSelect,
  shopRoleList,
  queryShopRoleRelAccountById,
  getShopRoleResList,
  addShopRole,
  queryShopRoleById,
  delShopRoleById,
  shopList,
  queryShopRoleRelAccountByShopId,
  updateShopRole,
  getSessionTime,
} from '@/api/org/account'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async queryBusiUserEmailSuffix(context) {
      return await queryBusiUserEmailSuffix()
    },
    async queryBusiRoleList(context) {
      return await queryBusiRoleList()
    },
    async addAccount(context, data) {
      return await addAccount(data)
    },
    async employeeList(context, params) {
      return await employeeList(params)
    },
    async changeStatus(context, data) {
      return await changeStatus(data)
    },
    async queryBusiRoleDetailsBySubId(context, id) {
      return await queryBusiRoleDetailsBySubId(id)
    },
    async updateAccount(context, data) {
      return await updateAccount(data)
    },
    async delEmployeeById(context, id) {
      return await delEmployeeById(id)
    },
    async queryShopRoleBySubId(context, id) {
      return await queryShopRoleBySubId(id)
    },
    async updateUserSubShopRole(context, data) {
      return await updateUserSubShopRole(data)
    },
    async queryShopRoleByShopId(context, id) {
      return await queryShopRoleByShopId(id)
    },
    async resetPwd(context, data) {
      return await resetPwd(data)
    },
    async busiRoleList(context, params) {
      return await busiRoleList(params)
    },
    async queryBusiRoleRelAccountById(context, id) {
      return await queryBusiRoleRelAccountById(id)
    },
    async getBusiRoleResList(context) {
      return await getBusiRoleResList()
    },
    async addBusiRole(context, data) {
      return await addBusiRole(data)
    },
    async queryBusiRoleById(context, id) {
      return await queryBusiRoleById(id)
    },
    async updateBusiRole(context, data) {
      return await updateBusiRole(data)
    },
    async delBusiRoleById(context, id) {
      return await delBusiRoleById(id)
    },
    async getShopSelect(context) {
      return await getShopSelect()
    },
    async shopRoleList(context, params) {
      return await shopRoleList(params)
    },
    async queryShopRoleRelAccountById(context, id) {
      return await queryShopRoleRelAccountById(id)
    },
    async getShopRoleResList(context, id) {
      return await getShopRoleResList(id)
    },
    async addShopRole(context, data) {
      return await addShopRole(data)
    },
    async queryShopRoleById(context, id) {
      return await queryShopRoleById(id)
    },
    async delShopRoleById(context, id) {
      return await delShopRoleById(id)
    },
    async shopList(context, params) {
      return await shopList(params)
    },
    async queryShopRoleRelAccountByShopId(context, id) {
      return await queryShopRoleRelAccountByShopId(id)
    },
    async updateShopRole(context, data) {
      return await updateShopRole(data)
    },
    async getSessionTime(context) {
      return await getSessionTime()
    },
  },
}
