import request from '@/utils/request'

/**
 * 获取拣货任务列表
 * @returns 拣货任务列表
 */
export const getPickingTaskList = (params) => {
  return request({
    url: '/shop/pickingTask/getPickingTaskList',
    method: 'GET',
    params,
  })
}

/**
 * 获取发货核对订单信息
 */
export const getShipmentVerificationById = (id) => {
  return request({
    url: `/shop/pickingTask/getShipmentVerification/${id}`,
    method: 'GET',
  })
}

/**
 * 获取在线小票机
 */
export const getOnlineTicket = () => {
  return request({
    url: '/shop/pickingTask/getOnlineTicket',
    method: 'GET',
  })
}

/**
 * 核对完毕
 */
export const verificationCompleted = (data) => {
  return request({
    url: '/shop/pickingTask/verificationCompleted',
    method: 'POST',
    data,
  })
}

/**
 * 打印拣货单
 */
export const printPickingTask = (data) => {
  return request({
    url: '/shop/pickingTask/printPickingTask',
    method: 'POST',
    data,
  })
}

/**
 * 打印小票
 */
export const printOnlineTicket = (data) => {
  return request({
    url: '/shop/pickingTask/printOnlineTicket',
    method: 'POST',
    data,
  })
}

/**
 * 获取可用并且带有打票机的收银台
 */
export const getAvailableAndPrintCashierCounterList = () => {
  return request({
    url: '/shop/pickingTask/getAvailableAndPrintCashierCounterList',
    method: 'GET',
  })
}
