<template>
  <div class="navbar">
    <!-- 左侧收缩按钮 -->
    <Hamburger class="hamburger-container" />
    <!-- 面包屑 -->
    <Breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <!-- <Guide class="right-menu-item hover-effect"></Guide> -->
      <!-- <Screenfull class="right-menu-item hover-effect"></Screenfull> -->
      <SwitchShop class="switch-shop" />
      <Notify />
      <LangSelect
        v-if="e === 'development'"
        class="right-menu-item hover-effect"
        effect="light"
      ></LangSelect>
      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar
            shape="square"
            :src="assemblyImg($store.getters.userInfo.avatar)"
          />
          <p>{{ $store.getters.userInfo.username }}</p>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <!-- 回组织 -->
            <el-dropdown-item @click="toHome">
              {{ $t('msg.navBar.organizationalSystem') }}
            </el-dropdown-item>
            <!-- 个人中心 -->
            <div @click="toSettings()">
              <el-dropdown-item divided>
                {{ $t('msg.navBar.personalCenter') }}
              </el-dropdown-item>
            </div>
            <!-- 帮助手册 -->
            <el-dropdown-item @click="helpManual()">
              {{ $t('msg.common.helpManual') }}
            </el-dropdown-item>
            <!-- 退出 -->
            <el-dropdown-item @click="logout" divided>
              {{ $t('msg.navBar.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <i class="el-icon-s-tools"></i>
    </div>
  </div>
</template>

<script setup>
import Hamburger from './hamburger/Hamburger.vue'
import Notify from './notify/Notify.vue'
import Breadcrumb from './breadcrumb/Breadcrumb.vue'
import LangSelect from '@/components/lang-select/LangSelect.vue'
import SwitchShop from '@/components/switch-shop/SwitchShop.vue'
import { assemblyImg, loadStore } from '@/utils/store'
import router from '@/router'
import { useRoute } from 'vue-router'
import { SETTINGS_ORIGINAL_PAGE } from '@/constant'
import { setCookie } from '@/utils/cookies'
import store from '@/store'
import { toHomeUrl } from '@/utils/path'

const e = process.env.VUE_APP_ENV

const route = useRoute()

const toSettings = () => {
  setCookie(SETTINGS_ORIGINAL_PAGE, route.fullPath)
  if (store.getters.userInfo.ifPrimary) {
    router.push('/settings/personalCenter')
  } else {
    router.push('/settings/accountSecurity')
  }
}
const helpManual = () => {
  window.open('https://massho-docs.web.app/guide/home/')
}

const toHome = async () => {
  // 能进入店铺的，必然有组织中的店铺选择权限，所以可以放心跳转，不会 404
  // router.push(orgDefaultIndex)
  toHomeUrl()
}

const logout = () => {
  loadStore('user/logout').then(() => {
    window.location.href = '/login'
  })
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0, 21, 41, 0.08);

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    :deep(.avatar-container) {
      cursor: pointer;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        display: inline-flex;
        line-height: 2.8;

        .el-avatar {
          --el-avatar-bg-color: none;
          margin-right: 12px;
        }
      }
    }

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }
  }

  .hamburger-container {
    padding: 0 16px;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }
}

.switch-shop {
  margin-right: 10px;
}

.el-avatar {
  width: 40px;
  height: 40px;
}
</style>
@/utils/cookies
