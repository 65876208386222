import { getCookie } from './cookies'
import { SHOP_DOMAIN } from '@/constant'
import i18n from '@/i18n'
import { ElMessageBox } from 'element-plus'
import { toShopUrl } from '@/utils/path'

let timer
// 开启定时器
export const openShopDomainTimer = () => {
  timer = setInterval(() => {
    // 默认域名不管
    const currHostname = window.location.hostname
    if (currHostname === process.env.VUE_APP_BASE_ORG_DOMAIN) {
      return
    }
    // 子域名不等于最新的则弹框
    const shopDomain = getCookie(SHOP_DOMAIN)
    if (shopDomain && shopDomain.toString() !== currHostname.split('.')[0]) {
      ElMessageBox.alert(
        i18n.global.t('msg.toast.changeShopTip'),
        i18n.global.t('msg.common.tip'),
        {
          confirmButtonText: i18n.global.t('msg.common.confirm'),
          showCancelButton: false,
          callback: (action) => {
            const shopDomain = getCookie(SHOP_DOMAIN)
            toShopUrl(shopDomain + process.env.VUE_APP_BASE_COOKIE_DOMAIN)
          },
        },
      )
      clearShopDomainTimer()
    }
  }, 1000)
}

// 关闭定时器
export const clearShopDomainTimer = () => {
  if (timer) {
    clearInterval(timer)
  }
}
