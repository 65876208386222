<template>
  <div>
    <el-dialog
      v-model="dialogFlag"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="10vh"
      center
    >
      <template #header>
        <div style="position: relative">
          <span class="text-22 font-[700]">{{
            $t('msg.register.operatorInformation')
          }}</span>
          <el-button
            style="position: absolute; right: 0px"
            type="danger"
            @click="logout"
            >{{ $t('msg.navBar.logout') }}</el-button
          >
        </div>
      </template>

      <div class="seller-information">
        <div class="my-20 mx-50">
          <div
            class="m-30"
            style="border: solid 1px #d7d7d7; border-radius: 4px"
          >
            <!-- 温馨提示 -->
            <div
              class="m-20 p-10"
              style="
                border: solid 1px #d7d7d7;
                border-radius: 4px;
                display: flex;
              "
            >
              <SvgIcon icon="shop-warn" class="text-40 mr-10" />
              <p>
                {{
                  $t(
                    'msg.register.fillInTheInformationOfTheOperatorOfTheStoreThatNeedsToBeCertified',
                  )
                }}
              </p>
            </div>
            <!-- 主体填写信息 -->
            <div class="my-30 mx-40">
              <el-form
                label-position="top"
                :label-width="formLabelWidth"
                ref="sellFormRef"
                :model="sellForm"
                :rules="sellFules"
              >
                <!-- 姓名 -->
                <el-row>
                  <el-col :span="10">
                    <el-form-item
                      :label="$t('msg.register.firstName')"
                      prop="firstName"
                    >
                      <el-input
                        type="text"
                        v-model="sellForm.firstName"
                        :maxlength="30"
                        :show-word-limit="true"
                      ></el-input>
                    </el-form-item>
                    <div class="text-12">
                      {{
                        $t(
                          'msg.register.enterYourFullNameAsItAppearsOnYourSocialSecurityCardOrDriverIsLicense',
                        )
                      }}
                    </div>
                  </el-col>
                  <el-col :span="10" :offset="4">
                    <el-form-item
                      :label="$t('msg.register.lastName')"
                      prop="lastName"
                    >
                      <el-input
                        type="text"
                        v-model="sellForm.lastName"
                        :maxlength="30"
                        :show-word-limit="true"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 身份证明、签发国家 -->
                <el-row class="mt-20">
                  <el-col :span="10">
                    <el-form-item
                      :label="$t('msg.register.proofOfIdentity')"
                      prop="identityType"
                    >
                      <el-select
                        v-model="sellForm.identityType"
                        style="width: 100%"
                      >
                        <el-option
                          :label="$t('msg.register.socialSecurityCard')"
                          :value="1"
                        />
                        <el-option
                          :label="$t('msg.register.driverIsLicense')"
                          :value="2"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10" :offset="4">
                    <el-form-item
                      :label="$t('msg.register.issuingCountry')"
                      prop="srcCountry"
                    >
                      <el-select
                        v-model="sellForm.srcCountry"
                        style="width: 100%"
                      >
                        <el-option
                          :label="$t('msg.register.unitedStates')"
                          :value="1"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- SSN、驾照编号、有效期 -->
                <div v-if="sellForm.identityType === 1">
                  <el-row class="mt-20">
                    <el-col :span="10">
                      <el-form-item label="SSN" prop="ssn">
                        <el-input
                          type="text"
                          v-model="sellForm.ssn"
                          :maxlength="9"
                          :show-word-limit="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="10" :offset="4"> </el-col>
                  </el-row>
                </div>
                <div v-if="sellForm.identityType === 2">
                  <el-row class="mt-20">
                    <el-col :span="10">
                      <el-form-item
                        :label="$t('msg.register.driverIsLicenseNumber')"
                        prop="driverLicense"
                      >
                        <el-input
                          type="text"
                          v-model="sellForm.driverLicense"
                          :maxlength="15"
                          :show-word-limit="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="4">
                      <el-form-item
                        :label="$t('msg.register.validityPeriod')"
                        prop="validityPeriod"
                      >
                        <el-date-picker
                          v-model="sellForm.validityPeriod"
                          type="date"
                          placeholder="Pick a date"
                          style="width: 100%"
                          format="MM/DD/YYYY"
                          value-format="YYYY-MM-DD"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!-- 居住地址 -->
                <div class="my-20">
                  <el-form-item
                    :label="$t('msg.register.residenceAddress')"
                    prop="address"
                  >
                    <el-input
                      v-model="sellForm.address"
                      type="text"
                      readonly
                      @click="openAddressDialog"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- 经营人署名 -->
                <div
                  class="mt-20 text-16 font-[700]"
                  style="display: flex; align-items: center"
                >
                  <span class="mr-30">{{
                    $t('msg.register.signatureOfOperator')
                  }}</span>
                  <div
                    v-if="!sellForm.signature"
                    style="
                      display: flex;
                      align-items: center;
                      position: relative;
                    "
                  >
                    <el-button
                      class="w-200 h-50 z-[999]"
                      @click="openSignatureDialogFlag"
                      >{{ $t('msg.register.clickToSign') }}</el-button
                    >
                    <el-form-item
                      class="top-[18px] z-[998]"
                      prop="signature"
                      style="position: absolute"
                    >
                      <el-input
                        v-model="sellForm.signature"
                        type="text"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div v-else style="display: flex; align-items: center">
                    <el-image
                      fit="contain"
                      class="w-200 h-100 mr-20 border-2 border-dashed"
                      :src="assemblyImg(signature)"
                    />
                    <el-button
                      class="w-200 h-50"
                      @click="openSignatureDialogFlag"
                      >{{ $t('msg.register.reSignature') }}</el-button
                    >
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <div class="mt-50" style="display: flex; justify-content: center">
            <el-button class="w-300 h-50" type="primary" @click="confirm">{{
              $t('msg.common.confirm')
            }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 签字 Dialog -->
    <el-dialog width="80%" :show-close="false" v-model="signatureDialogFlag">
      <template #header>
        <div style="display: flex; align-items: center">
          <SvgIcon icon="shop-warn" class="text-40 mr-20" />
          <el-text class="mx-1 text-20 font-[700]" type="primary">
            {{
              $t(
                'msg.register.pleaseSignInTheBlankAreaAndClickConfirmAfterSigning',
              )
            }}
          </el-text>
        </div>
        <el-divider />
      </template>
      <div style="width: 100%; height: 60vh">
        <canvas
          class="border-2 border-dashed"
          style="width: 100%; height: 60vh"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="signatureDialogFlag = false">{{
            $t('msg.common.cancel')
          }}</el-button>
          <el-button type="primary" @click="applySignatureDialogFlag">{{
            $t('msg.common.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 卖家居住地址 -->
    <el-dialog
      width="40%"
      center
      v-model="businessAddressClick"
      :title="$t('msg.register.residenceAddress')"
    >
      <el-form
        label-position="top"
        ref="addressRuleFormRef"
        :model="addressRuleForm"
        :rules="addressRules"
      >
        <!-- 地址 -->
        <el-form-item
          :label="$t('msg.register.address')"
          prop="address"
          :label-width="formLabelWidth"
        >
          <el-autocomplete
            v-model="addressRuleForm.address"
            :placeholder="$t('msg.register.pleaseEnterAddress')"
            :fetch-suggestions="addressChange"
            value-key="mainText"
            class="w-full"
            :maxlength="100"
            :show-word-limit="true"
            @select="addressSelect"
          >
            <template #default="{ item }">
              <div>{{ item.description }}</div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <!-- apt -->
        <el-form-item prop="apt" :label-width="formLabelWidth">
          <el-input
            v-model="addressRuleForm.apt"
            :placeholder="$t('msg.register.pleaseEnterApt')"
            autocomplete="off"
            :maxlength="50"
            :show-word-limit="true"
          />
        </el-form-item>
        <!-- 城市 -->
        <el-form-item
          prop="city"
          :label="$t('msg.register.city')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="addressRuleForm.city"
            autocomplete="off"
            disabled
            :maxlength="30"
            :show-word-limit="true"
          />
        </el-form-item>
        <el-row class="addressFormClass" justify="space-between">
          <el-col :span="11">
            <!-- 州 -->
            <el-form-item
              prop="shorState"
              :label="$t('msg.register.state')"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="addressRuleForm.shorState"
                prop="shorState"
                placeholder=""
                disabled
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <!-- 邮编 -->
            <el-form-item
              :label="$t('msg.register.zipCode')"
              prop="postalCode"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="addressRuleForm.postalCode"
                autocomplete="off"
                disabled
                :maxlength="5"
                :show-word-limit="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addressClose">{{
            $t('msg.common.cancel')
          }}</el-button>
          <el-button type="primary" @click="addressApply">{{
            $t('msg.common.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { validateSsn } from '@/utils/validate'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { assemblyImg, loadStore } from '@/utils/store'
import SmoothSignature from 'smooth-signature'
import { dateStrFormatUtcDatetime } from '@/utils/date'

const i18n = useI18n()

const logout = () => {
  loadStore('user/logout').then(() => {
    window.location.href = '/login'
  })
}

// #region 主体
const dialogFlag = ref(true)

const sellFormRef = ref(null)
const sellForm = ref({
  identityType: 1,
  srcCountry: 1,
})
// 校验 SSN
const ssnValidate = (rule, value, callback) => {
  if (validateSsn(value)) {
    callback()
  } else {
    callback(new Error(i18n.t('msg.register.sSNFormatIsIncorrect')))
  }
}
const sellFules = ref({
  firstName: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.lengthShouldBe1To30'),
    },
    {
      min: 1,
      max: 30,
      message: i18n.t('msg.register.lengthShouldBe1To30'),
      trigger: 'blur',
    },
  ],
  lastName: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.lengthShouldBe1To30'),
    },
    {
      min: 1,
      max: 30,
      message: i18n.t('msg.register.lengthShouldBe1To30'),
      trigger: 'blur',
    },
  ],
  ssn: [
    {
      required: true,
      validator: ssnValidate,
    },
  ],
  driverLicense: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.lengthShouldBe1To15'),
    },
    {
      min: 1,
      max: 15,
      message: i18n.t('msg.register.lengthShouldBe1To15'),
      trigger: 'blur',
    },
  ],
  validityPeriod: [
    {
      type: 'date',
      required: true,
      message: i18n.t(
        'msg.register.pleaseSelectTheValidityPeriodOfYourDriverIsLicense',
      ),
      trigger: 'blur',
    },
  ],
  address: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.residenceAddressCannotBeEmpty'),
    },
  ],
  signature: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.signatureCannotBeEmpty'),
    },
  ],
})

const confirm = () => {
  sellFormRef.value.validate((valid, invalidFields) => {
    // 未通过直接返回
    if (!valid) {
      ElMessage.warning(i18n.t('msg.register.existenceWithoutFillingItem'))
      return
    }

    delete addressRuleForm.address
    const assignObj = Object.assign({}, sellForm.value, addressRuleForm)
    if (assignObj.validityPeriod) {
      assignObj.validityPeriod = dateStrFormatUtcDatetime(
        assignObj.validityPeriod + ' 23:59:59',
      )
    }
    loadStore('orgUser/saveBusinessInfo', assignObj).then((data) => {
      dialogFlag.value = false
    })
  })
}
// #endregion

// #region 签名 Dialog
const signatureDialogFlag = ref(false)
// 签名对象
let signatureObj = null
// 签名照片
const signature = ref(null)
const openSignatureDialogFlag = () => {
  signatureDialogFlag.value = true
  setTimeout(() => {
    const canvas = document.querySelector('canvas')
    signatureObj = new SmoothSignature(canvas, {
      scale: 2,
    })
  }, 100)
}
const applySignatureDialogFlag = () => {
  if (signatureObj.isEmpty()) {
    ElMessage.warning(i18n.t('msg.register.signatureCannotBeEmpty'))
    return
  }
  const signPng = signatureObj.getPNG()
  loadStore('orgUser/uploadSignature', { file: signPng }).then((data) => {
    signature.value = data.url
    sellForm.value.signature = data.path
    signatureDialogFlag.value = false
  })
}
// #endregion

// #region 地址选择
// 左侧 Label 宽度
const formLabelWidth = '100px'
// 营业地址弹框
const addressRuleFormRef = ref(null)

const addressChange = (querySearch, callback) => {
  const res = []
  if (querySearch === '') {
    callback(res)
  } else {
    loadStore('org/automaticCompletionOfAddress', {
      address: querySearch,
    }).then((data) => {
      callback(data)
    })
  }
}

const addressSelect = (item) => {
  loadStore('org/queryAddressDetails', {
    placeId: item.placeId,
  }).then((data) => {
    addressRuleForm.city = data.city
    addressRuleForm.longState = data.longState
    addressRuleForm.shorState = data.shorState
    addressRuleForm.postalCode = data.postalCode
    addressRuleForm.postalCodeSuffix = data.postalCodeSuffix
    addressRuleForm.route = data.route
    addressRuleForm.streetNumber = data.streetNumber
    addressRuleForm.country = data.country
  })
}

const addressRuleForm = reactive({
  address: '',
  apt: '',
  city: '',
  longState: '',
  shorState: '',
  postalCode: '',
  postalCodeSuffix: '',
  route: '',
  streetNumber: '',
  country: '',
})
const addressRules = reactive({
  address: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.register.pleaseEnterYourBusinessAddress'),
    },
    {
      min: 1,
      max: 100,
      message: i18n.t('msg.register.businessAddressLength'),
      trigger: 'blur',
    },
  ],
  apt: [
    {
      required: false,
      message: i18n.t('msg.register.pleaseEnterApt'),
      trigger: 'blur',
    },
    {
      min: 1,
      max: 50,
      message: i18n.t('msg.register.aptLength'),
      trigger: 'blur',
    },
  ],
  // city: [
  //   {
  //     required: true,
  //     message: i18n.t('msg.register.pleaseEnterCity'),
  //     trigger: 'blur',
  //   },
  //   {
  //     min: 1,
  //     max: 30,
  //     message: i18n.t('msg.register.cityLength'),
  //     trigger: 'blur',
  //   },
  // ],
  // shorState: [
  //   {
  //     required: true,
  //     message: i18n.t('msg.register.pleaseEnterState'),
  //     trigger: 'blur',
  //   },
  // ],
  // postalCode: [
  //   {
  //     required: true,
  //     message: i18n.t('msg.register.pleaseEnterZipCode'),
  //     trigger: 'blur',
  //   },
  //   {
  //     min: 1,
  //     max: 5,
  //     message: i18n.t('msg.register.zipCodeLength'),
  //     trigger: 'blur',
  //   },
  // ],
})

const businessAddressClick = ref(false)
const openAddressDialog = (type) => {
  businessAddressClick.value = true
}

const addressClose = () => {
  businessAddressClick.value = false
}

// 地址填写
const addressApply = () => {
  addressRuleFormRef.value.validate((valid, fields) => {
    if (valid) {
      let address = ''
      if (addressRuleForm.apt !== '') {
        address = addressRuleForm.apt + ', '
      }
      address =
        address +
        addressRuleForm.address +
        ', ' +
        addressRuleForm.city +
        ', ' +
        addressRuleForm.shorState
      if (addressRuleForm.postalCode !== '') {
        address = address + ', ' + addressRuleForm.postalCode
      }
      sellForm.value.address = address
      businessAddressClick.value = false
    } else {
      addressRuleFormRef.value.scrollToField(Object.keys(fields)[0])
    }
  })
}

const stateList = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]
// #endregion
</script>

<style lang="scss" scoped></style>
