export default (app) => {
  app.directive('size', {
    // 指令的绑定函数，在元素插入到 DOM 中时调用
    created: function (el, binding) {
      // 获取指令的参数，例如 v-size:h30 中的 h30
      const arg = binding.arg
      // 获取指令的值，例如 v-size:h30="fontSize" 中的 fontSize
      const value = binding.value
      // 根据参数设置元素的样式
      if (arg === 'w') {
        el.style.width = value + 'px'
      } else if (arg === 'h') {
        el.style.height = value + 'px'
      }
    },
  })
}
