import {
  getPickingTaskList,
  getShipmentVerificationById,
  getOnlineTicket,
  verificationCompleted,
  printPickingTask,
  printOnlineTicket,
  getAvailableAndPrintCashierCounterList,
} from '@/api/shop/pickingTask'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getPickingTaskList(context, params) {
      return await getPickingTaskList(params)
    },
    async getShipmentVerificationById(context, id) {
      return await getShipmentVerificationById(id)
    },
    async getOnlineTicket(context) {
      return await getOnlineTicket()
    },
    async verificationCompleted(context, data) {
      return await verificationCompleted(data)
    },
    async printPickingTask(context, data) {
      return await printPickingTask(data)
    },
    async printOnlineTicket(context, data) {
      return await printOnlineTicket(data)
    },
    async getAvailableAndPrintCashierCounterList(context) {
      return await getAvailableAndPrintCashierCounterList()
    },
  },
}
