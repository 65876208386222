import i18n from '@/i18n'
import { ElMessageBox } from 'element-plus'

/**
 * 会话超时弹窗
 */
class SessionTimeoutBox {
  #isLoging = false

  dialog() {
    if (!this.#isLoging) {
      this.#isLoging = true

      const message =
        i18n.global.t('msg.common.resetLoginTips2') +
        '<span style="color:#E6A23C">' +
        i18n.global.t('msg.common.resetLoginTips3') +
        '</span>' +
        i18n.global.t('msg.common.resetLoginTips4')

      const title = i18n.global.t('msg.common.resetLoginTips1')
      const buttonText = i18n.global.t('msg.common.resetLogin')

      ElMessageBox.alert(message, title, {
        confirmButtonText: buttonText,
        dangerouslyUseHTMLString: true,
        type: 'warning',
        showClose: false,
      }).then(() => {
        this.#isLoging = false
        window.location.href = '/login'
      })
    }
  }
}

export const sessionTimeoutBox = new SessionTimeoutBox()
