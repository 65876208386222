import { shopDefaultPrefix, shopConfig } from '..'
import { SHOP_DOMAIN, BIND_SHOP_DOMAIN } from '@/constant'
import { enterTheShop } from '@/utils/shop'
import { toHomeUrl, toShopUrl } from '@/utils/path'
import { getCookie, removeCookie } from '@/utils/cookies'
import { loadStoreNoLoading } from '@/utils/store'

/**
 * 路由前置守卫
 * 此路由守卫主要控制必须使用对应域名访问对应路由
 * 比如必须使用店铺自定义域名才能访问 '/shop' 开头的路由，因为 '/shop' 开头的域名才是店铺页面
 * 如果访问了 '/shop' 开头的路由，但是域名却是 'process.env.VUE_APP_BASE_ORG_DOMAIN'，那也不对
 *
 * 不对的情况，全部直接使用 window.location.href 回到 process.env.VUE_APP_BASE_ORG_DOMAIN 根地址
 */
export const shopDomain = async (to, from, next) => {
  // 当前域名
  const currHostname = window.location.hostname
  const pathname = window.location.pathname
  // 处理square特殊回调
  if (
    currHostname === process.env.VUE_APP_BASE_ORG_DOMAIN &&
    pathname === shopConfig
  ) {
    const bindShopDomain = getCookie(BIND_SHOP_DOMAIN)
    if (bindShopDomain) {
      removeCookie(BIND_SHOP_DOMAIN)
      window.location.href = window.location.href.replace(
        process.env.VUE_APP_BASE_ORG_DOMAIN,
        bindShopDomain + process.env.VUE_APP_BASE_COOKIE_DOMAIN,
      )
      return
    }
  }

  if (
    to.path.startsWith(shopDefaultPrefix) ||
    (currHostname !== process.env.VUE_APP_BASE_ORG_DOMAIN && pathname === '/')
  ) {
    // 访问的店铺页面
    if (currHostname === process.env.VUE_APP_BASE_ORG_DOMAIN) {
      // 使用了组织的域名访问店铺页面，那么重新使用组织的域名回到组织页面
      toHomeUrl()
      return
    } else if (pathname === '/') {
      toShopUrl(currHostname)
      return
    }

    // 访问的店铺页面，并且用的也不是组织域名，而是使用的店铺自定义域名

    // 判断本地 cookies 是否已经存在店铺信息
    //  不存在：使用当前店铺域名去后台获取店铺信息并存入 cookie，供后续使用。
    //  存在：判断域名与存在的店铺信息是否匹配
    //    不匹配：使用当前店铺域名去后台获取店铺信息并覆盖对应 cookie，供后续使用。
    //    匹配：不需要请求后台，直接使用即可。
    // 上述存入 cookie 的信息可以参考以下格式
    // shopDomain: 'aaaaaaaa'
    const shopDomain = getCookie(SHOP_DOMAIN)
    if (!shopDomain || shopDomain.toString() !== currHostname.split('.')[0]) {
      // 不存在 || 子域名信息不一致
      // 发起同步请求
      const shopInfo = await loadStoreNoLoading(
        'org/getShopInfoByDomain',
        currHostname.split('.')[0],
      )
      // 此处为伪代码，应像上一行代码一样真实发起请求
      // const shopInfo = {
      //   id: '111111',
      //   shopName: '我的小店',
      //   domain: 'aaaaaaaa',
      // }
      enterTheShop(shopInfo)
      return
    }

    // 判断店铺域名是否为根地址，如：https://aaaaaaa.test.lynchj.com/，如果是的话，可以使用 enterTheShop 进入店铺
    next()
  } else {
    // 访问的非店铺页面
    if (currHostname !== process.env.VUE_APP_BASE_ORG_DOMAIN) {
      // 使用了店铺的域名访问组织页面，那么重新使用组织的域名回到组织页面
      toHomeUrl()
      return
    }

    next()
  }
}
