import {
  notificationCount,
  notificationList,
  verificationNotification,
} from '@/api/shop/notify'

export default {
  namespaced: true,
  state: () => ({
    stockList: [],
  }),
  mutations: {},
  actions: {
    async notificationCount(context) {
      return notificationCount()
    },
    async notificationList(context, params) {
      return notificationList(params)
    },
    async verificationNotification(context, id) {
      return verificationNotification(id)
    },
  },
}
