import i18n from '@/i18n'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

export default (app) => {
  VXETable.setup({
    i18n: (key, args) => {
      const aaa = i18n.global.t(key, args)
      return aaa
    },
  })

  app.use(VXETable)
}
