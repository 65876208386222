import i18n from '@/i18n'

/**
 * 判断是否为外部资源
 * @param {} path 资源路径
 * @returns 是否为外部资源
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// const emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/
const emailReg = /^(?=.{1,100}$)[A-Za-z0-9_.-]+@[A-Za-z0-9]+\.[A-Za-z0-9]+$/
const emailPrefix = /^(?=.{1,76}$)[A-Za-z0-9_.-]+$/
// SSN
const ssnReg = /^\d{9}$/
// 8位数 小数，最多两位
const decimalUpTwoDigitsReg = /^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/
// 3位数 小数，最多两位
const decimalUpTwoDigitsRegThree = /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/
// 正整数
const positiveInteger = /^([1-9][\d]*|0)$/
// 大于 0 的正整数
const positiveIntegersGt0 = /^[1-9]\d{0,7}$/
// 大于等于 0 的正整数 5位数
const positiveIntegersGt0Five = /^(0|[1-9]\d{0,4})$/
// 大于等于 0 的正整数 8位数
const positiveIntegersGe0 = /^(0|[1-9]\d{0,7})$/
// 数字和小写字母组合
const combinationOfNumbersAndLowercaseLettersReg = /^[a-z0-9]+$/

export const validateUsername = () => {
  return (rule, value, callback) => {
    if (!emailReg.test(value)) {
      callback(new Error(i18n.global.t('msg.common.emialError')))
    } else {
      callback()
    }
  }
}

export const validateEmailPrefix = () => {
  return (rule, value, callback) => {
    if (!emailPrefix.test(value)) {
      callback(new Error(i18n.global.t('msg.common.emialPrefixError')))
    } else {
      callback()
    }
  }
}

export const validatePassword = () => {
  return (rule, value, callback) => {
    if (!validPassword(value)) {
      callback(new Error(i18n.global.t('msg.register.passwordRule')))
    } else {
      callback()
    }
  }
}
export const validPassword = (password) => {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(password)
}

/**
 * 验证数字和小写字母组合
 * @param {1242345aaaaaa} value
 * @returns boolean
 */
export const validCombinationOfNumbersAndLowercaseLetters = (value) => {
  return combinationOfNumbersAndLowercaseLettersReg.test(value)
}

/**
 * 验证邮箱
 * @param {aaaaaa@gmail.com} value
 * @returns boolean
 */
export const emailVerify = (value) => {
  if (emailReg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 验证手机号
 * @param {2345678923} value
 * @returns boolean
 */
export const phoneNumberVerify = (value) => {
  if (/^\d{10}$/.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 验证 SSN
 */
export const validateSsn = (ssn) => {
  // 返回验证结果
  return ssnReg.test(ssn)
}

/**
 * 8位数 + 验证小数，最多两位
 */
export const regDecimalUpTwoDigits = (value) => {
  return decimalUpTwoDigitsReg.test(value)
}

/**
 * 3位数 + 验证小数，最多两位
 */
export const regDecimalUpTwoDigitsThree = (value) => {
  return decimalUpTwoDigitsRegThree.test(value)
}

/**
 * 补足两位小数，不足的用0补充
 */
export const fillDecimalUpTwoDigits = (value) => {
  if (regDecimalUpTwoDigits(value)) {
    value = String(value)
    if (value.indexOf('.') === -1) {
      return '.00'
    }
    if (value.split('.')[1].length < 2) {
      return '0'
    }
  }

  return ''
}

/**
 * 8位数 + 验证小数，最多两位
 */
export const validateDecimalUpTwoDigits = (rule, value, callback) => {
  if (!decimalUpTwoDigitsReg.test(value)) {
    callback(new Error(i18n.global.t('msg.common.incorrectFormatReference000')))
  } else {
    callback()
  }
}

/**
 * 3位数 + 验证小数，最多两位
 */
export const validateDecimalUpTwoDigitsThree = (rule, value, callback) => {
  if (!decimalUpTwoDigitsRegThree.test(value)) {
    callback(
      new Error(i18n.global.t('msg.common.incorrectFormatReferenceThree')),
    )
  } else {
    callback()
  }
}

/**
 * 8位数 + 验证小数，最多两位，可为空
 */
export const validateDecimalUpTwoDigitsCanBeEmpty = (rule, value, callback) => {
  if (!value) {
    callback()
  } else if (!decimalUpTwoDigitsReg.test(value)) {
    callback(new Error(i18n.global.t('msg.common.incorrectFormatReference000')))
  } else {
    callback()
  }
}

/**
 * 验证正整数
 */
export const validatePositiveIntegers = (rule, value, callback) => {
  if (!positiveInteger.test(value)) {
    callback(new Error(i18n.global.t('msg.common.positiveInteger')))
  } else {
    callback()
  }
}

/**
 * 验证大于 0 的正整数，没有位数提示
 */
export const validatePositiveIntegersGt0NoLenMsg = (rule, value, callback) => {
  if (!positiveIntegersGt0.test(value)) {
    callback(new Error(i18n.global.t('msg.common.mustBeGreaterThan0')))
  } else {
    callback()
  }
}

/**
 * 验证大于 0 的正整数 个数为8位
 */
export const validatePositiveIntegersGt0 = (rule, value, callback) => {
  if (!positiveIntegersGt0.test(value)) {
    callback(
      new Error(i18n.global.t('msg.common.positiveIntegerGreaterThanZero')),
    )
  } else {
    callback()
  }
}

/**
 * 验证大于等于 0 的正整数 个数为5位
 */
export const validatePositiveIntegersFive = (rule, value, callback) => {
  if (!positiveIntegersGt0Five.test(value)) {
    callback(
      new Error(i18n.global.t('msg.common.positiveIntegerGreaterThanZero2')),
    )
  } else {
    callback()
  }
}

/**
 * 验证大于 0 的正整数
 */
export const regPositiveIntegersGt0 = (value) => {
  return positiveIntegersGt0.test(value)
}

/**
 * 验证大于等于 0 的正整数
 */
export const validatePositiveIntegersGe0 = (rule, value, callback) => {
  if (!positiveIntegersGe0.test(value)) {
    callback(
      new Error(
        i18n.global.t('msg.common.positiveIntegerGreaterThanOrEqualToZero'),
      ),
    )
  } else {
    callback()
  }
}

/**
 * 验证大于等于 0 的正整数
 */
export const regPositiveIntegersGe0 = (value) => {
  return positiveIntegersGe0.test(value)
}
