export default {
  404: {
    uHOHYoureLost: "UH OH! You're lost.",
    thePageYouAreLookingForDoesNotExist:
      'The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.',
    home: 'HOME',
  },
  toast: {
    switchLangSuccess: 'Switch Language Success',
    success: 'Success',
    changeShopTip:
      'You have switched to another store and need to refresh before continuing.',
  },
  tagsView: {
    refresh: 'Refresh',
    closeOther: 'Close Others',
    closeLeft: 'Close Lefts',
    closeRight: 'Close Rights',
  },
  navBar: {
    themeChange: 'Theme Modification',
    headerSearch: 'Page Search',
    screenfull: 'Full Screen Replacement',
    lang: 'Globalization',
    guide: 'Function Guide',
    home: 'Home',
    organizationalSystem: 'Organizational System',
    course: 'Course homepage',
    logout: 'Log out',
    personalCenter: 'Personal Center',
  },
  guide: {
    close: 'close',
    next: 'next',
    prev: 'previous',
    guideTitle: 'guidance',
    guideDesc: 'Turn on the boot function',
    hamburgerTitle: 'Hamburger button',
    hamburgerDesc: 'Open and close the left menu',
    breadcrumbTitle: 'Bread crumbs',
    breadcrumbDesc: 'Indicates the current page position',
    searchTitle: 'search',
    searchDesc: 'Page link search',
    fullTitle: 'full screen',
    fullDesc: 'Page display switching',
    themeTitle: 'theme',
    themeDesc: 'Change project theme',
    langTitle: 'globalization',
    langDesc: 'Language switch',
    tagTitle: 'Label',
    tagDesc: 'Opened page tab',
    sidebarTitle: 'menu',
    sidebarDesc: 'Project function menu',
  },
  // 以下为正式业务使用
  login: {
    title: 'User Login',
    loginBtn: 'Login',
    usernameRule: 'The email format is incorrect',
    passwordRule: 'Must consist of both numbers and letters, at least 6 digits',
    resetPassword: 'Reset Password',
    email: 'Email',
    verificationCode: 'Verification code',
    pleaseSetANewPassword: 'Please set a new password',
    enterTheNewPasswordAgain: 'Enter the new password again',
    selectTheOrganizationalSystemToLogIn: 'Select the organizational to log in',
    shopName: 'Shop Name',
    joinTime: 'Join time',
    pleaseSelectAnOrganization: 'Please select an organization',
  },
  register: {
    signUp: 'Sign up',
    storeCertification: 'Verify your store',
    registerBtn: 'Next',
    usernameRule: 'The email format is incorrect',
    passwordRule: 'Must consist of both numbers and letters, at least 6 digits',
    confirmPasswordRule: 'The passwords are inconsistent',
    passwordInconsistent: 'The passwords are inconsistent',
    verificationCodeEmailAddress: 'Verification code email address',
    verificationCodeEmailPrefix:
      'To verify your email, we sent a verification code to ',
    verificationCodeEmailSuffix: '',
    changEmail: 'Change email',
    inputVerityCode: 'Enter verification code',
    createYourMasshoAccount: 'Create your Massho account',
    resendCode: 'Resend Code',
    verificationCodeMustBeFilled: 'verification code must be filled',
    yourShopIs: 'Your store is',
    aLoudStoreNameLetMorePeopleFind:
      'A loud store name let more people find you',
    pleaseEnterTheStoreName: 'Please enter the store name',
    yourWebAddressIs: 'Your web address is',
    setUpACharacteristicUniqueWebsite:
      'Set up a unique website, let customers find you better',
    pleaseEnterTheURLPrefix: 'Please enter the URL prefix',
    theURLAlreadyExists: 'The URL already exists, please try again',
    storeContactEmail: 'Store contact email',
    aMailboxUsedToReceiveMailFromTheStore:
      'A mailbox used to receive mail from the store',
    theEmailFormatIsIncorrect: 'The email format is incorrect',
    businessAddress: 'Business address',
    theExactLocationOfTheStore: 'The exact location of the store',
    pleaseEnterYourBusinessAddress: 'Please enter your business address',
    businessAddressLength:
      'The maximum length of the business address is 100 characters',
    pleaseEnterUBINumber: 'Please enter UBI Number',
    pleaseProvideAValue: 'Please provide a value',
    pleaseEnterTheEinForTheBore:
      'Please enter the Employer Identification Number for the bore',
    serviceIndustry: 'Service industry',
    chooseAccurateIndustry:
      'Choose accurate industry, we can help you better promote',
    selectYourBusinessType: 'Select your business type',
    industryCategory: 'Industry category',
    chooseIndustryCategory:
      'Choose industry category, we can help you better promote',
    selectYourIndustryCategory: 'Select your industry category',
    contactPhoneNumber: 'Contact phone number',
    masshoCustomerServiceTeamWillContactYouWithThisPhoneNumber:
      'Massho customer service team will contact you with this phone number',
    sendAShortMessageText: 'Send a text',
    shopInformation: 'Store information',
    storePicture: 'Store picture',
    shopInfoDes:
      'Reference example of image uploading (Complete appearance drawing with store name is required to be uploaded, while overall appearance drawing without store name can be uploaded)',
    frontDeskPicture: 'Front desk picture',
    pictureUploadReferenceExample:
      'Picture upload reference example (The complete picture of the store front desk needs to be uploaded)',
    iHaveCarefullyRead: 'I have read carefully, understood, and agreed to the',
    massHOPrivacyStatement: '"MassHO Privacy policy"',
    massHOUserAgreement: '"MassHO User protocol"',
    submit: 'Submit',
    privacyStatement: 'Privacy Statement',
    userAgreement: 'User Agreement',
    back: 'Back',
    existenceWithoutFillingItem:
      'There are items that have not been filled out correctly',
    theServiceIndustryCannotBeEmpty: 'The service industry cannot be empty',
    theIndustryCategoryCannotBeEmpty: 'The industry category cannot be empty',
    isThereAGasStationOptionCannotBeEmpty:
      'Is there a gas station option cannot be empty',
    storePicturesAndFrontDeskPicturesCannotBeEmpty:
      'Store pictures and front desk pictures cannot be empty',
    notAllThePicturesWereUploaded: 'Not all the pictures were uploaded',
    pleaseReadTheAgreementCarefullyAndAgree:
      'Please read the agreement carefully and agree',
    verificationCode: 'Verification code',
    disposableVerificationCode: 'Disposable verification code',
    pleaseEnterTheVerificationCode: 'Please enter the verification code',
    thePhoneNumberFormatIsIncorrect: 'The phone number format is incorrect',
    address: 'Address',
    pleaseEnterAddress: 'Please enter address',
    pleaseEnterApt: 'Please enter apt',
    aptLength: 'The maximum length of the apt is 50 characters',
    city: 'City',
    cityLength: 'The maximum length of the city is 30 characters',
    pleaseEnterCity: 'Please enter city',
    state: 'State',
    pleaseEnterState: 'Please enter state',
    zipCode: 'Zip code',
    zipCodeLength: 'The maximum length of the zip code is 5 characters',
    pleaseEnterZipCode: 'Please enter zip code',
    sellerInformation: 'Seller Information',
    operatorInformation: 'Operator Information',
    fillInTheInformationOfTheOperatorOfTheStoreThatNeedsToBeCertified:
      'Fill in the information of the operator of the store that needs to be certified, and the operator will have the ownership and legal liability of the store.',
    firstName: 'First name',
    lengthShouldBe1To30: 'Length should be 1 to 30',
    lastName: 'Last name',
    enterYourFullNameAsItAppearsOnYourSocialSecurityCardOrDriverIsLicense:
      "Enter your full name as it appears on your Social Security card or driver's license",
    proofOfIdentity: 'Proof of Identity',
    socialSecurityCard: 'Social Security Card',
    driverIsLicense: "Driver's License",
    issuingCountry: 'Issuing country/region',
    unitedStates: 'United States',
    sSNFormatIsIncorrect: 'SSN format is incorrect, 9 digits',
    driverIsLicenseNumber: "Driver's License Number",
    lengthShouldBe1To15: 'Length should be 1 to 15',
    validityPeriod: 'Validity Period',
    pleaseSelectTheValidityPeriodOfYourDriverIsLicense:
      "Please select the validity period of your driver's license",
    residenceAddress: 'Residence Address',
    residenceAddressCannotBeEmpty: 'Residence address cannot be empty',
    nextStep: 'Next step',
    signatureOfOperator: 'Signature of operator',
    clickToSign: 'Click to sign',
    pleaseSignInTheBlankAreaAndClickConfirmAfterSigning:
      'Please sign in the blank area and click Confirm after signing.',
    signatureCannotBeEmpty: 'Signature cannot be empty',
    reSignature: 'Re-signature',
    gasStationBusiness: 'Gas Station Business',
    ifTheStoreHasRefuelingService:
      'If the store has refueling service, please select "Yes"',
    whetherThereIsAGasStationBusiness:
      'Whether there is a gas station business',
    brandLogo: 'Brand logo',
    uploadYourBrandLogo: 'Upload your brand logo',
    shopPoster: 'Store Poster',
    thePictureIsUsedToDisplayThePosterOfYourStoreOnTheUserSide:
      'The picture is used to display the poster of your store on the user side. Upload the picture with the related product of your store. A good store poster can prompt the user to place an order.',
  },
  settings: {
    operatorInformation: 'Operator Information',
    accountSecurity: 'Account Security',
    loginProtection: 'Login Protection',
    loginAccount: 'Login Account',
    yourUniqueLoginInformation: 'Your unique login information',
    account: 'Account',
    password: 'Password',
    alreadySet: 'Already set',
    changePassword: 'Change password',
    originalPassword: 'Original password',
    newPassword: 'New password',
    confirmPasswordAgain: 'Confirm password again',
    notTheSameAsTheNewPassword: 'Not the same as the new password',
    sessionTimeout: 'Session Timeout',
    theTimeRangeIsFrom1To1440Minutes:
      'The time range is from 1 to 1440 minutes. If session timeout is configured at multiple locations, the user-managed session timeout will take precedence. If no session timeout is configured at any location, the system will default to 10 minutes. If the configured session timeout time is exceeded, automatic logout will be triggered.',
    modifySessionTimeout: 'Modify session timeout',
    minutes: 'Minutes',
    sessionTimeoutCannotBeEmpty: 'Session timeout cannot be empty',
    notSet: 'Not set',
  },
  route: {
    shopSelect: 'Store Selection',
    account: 'Account Management',
    roleManage: 'Organizational Role Management',
    shopRoleManage: 'Store Position Management',
    shopRole: 'Store Role',
    storePosition: 'Store Position',
    // =============================
    shopIndex: 'Home',
    analysisReport: 'Analysis Report',
    // =============================
    onlineGoodsManage: 'Online Product Management',
    pushGoods: 'Publish Product',
    pushGoods1: 'Edit Product',
    pushGoods2: 'View Product',
    onlineProducts: 'Online Products',
    releaseGoods: 'Product entry',
    releaseGoods1: 'Edit Product',
    releaseGoods2: 'View Product',
    platformClassify: 'Platform Classification',
    platformClassifyAdd: 'Add Platform Classification',
    platformClassifyDsc: 'Platform Classification Details Page',
    // =============================
    goodsManage: 'Product Management',
    goodsManageGoodsListInfo: 'Product Information',
    goodsClassify: 'Product Classification',
    goodsClassifyAdd: 'Add Product Classification',
    goodsClassifyAdd1: 'Edit Product Classification',
    goodsClassifyDsc: 'Product Classification Details',
    rawMaterial: 'Raw Material Management',
    rawMaterialClassify: 'Raw Material Classification',
    rawMaterialClassifyAdd: 'Add Raw Material Classification',
    rawMaterialClassifyAdd1: 'Edit Raw Material Classification',
    rawMaterialClassifyDsc: 'Raw Material Classification Details',
    rawMeasureUnit: 'Measurement Unit',
    fuelCardInformation: 'Fuel Card Information',
    rechargeCardService: 'Recharge Card Service',
    shopManage: 'Store Management',
    shopSetting: 'Store Settings',
    cashierManagement: 'Counter Management',
    logisticsManagement: 'Logistics Management',
    printersManagement: 'Printers Management',
    afterSalesManage: 'After-sales Management',
    afterSalesInfo: 'After-sales List',
    afterSalesDes: 'After-sales Details',
    walletManage: 'Wallet Management',
    walletInfo: 'Wallet Management',
    creditLimitManagement: 'Credit Limit Management',
    mortgageFunds: 'Mortgage Funds',
    OrderManage: 'Order Management',
    SelfPickOrderInfo: 'Self-pickup Order List',
    selfPickOrderDes: 'Order Details',
    DeliveryOrderInfo: 'Delivery Order List',
    deliveryOrderDes: 'Order Details',
    OfflineOrderInfo: 'Offline Order',
    offlineOrderDes: 'Order Details',
    offlineCheckout: 'Checkout Counter',
    OnlineOrderInfo: 'Online Order',
    onlineOrderDes: 'Order Details',
    PickingTaskInfo: 'Picking Task',
    StockManage: 'Inventory Management',
    goodsStock: 'Product Inventory',
    goodsStockInfo: 'Product Inventory Details',
    rawmaterialStock: 'Raw Material Inventory',
    rechargeCardStock: 'Recharge Card Inventory',
    rawmaterialStockInfo: 'Raw Material Inventory Details',
    shelfManage: 'Shelf Management',
    guaranteeService: 'Guarantee Service',
    operGuaranteeTemplate: 'Add Guarantee Services',
    operGuaranteeTemplate1: 'Edit Guarantee Services',
    operGuaranteeTemplate2: 'Guarantee Template Details',
    guaranteeTemplate: 'Guarantee Template',
    guaranteeType: 'Guarantee Type',
    settings: 'Settings',
    notificationSettings: 'Notification Settings',
    inventoryWarning: 'Inventory Warning',
    inventoryWarningSettings: 'Inventory Warning Settings',
    purchaseManage: 'Purchase Management',
    purchaseOrder: 'Purchase Order',
    warehouseManage: 'Warehouse Management',
    warehouse: 'Warehouse',
    inbound: 'Inbound',
    outbound: 'Outbound',
    shelf: 'Shelf',
    shelfWarning: 'Shelf Warning',
    shelfWarningSettings: 'Shelf Warning Settings',
    shelfOutbound: 'Shelf outbound',
    outboundDetails: 'Outbound Details',
    inboundDetails: 'Inbound Details',
    putOnTheShelf: 'Put On The Shelf',
    operShelfGoods: 'Edit Product',
    operShelfGoods1: 'Product Details',
    purchaseOperDetails: 'Purchase Details',
    purchaseOperDetails1: 'Review Purchase Order',
    // =============================
    profile: 'Personal Center',
    user: 'User',
    excelImport: 'Excel Import',
    userManage: 'User Management',
    userInfo: 'User Information',
    roleList: 'Role List',
    permissionList: 'Permission List',
    article: 'Article',
    articleRanking: 'Article Ranking',
    articleCreate: 'Create Article',
    articleDetail: 'Article Details',
    articleEditor: 'Article Editing',
    shelfDesc: 'Shelf Management Details',
    data: 'Data',
    operatingStatement: 'Operating Statement',
    summaryTable: 'Summary Table',
    orderDeductionDetails: 'Order Deduction Details',
    paymentDetails: 'Payment Details',
    tipDetails: 'Tip Details',
    taxesDetails: 'Taxes Details',
    refundDetails: 'Refund Details',
  },
  component: {
    searchUser: 'Search user',
  },
  // 通用
  common: {
    other: 'Other',
    modify: 'Modify',
    sorryInsufficientPermissions: 'Insufficient permissions',
    characterAtLeast: 'Character at least: ',
    characterLengthLimit: 'Character length limit: ',
    uploadingInProgress: 'Uploading in progress...',
    uploadImageErrorPleaseTryAgain: 'Upload image error, please try again',
    setting: 'Setting',
    exist: 'Exist',
    none: 'None',
    quantityMustBeGreaterThan0: 'Quantity must be greater than 0',
    quantityMustBeGreaterThanOrEqualTo0:
      'Quantity must be greater than or equal to 0',
    submit: 'Submit',
    download: 'Download',
    selectAll: 'Select all',
    pleaseSelect: 'Please select',
    view: 'View',
    all: 'All',
    copySuccessful: 'Copy successful',
    warning: 'Warning',
    pleaseEnter: 'Please enter',
    length130Bits: 'Length 1-30 bits',
    search: 'Search',
    reset: 'Reset',
    new: 'New',
    operation: 'Operation',
    edit: 'Edit',
    details: 'Details',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    cannotBeEmpty: 'Cannot be empty',
    description: 'Description',
    create: 'Create',
    select: 'Select',
    clear: 'Clear',
    add: 'Add',
    more: 'More',
    basicInformation: 'Basic Information',
    verificationFailedThereAreUnfilledItems:
      'There are items that have not been filled out correctly',
    status: 'Status',
    pleaseSelectAStartTime: 'Start time',
    pleaseSelectAnEndTime: 'End time',
    creationTime: 'Creation Time',
    to: 'To',
    back: 'Back',
    confirm: 'Confirm',
    sendCode: 'Send code',
    seconds: 's',
    copy: 'Copy',
    copyUrl: 'Copy URL',
    change: 'Change',
    verification: 'Verification',
    success: 'Success',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
    pleaseInputPassword: 'Please input Password',
    lengthShouldBe6To20: 'Length should be 6 to 20',
    lengthShouldBe6To25: 'Length should be 6 to 25',
    theCurrentBrowserDoesNotSupportFullScreen:
      'The current browser does not support full screen',
    areYouSureToDeleteThis: 'Are you sure to delete this?',
    loginHasExpiredPleaseLogInAgain: 'Login has expired, please log in again',
    iKnow: 'I see',
    emialError: 'Email format error. 1-100 characters, a-z, A-Z, 0-9, ., _, -',
    emialPrefixError:
      'Email format error. 1-76 characters, a-z, A-Z, 0-9, ., _, -',
    warmTips: 'Warm Tips',
    incorrectFormatReference000:
      'reference: 0.00. Incorrect format, Longest 8 digits',
    incorrectFormatReferenceThree:
      'reference: 0.00. Incorrect format, Longest 3 digits',
    positiveInteger: 'positive integer',
    positiveIntegerGreaterThanZero: '> 0, Longest 8 digits',
    positiveIntegerGreaterThanZero1: '> 0, Longest 5 digits',
    positiveIntegerGreaterThanZero2: '>= 0, Longest 5 digits',
    mustBeGreaterThan0: 'Must be greater than 0',
    positiveIntegerGreaterThanOrEqualToZero: '>= 0, Longest 8 digits',
    resetLogin: 'Re-login',
    resetLoginTips1: 'Session timed out, please log in again',
    resetLoginTips2: 'You need to go to ',
    resetLoginTips3: 'user management or organization role management ',
    resetLoginTips4: 'to set the session timeout',
    minute: 'Minute',
    noData: 'No Data',
    zero: '0.00',
    tip: 'Tip',
    helpManual: 'Help Manual',
    ok: 'OK',
    replace: 'Replace',
    imageSizePrompt: 'Please upload an image within a size of 3M',
    uploadTip:
      'Suggested image requirements: 1:1 ratio, size 750px * 750px or larger, size within 3M',
    settingSuccess: 'Setting successful',
    clearSelected: 'Clear Selected',
    viewSelected: 'View Selected',
    viewDetails: 'View Details',
    combinationOfNumbersAndLowercaseLetters:
      'Combination of numbers and lowercase letters',
  },
  // 店铺选择
  storeSelect: {
    shopSelection: 'Store selection',
    pleaseChooseAShopToOpenYourBusiness:
      'Please choose a store to open your business',
    createANewStore: 'Create a new store',
    inBusiness: 'in business',
    resting: 'Resting',
    underReview: 'under review',
    approved: 'Approved',
    auditRejected: 'Audit rejected',
    atRest: 'At rest',
    auditRejection: 'Audit rejection',
    checkTheCause: 'Check the cause',
    rejectionReasons: 'Rejection reasons',
    cancel: 'cancel',
    toRevise: 'to revise',
    ordersBy30Days: '30-day order count',
    totalOrderAmountInTheLast30Days: '30-day order total',
    openTheShop: 'Open the store',
    canOnlyEnterTheStoreThatHasPassedTheReview:
      'Can only enter the store that has passed the review',
  },
  accountManagement: {
    account: 'Account',
    status: 'Status',
    all: 'all',
    enable: 'enable',
    disable: 'disable',
    addAccount: 'Add Account',
    firstName: 'First name',
    pleaseEnterFirstName: 'Please enter first name',
    lastName: 'Last name',
    pleaseEnterLastName: 'Please enter last name',
    pleaseEnterYourEmail: 'Please enter your email',
    organizationalRoles: 'Organizational roles',
    pleaseSelectAnOrganizationRole: 'Please select role',
    afterThePersonIsSuccessfullyCreated:
      "After the person is successfully created, the initial login password is '123456a'",
    administratorName: 'Administrator Name',
    shopRole: 'Store role',
    storePosition: 'Store position',
    editUser: 'Edit User',
    employee: 'Employee',
    operationGuide: 'Operation Guide',
    byAddingEmployeesAndAssigningThemTheAppropriatePermissions:
      'By adding employees and assigning them the appropriate permissions, they will have access to your store.',
    displayTheApprovedStoresAnd:
      'Display the approved stores and assign corresponding store position to your employees.',
    employeeName: 'Employee Name',
    shopName: 'Store Name',
    permissionAssignment: 'Permission Assignment',
    configurePermissions: 'Configure Permissions',
    rolesAlreadyAssigned: 'Assigned positions',
    roleName: 'Position Name',
    newOrganizationRole: 'New Organization Role',
    organizationRoleName: 'Organization Role Name',
    numberOfAssociatedAccounts: 'Number of associated accounts',
    organizationPermissions: 'Organization Permissions',
    manageThePermissionsOfThisOrganization:
      'Manage the permissions of this organization. You can manage the permissions of multiple stores under this organization through the organization permissions.',
    selectAllPermissions: 'Select all permissions',
    foldAll: 'Fold all',
    expandAll: 'Expand All',
    editOrganizationRole: 'Edit Organization Role',
    viewOrganizationRole: 'View Organization Role',
    organizationRoleDetails: 'Organization Role Details',
    newShopRole: 'New Store Position',
    shopRoleName: 'Store Position Name',
    shopPermission: 'Store Permission',
    permissionToManageThisStore:
      'Permission to manage this store. Employees with this permission can view content or perform operations in your store.',
    editShopRole: 'Edit Store Position',
    viewShopRole: 'View Store Position',
    permissionToManageTheStore:
      'Permission to manage the store, the content that employees can view or perform in your store.',
    numberOfRoles: 'Number of positions',
    manageShopRoles: 'Manage store position',
    theShopDoesNotExist: 'The store does not exist',
    loginAccount: 'Login Account',
    yourUniqueLoginInformation: 'Your unique login information',
    accountEmail: 'Account email',
    fillInTheInformationOfTheOperatorOfTheStoreThatNeedsToBeCertified:
      'Fill in the information of the operator of the store that needs to be certified, and the operator will have the ownership and legal liability of the store.',
    accordingToTheOperationSteps:
      'According to the operation steps, add your employees and let them manage your store.',
    addYourOrganizationRole: 'Add your organization role',
    addYourStoreRole: 'Add your store position',
    thePermissionsForManagingThisStore:
      'The permissions for managing this store, employees can view the content or perform operations in your store.',
    addYourEmployees: 'Add your employees',
    addYourEmployeesInUserManagementAndAssignThemCorrespondingPermissions:
      'Add your employees in user management and assign them corresponding permissions. They will have access to your store.',
    pleaseSelectPermissions: 'Please select permissions',
    thePasswordResetFor: 'The password reset for',
    wasSuccessful: 'was successful.',
    initialLoginPassword123456a: 'Initial login password: 123456a',
    sessionTimeout: 'Session timeout',
    sessionTimeoutTips:
      'The time range is from 1 to 1440 minutes. If session timeout is configured in multiple locations, user management session timeout takes the highest priority. If session timeout is not configured in any location, the system defaults to 10 minutes. If the configured session timeout is exceeded, it will automatically trigger a logout.',
  },
  indexManage: {
    totalOrdersToday: 'Total Orders Today',
    totalSalesToday: 'Total Sales Today',
    todaysReturnOrder: "Today's Return Order",
    refundAmountToday: 'Refund Amount Today',
    productOverview: 'Product Overview',
    analysisReport: 'Analysis report',
    allProducts: 'All products',
    onSale: 'On sale (online store)',
    offSale: 'Off sale (online store)',
    inventoryWarning: 'Inventory warning',
    inventoryWarningOnlineStore: 'Inventory warning (online Store)',
    shelfWarning: 'Shelf warning',
    countTheQuantityOfYourInsufficientGoodsOrRawMaterialInventory:
      'Count the quantity of your insufficient goods or raw material inventory',
    productInventory: 'Product inventory',
    rawMaterialInventory: 'Raw material inventory',
    goToHandle: 'Go to handle',
    reportMsg:
      '1. Sales = unit price of goods * number (excluding refunded). <br >2. Gross profit = Sales Revenue - Cost Price of Goods. <br >3. Proportion of returned goods = number of returned goods/number of sold goods. <br >4. number of orders = if an order has more than one SKU, it is recorded as one order number.',
    theAnalysisReportDataStatisticsDoNotIncludeTheCurrentDay:
      'The analysis report data statistics do not include the current day',
    productName: 'Product Name',
    time: 'Time',
    days: 'days',
    theQuantityOfYourProductIsLessThan10:
      'The quantity of your product is less than 10, which cannot be analyzed',
    productNameSpecification: 'Product name/Specification',
    salesRevenue: 'Sales revenue',
    grossProfit: 'Gross profit',
    returnProductRatio: 'Return product ratio',
    numberOfOrders: 'Number of orders',
    pendingTransactions: 'Pending transactions',
    abnormalOrder: 'Abnormal order',
    pickingTask: 'Picking task',
    picture: 'Picture',
  },
  goodsManage: {
    duplicateSpecificationNameExists: 'Duplicate specification name exists',
    duplicateSpecificationValuesExist: 'Duplicate specification value exist',
    noRawMaterialsAvailable: 'No raw materials available',
    goAdd: 'go add',
    productName: 'Product name',
    productClassification: 'Product classification',
    pleaseEnter: 'Please enter',
    search: 'Search',
    reset: 'Reset',
    new: 'New',
    productID: 'Product ID',
    productPictures: 'Product Pictures',
    productNameAndSpecification: 'Product Name/Specification',
    barCode: 'Bar Code',
    creationTime: 'Creation Time',
    operation: 'Operation',
    edit: 'Edit',
    details: 'Details',
    delete: 'Delete',
    basicInformation: 'Basic Information',
    classifyTip:
      'The product category does not meet the requirements. Please add the appropriate category and then make your selection.',
    unit: 'Unit',
    taxRate: 'Tax rate',
    taxTip: 'Customized tax fees can be charged for specified products.',
    thisIsComposedOfRawMaterials: 'This is composed of raw materials',
    productSpecificationsAndInventory: 'Product Specifications',
    specificationName: 'Specification name',
    specificationValue: 'Specification value',
    pleaseSelect: 'Please select',
    addSpecification: 'Add specification',
    sellingPrice: 'Selling price',
    originalPrice: 'Original price',
    costPrice: 'Cost price',
    productParticipatingInPromotion: 'Product Participating in Promotion',
    usageRatioOfMacou: 'Usage Ratio of Macou',
    macouTip:
      'Users can enjoy the maximum deduction ratio/maximum coin rebate ratio when purchasing this product. Red bottle deduction and blue bottle rebate can only choose one of the two.',
    deduction: 'deduction',
    deductionOrRebate: 'deduction/rebate',
    rebate: 'rebate',
    rebate1: 'rebate',
    save: 'Save',
    cancel: 'Cancel',
    cannotBeEmpty: 'Cannot be empty',
    gtZero: 'An integer/decimal greater than or equal to 0.',
    canOnlyHaveUpTo8Digits: 'Can only have up to 8 digits',
    geOnlyZero: 'Can only be an integer greater than or equal to 0',
    gtOnlyZero: 'Can only be an integer greater than to 0',
    verificationFailedThereAreUnfilledItems:
      'There are items that have not been filled out correctly',
    description: 'Description',
    correlationQuantity: 'Correlation quantity',
    des: 'Description',
    pleaseEnterACategoryName: 'Please enter a category name',
    classificationDescription: 'Classification description',
    pleaseEnterTheCategoryDescription: 'Please enter the category description',
    create: 'Create',
    lengthShouldBeBetween2And10: 'Length should be between 2 and 10',
    lengthShouldBeBetween2And20: 'Length should be between 2 and 20',
    thisCategoryIsNotRelated:
      'There are no related products for this product category at the moment.',
    relatedGoods: 'Related goods',
    nameOfRawMaterial: 'Name of raw material',
    classificationOfRawMaterials: 'Classification of materials',
    rawMaterialID: 'Raw material ID',
    picture: 'Picture',
    numberOfAssociatedProducts: 'Number of products',
    addRawMaterialInformation: 'Add raw material information',
    editRawMaterialInformation: 'Edit raw material information',
    rawMaterialInformationDetails: 'Raw material information details',
    classificationOfRawMaterialsTip:
      'If there is no suitable classification for the raw materials, please add it to the raw material classification before selecting.',
    unitTip:
      'Select the smallest unit of raw materials to facilitate inventory management',
    rawMaterialPicture: 'Raw material picture',
    rawMaterialSpecification: 'Raw material specification',
    inventoryQuantity: 'Inventory quantity',
    theInventoryQuantityWillBe:
      'The inventory quantity will be synchronized with inventory management [raw material inventory]',
    categoryNames: 'Category names',
    relatedRawMaterials: 'Related raw materials',
    thisClassificationHasNo:
      'This classification has no associated raw materials',
    nameOfUnit: 'Name of unit',
    unitType: 'Unit type',
    status: 'Status',
    unitSource: 'Unit source',
    enable: 'Enable',
    disable: 'Disable',
    newUnit: 'New Unit',
    compositionOfRawMaterialsForThisProduct:
      'Composition of raw materials for this product',
    compositionOfCommodityRawMaterials:
      'Composition of commodity raw materials',
    copyRawMaterials: 'Copy raw materials',
    rawMaterials: 'Raw Materials',
    quantityOfRawMaterials: 'Quantity of raw materials',
    clickToSelectRawMaterials: 'Click to select raw materials',
    selectionOfRawMaterials: 'Selection of raw materials',
    shelfLocation1: 'Shelf Location',
    pleaseFillInTheFormBeforeCopying: 'Please fill in the form before copying',
    setRedTips:
      'Set the red bottle deduction/rebate, which means that the user can enjoy the maximum deduction ratio when purchasing this product. If the corresponding user pays with cash, the merchant will return the corresponding macou, for example (a product is 100 US dollars, set a 50% deduction ), if the user buys this product with 100 dollars, he will get 50macou.',
    setBlueTips:
      'Set the blue bottle rebate, which means that the user can only rebate when purchasing this product. For example (a product is 100 US dollars, set 100% rebate), the user will get 100macou for purchasing this product.',
    goodNoTips:
      'Item no: Merchants can set a non-repetitive item No. for easy memory.',
    shelfLocation:
      'Shelf location: The product needs to be put on the shelf to the corresponding shelf position.',
    scaleCantNotEmpty: 'Please select macou ratio',
    shelfNullTips1:
      'If no matching shelf location is found, please add it in shelf management, ',
    shelfNullTips2: 'to add>',
    thisProductHasBeenPutOnTheOnlineStoreAndCannotBeChanged:
      'This product has been put on the online store and cannot be changed.',
    thisProductHasBeenAddedToTheOnlineStoreAndCannotBeDeleted:
      'This product has been added to the online store and cannot be deleted.',
    fuelCardName: 'Fuel card name',
    fuelCardPicture: 'Fuel card picture',
    parValue: 'Par Value',
    addedFuelCard: 'Added fuel card',
    parValueOfFuelCard: 'Par value of fuel card',
    cashMacouReturnCoin: 'Cash Macou Return Coin',
    thePaymentMethodIsDifferent:
      'The payment method is different, and the Macou return currency is inconsistent',
    macouReturnCurrencyValue:
      'Macou return currency value, please select the corresponding fuel card face value',
    cardPayMacouReturnCoin: 'Card Pay Macou Return Coin',
    lengthShouldBe1To75: 'Length should be 1 to 75',
    macouReturnCoin: 'Macou Return Coin',
    fuelCardInformation: 'Fuel Card Information',
    addFuelCard: 'Add fuel card',
    editFuelCard: 'Edit fuel card',
    viewFuelCard: 'View fuel card',
    addRechargeCard: 'Add recharge card',
    editRechargeCard: 'Edit recharge card',
    viewRechargeCard: 'View recharge card',
    enablePackagingUnits: 'Enable packaging units',
    theBasicUnitIsTheSmallestSellingUnitOfACommodity:
      'The basic unit is the smallest selling unit of a commodity. If the commodity has other units, packaging units can be used.',
    packagingUnit: 'Packaging unit',
    unitConversion: 'Unit conversion',
    pleaseFillInTheUnitConversionCarefully:
      'Please fill in the unit conversion carefully. Once enabled, it cannot be canceled or modified.',
    packagingBarcode: 'Packaging barcode',
    selectExistingImage: 'Select existing image',
    functionSettings: 'Function settings',
    guaranteeService: 'Guarantee service',
    ifYourProductSupportsGuaranteeServices:
      'If your product supports guarantee services, please fill in the relevant information of the guarantee services. Guarantee services also enjoy Macou deductions or rebates.',
    selectGuarantee: 'Select Guarantee',
    selectType: 'Select type',
    guaranteeType: 'Guarantee type',
    guaranteeName: 'Guarantee name',
    ifNoMatchingCoverageNameIsFound:
      'If no matching coverage name is found, please add it in the coverage template.',
    pleaseSelectTheTypeOfGuaranteeFirst:
      'Please select the type of guarantee first',
    PleaseSelectTheGuaranteeServiceFirst:
      'Please select the guarantee service first',
    serviceSupportsMacouDeductionOrRebate:
      'Service supports Macou deduction or rebate',
    checkTheOptionToSupportMacouRebates:
      'Check the option to support Macou rebates, and the proportion will be consistent with the product',
    price: 'Price',
    validityPeriod: 'Validity Period (Days)',
    type: 'Type',
    commodity: 'Commodity',
    rawMaterialCommodities: 'Raw material commodities',
    batchSet: 'Batch set',
    cannotBeCanceledOrModifiedAfterSaving:
      'Cannot be canceled or modified after saving',
    unitKind: 'Unit kind',
    basicUnit: 'Basic unit',
    cannotBeCanceledAfterSaving: 'Cannot be canceled after saving',
    purchasingCycle: 'Purchasing cycle',
    purchasingCycleTips:
      'It is recommended to fill in the longest procurement cycle, that is, the longest time from initiating procurement to warehousing, to avoid out of stock.',
    stockingTime: 'Stocking time',
    stockingTimeTips:
      'For special items, you can set a separate lead time, which means additional time is needed to prepare the item.',
    singleDaySalesLimit: 'Single day sales limit',
    singleDaySalesLimitTips:
      'There is a daily cap on product sales, including online and offline orders. Once the daily sales volume reaches the upper limit, the product status will be displayed as "Out of Stock". This limit is updated daily at 00:00 AM. If no daily sales limit is set, the system defaults to infinity.',
    day: 'Day',
    example: 'Example',
    productPurchaseLimit: 'Product purchase limit',
    productPurchaseLimitTips:
      'Total purchase limit = basic purchase limit + purchase limit for inviting new users. If not filled in, there is no limit on the purchase quantity of the product.',
    basicPurchaseLimit: 'Basic purchase limit',
    inviteExtraQuantity: 'Invite a new user to get additional purchase limits',
    productNumber: 'Item no',
    productType: 'Product type',
    specifications: 'Specifications',
    purchaseTime: 'Purchase time',
    itIsRecommendedToFillInTheLongestProcurementTime:
      'It is recommended to fill in the longest procurement time, that is, the longest time from initiating procurement to inventory entry, to avoid stockouts.',
    relatedProducts: 'Related Products',
    numberOfUses: 'Number of uses',
    refuelingCard: 'Refueling card',
    universalRechargeCard: 'Universal recharge card',
    name: 'Name',
    rechargeCardType: 'Recharge card type',
    updateGoodsTips:
      'The product has been published to the online store. If the following information is modified, it will be updated to the online store simultaneously:',
    updateCardTips:
      'The recharge card has been posted to the online store. If the following information is modified, it will be updated to the online store simultaneously:',
  },
  onlineGoodsManage: {
    productName: 'Product Name',
    onlineProducts: 'Online products',
    manageTheProductsOfYourOnlineStore:
      'Manage the products of your online store. Users will browse your store and purchase products through APP.',
    upload: 'Upload',
    all: 'All',
    onSale: 'On Sale',
    onSale2: 'On Sale',
    offSale: 'Off Sale',
    offSale2: 'Off Sale',
    soldOut: 'Sold Out',
    productImage: 'Product Image',
    inventory: 'Inventory',
    availableInventory: 'Available Inventory',
    availableInventoryTheSumOfTheMaximumSellableQuantitiesForEachSKUOfAProduct:
      'Available inventory = the sum of the maximum sellable quantities for each SKU of a product.<br />· If some SKUs of the product are out of stock, display partial out-of-stock. <br />· If all SKUs of the product are out of stock, display out-of-stock.',
    partiallyOutOfStock: 'Partially out of stock',
    outOfStock: 'Out of stock',
    replenish: 'Replenish',
    batchOperation: 'Batch operation',
    publish: 'Publish',
    publishGoods: 'Publish Goods',
    nextStep: 'Next step',
    oneClickPublishing:
      'One-click publishing: it can be uploaded in batches. it is suitable for direct publishing after selection without modifying any information of the goods (such as the selling price of the goods, the upper limit of sales, etc.).',
    editAndPublish:
      'Edit and publish: specify the product to be edited and put on shelves. Only one product can be put on shelves at a time.',
    publishingMethod: 'Publishing Method',
    oneClickPublish: 'One-click publish',
    editPublish: 'Edit Publish',
    productID: 'Product ID',
    productCategories: 'Product Categories',
    deliveryMethod: 'Delivery method',
    selfPickUp: 'Self pick-up',
    delivery: 'Delivery',
    toManage: 'to manage',
    selectTheTypeOfGoodsToSupportDistribution:
      'Select the type of goods to support distribution, and support multiple choices of self-delivery and distribution.',
    makeSureToRelease: 'Make sure to release ',
    items: ' items',
    afterThePublicationIsSuccessful:
      'After the publication is successful, it will be displayed in the online product list, and you also need to manually put the products on the shelves.',
    releaseRechargeCard1: ' recharge card',
    afterThePublicationRechargeCardIsSuccessful:
      'After the publication is successful, it will be displayed in the online product list, and you also need to manually put the recharge card on the shelves.',
    basicInformation: 'Basic information',
    taxRate: 'Tax rate',
    productSpecificationsAndInventory: 'Product specifications and inventory',
    setSalesLimit: 'Set sales limit',
    ifYouWantToSellPartOfTheNumberOfGoods:
      'If you want to sell part of the number of goods, please check the set sales limit, if not checked, the number of goods sold = total inventory quantity.',
    productNumber: 'Item no',
    productNumberMerchantsCanSetAUniqueProductNumberForEasyMemorization:
      'Item no: Merchants can set a unique item no for easy memorization.',
    picture: 'Picture',
    barcode: 'Barcode',
    shelfLocation: 'Shelf location',
    shelfLocationTheGoodsNeedToBePlacedOnTheCorrespondingShelfLocation:
      'Shelf location: The goods need to be placed on the corresponding shelf location.',
    salesLimit: 'Sales limit',
    totalInventory: 'Total inventory',
    statusSettings: 'Status Settings',
    additionalDeliveryFee: 'Additional delivery fee',
    extraAdditionalDeliveryFeesCanBeSetForTheCurrentProduct:
      'Extra additional delivery fees can be set for the current product.',
    productDetails: 'Product Details',
    productDescription: 'Product Description',
    brieflyDescribeTheInformationOfTheProductToLetMorePeopleUnderstandTheProductInformation:
      'Briefly describe the information of the product to let more people understand the product information.',
    descriptionOfProductInformation:
      'Description of product information. Allows users to quickly understand the function of the product.',
    confirmSettingThisProductAs: 'Confirm setting this product as ',
    theListedProductsWillBeDisplayedInTheStore:
      'The listed products will be displayed in the store, and your customers can browse and purchase them.',
    theGoodsThatHaveBeenRemovedFromTheShelvesWillBeHiddenInTheStoreAndCustomersWillNotBeAbleToViewThem:
      'The goods that have been removed from the shelves will be hidden in the store and customers will not be able to view them.',
    afterSuccessfulDeletion:
      'After successful deletion, the product list will no longer display this item.',
    pleaseSelectTheItemFirst: 'Please select the item first',
    replenishment: 'Replenishment',
    guideToPublishingProducts: 'Guide to Publishing Products',
    uploadYourProductsToTheOnlineStoreForSaleAccordingToTheOperationSteps:
      'Upload your products to the online store for sale according to the operation steps',
    addYourProduct: 'Add your product',
    addYourProductInTheProductInformation:
      'Add your product in the product information.',
    addYourLogisticsInformation: 'Add your logistics information',
    createLogisticsInformationThatMatchesYourOnlineStore:
      'Create logistics information that matches your online store.',
    publishProducts: 'Publish products',
    onlineProductRelease:
      'Online product release, users will discover your store and browse products through the app.',
    successfullyPublished: 'Successfully published',
    successfullyOnSale: 'Successfully on sale',
    itemsWith: 'items, with',
    itemFailedToBePublished: 'item failed to be published.',
    itemFailedToBeOnSale: 'item failed to be put on the shelves.',
    successfulPublication: 'Successful publication',
    successfulPublicationPartialFailure:
      'Successful publication, partial failure',
    failedToPublish: 'Failed to publish',
    chooseAtLeastOneDeliveryMethod: 'Choose at least one delivery method',
    saleOfLimitedMerchandise: 'Sale of limited merchandise',
    productType: 'Product Type',
    outOfStockStatus: 'Out Of Stock Status',
    supplementInventoryForInventoryManagement:
      'Supplement inventory for inventory management',
    putTheGoodsOnTheShelves: 'Put the goods on the shelves',
    putTheGoodsOnTheShelves1: 'Put the goods on the shelves.',
    product: 'Product',
    rawMaterialsProduct: 'Raw materials product',
    salesMethod: 'Sales method',
    regularProduct: 'Regular product',
    preSaleProduct: 'Pre-sale product',
    rechargeCard: 'Recharge card',
    productNameAndSpecification: 'Product Name/Specification',
    storeInventoryTotal: 'Store Inventory Total',
    availableActualInventoryMinusOtherOccupiedInventory:
      'Available = actual inventory minus other occupied inventory, that is, inventory available for immediate shipment.',
    unit: 'Unit',
    thisUnitDisplaysTheBaseUnit: 'This unit displays the base unit.',
    salesMethodIsARequiredField: 'Sales method is a required field',
    publishResults: 'Publish Results',
    reasonForFailure: 'Reason for failure',
    shelfProducts: 'Shelf products',
    shelfCard: 'Shelf card',
    releaseRechargeCard: 'Release recharge card',
    oneClickPublishingItCanBeUploadedInBatches:
      'One-click publishing: it can be uploaded in batches. it is suitable for direct publishing after selection without modifying any information of the prepaid card (such as face value, participating in activities, etc.).',
    editAndPublishSpecifyTopUpCardsForEditingAndPublishing:
      'Edit and Publish: specify top-up cards for editing and publishing. Only one top-up card can be published at a time.',
    name: 'Name',
    refuelingCard: 'Refueling card',
    universalRechargeCard: 'Universal recharge card',
    type: 'Type',
    parValue: 'Par value',
    returnCoins: 'Return coins',
    theReturnCoinsCannotBeEmptyAtLeast0:
      'The return amount cannot be empty, at least 0.',
    pleaseUploadTheImage: 'Please upload the image.',
    productImageCannotBeEmpty: 'Product image cannot be empty',
    basicUnit: 'Basic unit',
    enablePackagingUnits: 'Enable packaging units',
    packagingUnit: 'Packaging unit',
    unitConversion: 'Unit conversion',
    thisIsComposedOfRawMaterials: 'This is composed of raw materials',
    purchaseTime: 'Purchase time',
    stockingTime: 'Stocking time',
    singleDaySalesLimit: 'Single day sales limit',
    batchSet: 'Batch set',
    preSaleItemsPreSaleItemsReferToProductsThatAreNotInStockInTheStoreAndCanOnlyBePurchasedOnline:
      'Pre-sale items: Pre-sale items refer to products that are not in stock in the store and can only be purchased online.',
    specifications: 'Specifications',
    shelfLocal: 'Shelf local',
    shelfLocationTheProductNeedsToBePutOnTheShelfToTheCorrespondingShelfPosition:
      'Shelf location: The product needs to be put on the shelf to the corresponding shelf position.',
    limitedPurchaseQuantityOfGoods: 'Limited purchase quantity of goods',
    totalPurchaseLimitQuantity:
      'Total purchase limit quantity = basic purchase limit quantity + invited new user purchase limit quantity. If not filled in, it means that the product is not restricted in quantity.',
    uploadImage: 'Upload image',
    thePictureOfSpecification: 'The picture of specification [',
    isInsufficientPleaseUploadItFirst:
      '] is insufficient, please upload it first.',
    editRechargeCard: 'Edit recharge card',
    rechargeCardDetails: 'Recharge Card Details',
    logisticsInformationNotConfigured: 'Logistics information not configured',
    youNeedToGoToThe: 'You need to go to the ',
    logisticsManagement: 'logistics management',
    settingsToSetUpRelevantInformation:
      ' settings to set up relevant information. Once the setup is complete, you can smoothly publish products.',
    unlimited: 'Unlimited',
    theTotalAvailableInventoryOfTheProductIsInsufficient:
      'The total available inventory of the product is insufficient, please purchase as soon as possible.',
    oneClickRelease:
      'One-click release: You can upload in batches, suitable for not modifying any information of the product (such as: product selling price, participating in activities, etc.), and directly publish after selection.',
    editAndPublish1:
      'Edit and publish: Edit and publish specified products, only one product can be published at a time.',
    productSpecifications: 'Product Specifications',
    settings: 'Settings',
    offSaleTime: 'Off sale time',
    scheduledDelisting: 'Scheduled delisting',
    scheduledDelistingTip:
      'When a product reaches the scheduled delisting time, the system will automatically remove the product from the shelves. The maximum time a user can reserve the product cannot exceed the scheduled delisting time.',
    pleaseChooseOffSaleTime: 'Please choose off sale time',
    offSaleTimeLessThanNow: 'The off sale time is less than the current time',
    onSaleTip:
      'This item has been removed from the shelf. Please add it back to the shelf before continuing.',
    onSaleResult: 'On sale results',
    onSaleResultTip:
      'X items were successfully put on the shelves, but Y items failed to be put on the shelves.',
  },
  shopManage: {
    storeConfiguration: 'Store configuration',
    shopInformation: 'Store information',
    addBusinessTime: 'Add Business Times',
    businessHours: 'Business hours',
    businessDate: 'Business date',
    businessTime: 'Business times',
    pleaseSelectAStartTime: 'Start time',
    pleaseSelectAnEndTime: 'End time',
    restDay: 'Rest day',
    allDayRest: 'All-day rest',
    selectTime: 'Select time',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    orderRefundPeriod: 'Order refund period',
    taxRate: 'Tax rate',
    accountManagement: 'Account management',
    accountStatus: 'Account status',
    linked: 'Linked',
    unlinked: 'Unlinked',
    linkNow: 'Link now',
    bindingInProgress: 'Binding in progress',
    enterTheVerificationPhoneNumber:
      'Enter the verification code sent to your phone number (+1 ',
    viaSMS: ') via SMS.',
    newCashier: 'New Counter',
    cashierName: 'Counter Name',
    bindingTime: 'Binding Time',
    notBound: 'Not bound',
    bound: 'Bound',
    bindPOSMachine: 'Bind Reader',
    unbind: 'Unbind',
    pOSDeviceCode: 'Reader Device Code',
    pleaseEnterTheDeviceCode:
      'Please enter the "Reader Device Code" into your Reader machine, and after successful binding, refresh the page.',
    ifYouWantToUseTheShopFunctionNormally:
      'If you want to use the store function normally, you must bind it.',
    verificationFailedBusinessDate: 'Please perfect the business times',
    pleaseSaveBusinessHour: 'Please save the business hours',
    businessDateTips1: 'If the business date is not checked,',
    businessDateTips2: 'it means that the corresponding day is closed.',
    preOrderSettings: 'Pre-order settings',
    restTimeSupportsReservation: 'Rest time supports reservation',
    restTimeDoesNotSupportReservation: 'Rest time does not support reservation',
    merchantsReceiveBookingDates: 'Merchants receive booking dates',
    reUpload: 'Re-upload',
    uploadYourBrandLogo: 'Upload your brand logo',
    thePictureIsUsedToDisplayThePosterOfYourStoreOnTheUserSide:
      "The picture is used to display the poster of your store on the user side. Upload pictures that are related to your store's products. A good store poster can encourage users to place orders",
    selfPickUp: 'Self pick-up',
    delivery: 'Delivery',
    selfPickupSettings: 'Self-pickup Settings',
    stockPreparationTime: 'Stock preparation time',
    hour: 'Hour',
    minute: 'Minute',
    thePreparationTimeForStockingAndTheTimeForPrintingReceiptsForSelfPickupOrdersAreRelated:
      'The preparation time for stocking and the time for printing receipts for self-pickup orders are related. For example, if the preparation time is set to 30 minutes and the user selects a pickup time of 10:00 AM, the receipt will be printed at 9:30 AM.',
    cannotBeGreaterThan60: 'Cannot be greater than 60',
    serviceArea: 'Service Area',
    setTheServiceRangeForYourStore:
      'Set the service range for your store, divide your business scope by postal code (for example, if you choose 20500, only users within the "20500" range on the app can browse your store).',
    addPostalCode: 'Add postal code',
    pleaseClick: 'Please click',
    state: 'State',
    city: 'City',
    deliverySettings: 'Delivery settings',
    setDifferentServiceAreaDeliveryDatesForYourStore:
      'Set different service area delivery dates for your store, and you can also click "daily delivery" to choose daily delivery.',
    deliveryDate: 'Delivery date',
    deliveryArea: 'Delivery area',
    selectPostalCode: 'Select postal code',
    postalCode: 'Postal code',
    deliverySettingsHaveBeenReset:
      'The delivery settings below have been reset, please reconfigure',
    dailyDelivery: 'Daily delivery',
    allServiceAreasAreDeliveredDaily:
      'All service areas are delivered daily. If you need to set different delivery times for different service areas, please turn off the daily delivery button in the upper right corner.',
    billingMethod: 'Billing method',
    usedToCalculateTheDeliveryCostOfGoods:
      'Used to calculate the delivery cost of goods.',
    freightPrice: 'Freight price',
    pleaseEnterTheShippingFeePrice: 'Please enter the shipping fee price',
    ifTheTotalPriceOfTheOrderIsNotWithinTheRange:
      'If the total price of the order is not within the range, then use the freight price for billing.',
    addConditions: 'Add conditions',
    deleteConditions: 'Delete conditions',
    byOrderTotalPrice: 'By order total price',
    reminderTheIntervalFollowsThe:
      'Reminder: The interval follows the "left-closed right-open" rule, for example: 0-50＄ represents 0 ≤ X ＜ 50＄.',
    exampleForATotalProductPriceOf:
      'Example: For a total product price of $0-50, the shipping fee is $25. This means that for an order with a total product price between $0 and $50, the required shipping fee is $25.',
    interval: 'Interval',
    endMustBeGreaterThanStart: 'End must be greater than start',
    chooseAtLeastOneDay: 'Choose at least one day',
    newPrinter: 'New Printer',
    printerName: 'Printer Name',
    offline: 'Offline',
    online: 'Online',
    printTest: 'Print Test',
    lengthShouldBe1To20: 'Length should be 1 to 20',
    bindingCashier: 'Binding Cashier',
    setAsMainTicketMachine: 'Set as main ticket machine',
    afterSuccessfulSetup:
      'After successful setup, the receipt printer will automatically print receipts for delivery, self-pickup orders, and offline cash register transactions. If not selected, it can still be used normally for offline cash register printing.',
    editPrinter: 'Edit Printer',
    printerDetails: 'Printer Details',
    requestSuccessfulPleaseObserveThePrinter:
      'Request successful, please observe the printer',
    orderPreparationTime: 'Order preparation time',
    upTo2ModificationsPerDay: 'Up to 2 modifications per day',
    setTime: 'Set time',
    fillingInstructions: 'Filling Instructions',
    setYourOrderPreparationTimeAccordingToTheActualOperationOfYourStorePleaseFillItInAccurately:
      '1. Set your order preparation time according to the actual operation of your store, please fill it in accurately.',
    fillingInAReasonableStockingPreparationTimeCanBringABetterExperienceToUsers:
      "2. Filling in a reasonable stocking preparation time can bring a better experience to users. If the time you fill in is too long or too short, it will affect the user's pickup time and thus affect the reputation of your store.",
    ifYouModifyTheLeadTimeForStockingPreparation:
      '3. If you modify the lead time for stocking preparation, it will only take effect on new orders, and historical orders will not be affected.',
    youCanDivideYourDeliveryServiceAreaBySettingThePostalCode:
      'You can divide your delivery service area by setting the postal code, so that only users within a specific range can browse your store. Users who choose "in-store" are not subject to service area restrictions.',
    orderDeliveryCutOffTimeForReceivingOrders:
      'Order delivery cut-off time for receiving orders',
    orderDeliveryCutOffTimeForReceivingOrdersXHoursBeforeTheEndOfTheBusinessDay:
      'Order delivery cut-off time for receiving orders: X hours before the end of the business day, assuming 2 hours are filled in. For example, if the business hours on that day are from 8:00 AM to 6:00 PM, then within 2 hours before the end of the business day, users cannot place same-day delivery orders on the app. If left blank, it means that the order cut-off time is equal to the end of business on that day.',
    beforeTheEndOfBusinessThatDay: 'Before the end of business that day',
    hours: 'Hours',
    setMainPrinter: 'Set main printer',
    afterTheSuccessfulSetup:
      'After the successful setup, the receipt printer will print picking lists and online receipts, while also having the function of offline cash register printing receipts.',
    selectAtLeastOneTicketMachine: 'Select at least one ticket machine',
    tip: 'Tip',
    userIfHaveCheckTip: 'User supported custom tips',
    orderStockingTimeTips:
      'Order stocking time cannot be zero, please set a valid time',
    cashPaymentRewards: 'Cash payment rewards',
    actualAmountPaid: 'Actual amount paid',
    cashPaymentRewardsTips:
      'Members will receive an additional Macou reward when paying with cash. Reward amount = order actual payment amount * X%',
  },
  orderManage: {
    offlineOrder: 'Offline order',
    onlineOrder: 'Online order',
    pickingTask: 'Picking task',
    orderTime: 'Order time',
    to: 'To',
    orderNumber: 'Order number',
    offlineCollection: 'Offline collection',
    productName: 'Product name',
    quantity: 'Quantity',
    qty: 'Qty',
    amountReceived: 'Amount received',
    amountReceived1: 'Amount received',
    methodOfPayment: 'Method of payment',
    payType: 'Pay Type',
    basicProductInformation: 'Basic Product Information',
    productID: 'Product ID',
    productPictures: 'Product Pictures',
    sKUInformation: 'SKU Information',
    unitPrice: 'Unit Price',
    totalPrice: 'Total Price',
    orderInformation: 'Order Information',
    taxAndFees: 'Tax and fees',
    taxAndFees1: 'Tax',
    confirmClearingThePurchaseList: 'Confirm clearing the purchase list?',
    amountDue: 'Amount due',
    subtotal: 'Subtotal',
    macouDeduction: 'macou deduction',
    macouUse: 'Macou Use',
    macouRebate: 'Macou Rebate',
    customerType: 'Customer type',
    actualPaymentAmount: 'Actual payment amount',
    total: 'Total',
    afterSalesInformation: 'After-sales information',
    afterSalesID: 'After-sales ID',
    hasTheProductBeenReturnedToTheWarehouse: 'Return to stock',
    buyerAccount: 'Buyer account',
    applicationAmount: 'Application amount',
    refundAmount: 'Refund amount',
    cashier: 'Counter',
    individualCustomer: 'Individual',
    member: 'Member',
    selectCustomerType: 'Select customer type',
    mobilePhoneNumber: 'Mobile phone number',
    product: 'Product',
    receivable: 'Receivable',
    paymentMethod: 'Payment method',
    cash: 'Cash',
    cardPayment: 'Card payment',
    onlinePayment: 'Online payment',
    paymentReceived: 'Payment received',
    initiatePayment: 'Initiate payment',
    specifiedPrice: 'Specified price',
    unit: 'Unit',
    amount: 'Amount',
    remaining: 'Remaining',
    classification: 'Classification',
    atLeastOneExists: 'At least one exists',
    collectingPayment: 'Collecting payment',
    pleaseSelectCashier: 'Please select Counter',
    pleaseSelectTheCorrespondingCashier:
      'Please select the corresponding cashier',
    pleaseSelectPaymentChannel: 'Please select payment channel',
    pleaseEnterReceiptAmount: 'Please enter Receipt Amount',
    pleaseScanTheGoods: 'Please scan the goods',
    pleaseScanTheProductBarcode:
      'Please scan the member code or product barcode',
    paymentFailed: 'Payment failed',
    orderCanceled: 'Order canceled',
    thereIsNoCashierYet: 'There is no cashier yet',
    goToAdd: 'Go to Add',
    completed: 'Completed',
    refunded: 'Refunded',
    refundFailed: 'Refund Failed',
    refund: 'Refund',
    printReceipt: 'Print receipt',
    agreeToRefundThisOrder: 'Agree to refund this order',
    whetherTheGoodsAreReturnedToTheWarehouse:
      'Whether the goods are returned to the warehouse',
    pleaseCheckWhetherTheGoodsAreReturnedToTheWarehouse:
      'Please check whether to return the product to the shelf',
    manualRefundToBuyer: 'Manual refund to buyer',
    thisOrderIsPaidInCashAndNeedsToBeReturnedToTheBuyerOffline:
      'This order is paid in cash and needs to be returned to the buyer offline',
    thisOrderIsPaidInOtherAndNeedsToBeReturnedToTheBuyerOffline:
      'This order is paid in other and needs to be returned to the buyer offline',
    thisOrderIsPaidInCombinationAndNeedsToBeReturnedToTheBuyerOffline:
      'This order is paid in combination and needs to be returned to the buyer offline',
    pleaseSelectACashier: 'Please select a Cashier',
    startPrinting: 'Start Printing',
    failedToPrintReceipt: 'Failed to print receipt',
    unableToConnectToTheTicketPrinterNormally:
      'Unable to connect to the ticket printer normally.',
    thePhoneNumberFormatIsIncorrect: 'The phone number format is incorrect',
    theMemberCodeFormatIsIncorrect: 'The member code format is incorrect',
    memberDoesNotExist: 'Member does not exist',
    receivablesDetails: 'Receivables Details',
    anAdditional05UsdWillBeChargedFor:
      'An additional 0.5 USD will be charged for online payments with a total amount less than 15 USD.',
    refundTips:
      "If the product does not affect secondary sales, select 'Yes' to return it to the shelf directly, otherwise select 'No'",
    precautions: 'Precautions',
    precautions1: 'The refunded amount includes the tax on the product.',
    precautions2:
      'Need to manually fill in the quantity of goods to be refunded, and the maximum number of goods purchased cannot exceed.',
    precautions3:
      'Tick ​​the Select All button, the default represents the refund of the entire order.',
    refundQuantity: 'Refund Quantity',
    validRefundtips: 'Exceeded the maximum number of product refunds',
    greaterThanZero: 'Refund quantity needs to be greater than 0',
    pleaseRefundSku: 'Please select refund item',
    refundTips1: 'Sorry, this order has expired on ',
    refundTips2: ' and cannot be refunded.',
    goodsQuantity: 'Number of Products',
    refundTime: 'Refund Time',
    macouDed: 'Macou use(refund user)',
    goodNo: 'Item No',
    refundInfo: 'Refund Information',
    selecting: 'selecting',
    servicesTotal: 'services, total',
    selectService: 'Select service',
    days: 'days',
    viewGuaranteeServices: 'View guarantee services',
    guaranteedServiceSynchronousRefund: 'Guaranteed service synchronous refund',
    guaranteeService: 'Guarantee service',
    selectTheGuaranteeServiceSynchronousRefund:
      'Select the guarantee service synchronous refund, which means that when the goods bound to the guarantee service are refunded, the guarantee service will also automatically refund.',
    nameSpecification: 'Name/Specification',
    guaranteeName: 'Guarantee name',
    priceValidityPeriod: 'Price/Validity period',
    refundableQuantity: 'Refundable quantity',
    expectedDueDate: 'Expected due date',
    theRefundQuantityCannotBeLessThan1:
      'The refund quantity cannot be less than 1',
    returnTheGoodsToTheShelf: 'Return the goods to the shelf',
    orderDetails: 'Order details',
    status: 'Status',
    pendingPayment: 'Pending payment',
    cancelled: 'Cancelled',
    orderType: 'Order type',
    buyerInformation: 'Buyer information',
    paymentInformation: 'Payment information',
    originalPriceOfTheProduct: 'Original price of the product',
    theTotalAmountOfOnlinePaymentIsLessThan15USDollars:
      'The total amount of online payment is less than 15 US dollars, an additional 0.5 US dollars will be charged',
    totalSubtotalTaxSurcharge: 'Total = Subtotal + Tax + Additional amount',
    totalSubtotalTaxSurcharge1:
      'Total = Subtotal + Tax + Additional amount + Tip',
    totalSubtotalTaxSurcharge2:
      'Total = Subtotal + Delivery fee + Tax + Additional amount + Tip',
    purchaseQuantity: 'Purchase quantity',
    refundedQuantity: 'Refunded quantity',
    remainingQuantity: 'Remaining quantity',
    operator: 'Operator',
    name: 'Name',
    nextStep: 'Next step',
    specification: 'Specification',
    productNumber: 'Item no',
    printRefundReceipt: 'Print refund receipt',
    scanProductBarcode: 'Scan product barcode',
    barcodeDoesNotExist: 'Barcode does not exist',
    theQuantityEligibleForRefundIsLessThan1:
      'The quantity eligible for refund is less than 1.',
    theMaximumRefundableQuantityHasBeenReached:
      'The maximum refundable quantity has been reached.',
    additionalAmount: 'Additional amount',
    nameAndItemNo: 'Name/Item no',
    selfPickUp: 'Self pick-up',
    delivery: 'Delivery',
    unchecked: 'Unchecked',
    checked: 'Checked',
    taskTime: 'Task Time',
    pickupCode: 'Pickup Code',
    numberOfPrints: 'Number Of Prints',
    numberOfPrintsTips:
      'Successfully printed a picking list, recorded as 1, and accumulated accordingly.',
    pickingOperator: 'Picking Operator',
    shippingVerification: 'Shipping Verification',
    printPickingList: 'Print Picking List',
    pickingTaskRules: 'Picking Task Rules',
    pickingTaskRules1:
      'When the order enters the stocking stage, the system will immediately generate corresponding picking tasks to ensure that the inventory can be stocked in an orderly manner and improve processing efficiency.',
    pickingTaskRules2:
      'Once the picking task is completed, the order will be smoothly transitioned to the next node, ensuring the continuity and efficient execution of the process.',
    orderShippingVerification: 'Order Shipping Verification',
    inStock: 'In stock',
    pickQuantity: 'Pick Quantity',
    verificationCompleted: 'Verification Completed',
    pickingQuantityGTPurchaseQuantity:
      'Picking quantity cannot be greater than purchase quantity, please re-enter',
    pleaseCheckRemainingProductQuantity: 'Check product quantity',
    shippingGtTips:
      'The picking quantity has met the order. Please verify the quantity of goods to ensure it is consistent with the order requirements.',
    scanWarning: 'Scan Warning',
    scanWarningTips:
      'The scanned product was not found in the order, please verify.',
    pleaseSelectTicketMachine: 'Please select ticket machine',
    thereIsNoTicketYet: 'No ticket machine available',
    deliveryPickupTime: 'Delivery/Pickup time',
    awaitingPickupOrDelivery: 'Awaiting pickup/Delivery',
    waitingForStock: 'Waiting for stock',
    deliveryTime: 'Delivery time',
    deliveryTime1: 'Delivery time',
    pickupTime: 'Pickup time',
    awaitingPickup: 'Awaiting pickup',
    delivering: 'Delivering',
    manualStocking: 'Manual stocking',
    confirmReceipt: 'Confirm receipt',
    confirmDelivery: 'Confirm delivery',
    stockUpTip1: 'The order is expected to start automatic stocking at ',
    stockUpTip2:
      '. You can manually stock the goods in advance according to your own situation. After confirming that the manual stocking is successful, the order will enter the picking process.',
    confirmReceiptTip:
      'Are you sure you want to confirm receipt of this order?',
    confirmReceiptTip1:
      'When confirming receipt, please ask the user to check the order-related information.',
    confirmDeliveryUploadImgTip:
      'Upload pictures of the goods upon arrival to facilitate user confirmation.',
    stockInsufficientTip1: 'The reasons for verification failure may include: ',
    stockInsufficientTip2:
      'The actual inventory on the shelves is insufficient to meet the order purchase quantity.',
    stockInsufficientTip3:
      'The product has been replenished to the shelf, but the system has not recorded the replenishment operation. The replenishment personnel need to be notified to perform the operation in the system.',
    outOfStockQuantity: 'Out of stock quantity',
    barcode: 'Barcode',
    productImage: 'Product image',
    image: 'Image',
    specifications: 'Specifications',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    selfPickupLocation: 'Self pickup location',
    receivingAddress: 'Receiving address',
    unpaid: 'Unpaid',
    tip: 'Tip',
    deliveryOrder: 'Delivery order',
    selfPickupOrders: 'Self pickup orders',
    statusTrack: 'Status track',
    transactionTime: 'Transaction time',
    stockingCompletionTime: 'Stocking completion time',
    startStockingTime: 'Start stocking time',
    paymentSuccessTime: 'Payment success time',
    deliveryPhotos: 'Delivery photos',
    startDeliveryTime: 'Start delivery time',
    orderCancellationTime: 'Order cancellation time',
    deliveryFee: 'Delivery fee',
    deliveryFeeTip:
      'Delivery fee = Basic delivery fee + Additional delivery fee',
    pickingCode: 'Picking code',
    remarks: 'Remarks',
    amountReceivedTip: 'Amount received = Total + Macou Use',
    taxRate: 'Tax rate',
    otherFunctions: 'Other functions',
    promotion: 'Promotion',
    modifyTheQuantityOfProducts: 'Modify the quantity of products',
    unknownProduct: 'Unknown product',
    pleaseEnterTheAmountOfTheProduct: 'Please enter the amount of the product',
    checkout: 'Checkout',
    otherPayments: 'Other payment',
    combinationPayment: 'Combination payment',
    quickInput: 'Quick',
    giveChange: 'Change',
    confirmPayment: 'Confirm payment',
    cancelPayment: 'Cancel payment',
    insufficientActualAmountReceived: 'Insufficient actual amount received',
    use: 'use ',
    paymentSuccessfully: 'Payment successfully',
    continueToCheckout: 'Continue to checkout',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    otherPaymentTips:
      'Other payments and receipts are only used for bookkeeping purposes. You need to confirm with the consumer that the payment has been made and the receipt has been made',
    receivables: 'Receivables',
    combinationPaymentMoreTips:
      'Combination payment only allows combinations of two payment methods',
    combinationPaymentOnePayTips1:
      'If there is only one payment method, Please choose ',
    combinationPaymentOnePayTips2: 'Cash payment method',
    combinationPaymentOnePayTips3: 'Other payment method',
    combinationPaymentZeroPayTips:
      'The actual amount received for each item in the combination payment shall not be zero.',
    paymenting: 'Receiving payment, please wait',
    cancelCardPayment:
      'To cancel the card payment, please operate the cancellation on the Reader device',
    other: 'Other',
    combination: 'Combination',
    card: 'Card',
    clickToExpandCollapseTheMenuBar: 'Click to expand/collapse the menu bar',
    refundMethod: 'Refund method',
    productInformation: 'Product Information',
  },
  walletManage: {
    walletManagement: 'Wallet management',
    cashManagement: 'Cash management',
    macouManagement: 'Macou management',
    amountSettled: 'Amount settled',
    totalSettlementAmount: 'Total settlement amount',
    shopOwesMoney: 'Store owes money',
    settlementTime: 'Settlement time',
    settlementAmount: 'Settlement amount',
    paymentTime: 'Payment time',
    paymentAmount: 'Payment amount',
    paymentMacou: 'Payment macou',
    thirdPartyFees: 'Third-party fees',
    platformCommission: 'Platform commission',
    actualAmountReceived: 'Actual amount received',
    actualAmountReceivedMacou: 'Actual received macou',
    methodOfPayment: 'Method of payment',
    accountBalance: 'Account balance',
    transferMoney: 'Transfer money',
    tradingTime: 'Trading time',
    type: 'Type',
    transfer: 'Transfer',
    rebate: 'Rebate',
    deduction: 'Deduction',
    refund: 'Refund',
    transactionAmount: 'Transaction amount',
    balance: 'Balance',
    remarks: 'Remarks',
    account: 'Account',
    pleaseEnterYourAccount: 'Please enter your account',
    pleaseEnterYourLoginPassword: 'Login password',
    cash: 'Cash',
    cardPayment: 'Card payment',
    transferAmount: 'Transfer amount',
    oppositeAccountNumber: 'Opposite Account Number',
    transferableAmount: 'Transferable amount',
    enterMacouAmount: 'Enter macou amount',
    nextStep: 'Next step',
    insufficientBalanceOfTheTransferableMacou:
      'Insufficient balance of transferable macou',
    macouCreditLimitManagement: 'Macou Credit Limit Management',
    evaluateYourCreditLimit: 'Evaluate your credit limit',
    theSystemWillEvaluateYourCreditLimit:
      'The system will evaluate your credit limit',
    evaluation: 'Evaluation',
    evaluatingInProgress: 'Evaluating in progress',
    availableCreditLimit: 'Available credit limit',
    borrowASum: 'Borrow',
    totalCreditLimit: 'Total credit limit',
    borrowedLimit: 'Borrowed limit',
    repayment: 'Repayment',
    theMassHoPlatformWillProvideYouWith60DaysOfInterestFreeBorrowingForEachLoan:
      'The MassHo platform will provide you with 60 days of interest-free borrowing for each loan.',
    evaluationRecord: 'Evaluation record',
    activeAssessment: 'Active assessment',
    increaseBy: 'increase by',
    reducedBy: 'reduced by',
    maintainUnchanged: 'maintain unchanged',
    generateIOUInformation: 'Generate IOU Information',
    borrowingMacou: 'Borrowing macou',
    borrowableAmount: 'Borrowable amount',
    interestFreePeriod: 'Interest-free period: ',
    interestWillBeCalculatedBeyondTheInterestFreePeriod:
      '; Interest will be calculated beyond the interest-free period.',
    arrivalAccount: 'Arrival account',
    pleaseEnterTheCorrectAmount:
      'Please enter the correct amount, Longest 8 digits, reference: 0.00',
    signatureOfCoSignatories: 'Signature of co-signatories',
    clickOnTheSignature: 'Click on the signature',
    iHaveReadAndAgreedToAllTheTermsOfTheLoanAgreement:
      'I have read and agreed to all the terms of the loan agreement, and I am willing to take on the risk of the loan',
    repaymentAmountForMacou: 'Repayment amount for macou',
    repaymentAmount: 'Repayment amount',
    loanAmount: 'Loan amount',
    interest: 'Interest',
    pleaseEnterTheLoginPassword: 'Please enter the login password',
    deductionAccount: 'Deduction account',
    loanPeriod: 'Loan Period',
    contract: 'Contract',
    viewContract: 'View contract',
    toBeRepaid: 'To be repaid',
    settled: 'Settled',
    interestFreeDaysRemaining: 'Interest-free days remaining',
    accruedInterestFor: 'Accrued interest for',
    insufficientBorrowingLimit: 'Insufficient borrowing limit',
    borrowAtLeast: 'Borrow at least 0.01',
    invitationReward: 'Invitation reward',
    creditLimitManagement: 'Credit limit management',
    cashPaymentRewards: 'Cash payment rewards',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    combinationPayment: 'Combination payment',
    time: 'Time',
    transactionType: 'Transaction Type',
    productRefunds: 'Product Refunds',
    productOrder: 'Product order',
    orderNumber: 'Order number',
    amount: 'Amount',
    remainingBalance: 'Remaining balance',
    shopOwesMoneyTips:
      'If a member user chooses to pay in cash, the system cannot charge commission from the order, so the commission for the order is recorded as debt owed by the store.',
  },
  stockManage: {
    productName: 'Product name',
    productID: 'Product ID',
    productPictures: 'Product Pictures',
    specification: 'Specification',
    totalInventory: 'Total inventory',
    inbound: 'Inbound',
    outbound: 'Outbound',
    currentInventory: 'Current inventory',
    storageQuantity: 'Storage quantity',
    quantityOfOutbound: 'Quantity outbound',
    remarks: 'Remarks',
    specificationName: 'Specification name',
    inventoryType: 'Inventory type',
    changeTime: 'Change time',
    changeQuantity: 'Change quantity',
    numberOfSKUsInStock: 'Number of SKUs in stock',
    rawMaterialInventory: 'Raw material inventory',
    nameOfRawMaterial: 'Name of raw material',
    rawMaterialID: 'Raw material ID',
    rawMaterialPicture: 'Raw material picture',
    unit: 'Unit',
    partialShortage: '(Partial shortage)',
    outOfStock: 'Out of stock',
    realStockNum: 'Real quantity',
    availableStockNum: 'Available quantity',
    inventory: 'Inventory',
    goodsShelf: 'GoodsShelf',
    inventoryTitle: 'Warehouse/Location inventory',
    inventoryCode: 'Inventory code',
    inventoryStockNum: 'Inventory quantity',
    inventoryAvailableStockNum: 'Inventory available quantity',
    type: 'Type',
    inventoryRecord: 'Inventory record',
    moveStorage: 'Move storage',
    warehouseLocationRecords: 'Warehouse Location Records',
    inventoryResidualQuantity: 'Residual quantity',
    operator: 'Operator',
    currentStorageLocation: 'Current location',
    targetStorageLocation: 'Target location',
    shelfStockTitle: 'Shelf stock',
    aisle: 'aisle',
    bay: 'bay',
    thisUnitShowsTheBaseUnit: 'This unit shows the base unit',
    shopRealTotalStockNumDesc:
      'Actual store inventory = shelf inventory + warehouse location inventory（auxiliary unit are autumatically converted to basic units）',
    shopAvailableStockNumDesc:
      'Available = Actual inventory - other occupied inventory（ready to ship inventory）',
    rawMaterialTracking: 'Raw Material Tracking',
    storageLocationCode: 'Storage location Code',
    shelfStockRecodsTitle: 'Shelf recods',
    name: 'Name',
    productNameSpecification: 'Product name/Specification',
    useBasicUnitsHere: 'Use basic units here',
    actualTotalShopStock: 'Actual total store stock',
    whenTheProductIsBelowTheLowerLimitOfWarning:
      'When the product is below the lower limit of warning, a warning purchase will be generated',
    theProcurementMeetingWillProvideRecommendedPurchaseQuantityBasedOnTheUpperLimit:
      'The procurement meeting will provide recommended purchase quantity based on the upper limit',
    warningProcurement: 'Warning procurement',
    warningProcurementSelection: 'Warning procurement selection',
    generatePurchaseOrder: 'Generate purchase order',
    totalShopStock: 'Total store inventory',
    availStock: 'Avail Inventory',
    stockUpperLimit: 'Stock upper limit',
    stockLowerLimit: 'Stock lower limit',
    purchasingUnit: 'Purchasing unit',
    suggestedPurchaseQuantity: 'Suggested purchase quantity',
    promptMessage: 'Prompt message',
    purchaseOrderNumber: 'Purchase Order Number',
    iUnderstand: 'I understand',
    actualStoreInventory:
      'Actual store inventory = shelf inventory + location inventory (automatically converts auxiliary units to base units).',
    availableActualInventoryMinusOtherOccupiedInventory:
      'Avail Inventory = actual inventory minus other occupied inventory, which means the inventory that can be shipped at any time.',
    warehouseArea: 'Warehouse area',
    shelf: 'Shelf',
    warehouseAreaWarehouseLocationStock:
      'Warehouse Area/Warehouse Location Stock',
    warehouseAreaCode: 'Warehouse area code',
    locationCode: 'Location code',
    warehouseStock: 'Warehouse stock',
    warehouseStockAvailability: 'Warehouse stock availability',
    transfer: 'Transfer',
    stockRecord: 'Stock record',
    warehouseStockRecords: 'Warehouse stock records',
    purchaseReceipt: 'Purchase receipt',
    shelfReturn: 'Shelf return',
    shelfArrival: 'Shelf arrival',
    shelfArrivalAutomatic: 'Shelf arrival (automatic)',
    stockBalance: 'Stock balance',
    currentLocation: 'Current location',
    targetLocation: 'Target location',
    pleaseSelectTheTargetWarehouseLocation:
      'Please select the target warehouse location',
    shelfStock: 'Shelf stock',
    shelfStock1: 'Shelf stock',
    shelfStockAvailability: 'Shelf stock availability',
    shelfStockRecords: 'Shelf stock records',
    shelfReplenishment: 'Shelf replenishment',
    automaticReplenishment: 'Automatic replenishment',
    goodsReturned: 'Goods returned',
    refundReturned: 'Refund returned',
    orderCancellationRefund: 'Order cancellation returned',
    salesOutboundOffline: 'Sales Outbound (Offline)',
    salesOutboundOnline: 'Sales Outbound (Online)',
    returnToWarehouse: 'Return to Warehouse',
    automaticOutbound: 'Automatic outbound',
    rawMaterialTraceability: 'Raw material traceability',
    returnOfRawMaterials: 'Return of raw materials',
    setStockAlert: 'Set stock alert',
    batchSetStockAlert: 'Batch set stock alert',
    lockQuantity: 'Lock quantity',
    inTransitQuantity:
      'Lock quantity: Order placed successfully, order status not yet completed.',
    quantityInTransit: 'Quantity in transit',
    theQuantityInTransit:
      'The quantity in transit = the quantity of purchase orders (not yet verified/not yet arrived/partially not yet arrived), and the packaging unit will be automatically converted to the basic unit.',
    damage: 'Damage',
    expired: 'Expired',
    initialQuantity: 'Initial quantity',
    lockedQuantityTheProductsInTheOrderHaveNotReachedThePointOfDeductingAvailableInventory:
      'Locked Quantity: The products in the order have not reached the point of deducting available inventory.',
    rechargeCardName: 'Recharge card name',
    recommendedPurchaseQuantityFormula:
      'Recommended purchase quantity formula: <br />1. Fixed: (Upper limit - Total available inventory in store - On the way quantity) / Packaging unit, rounded up.<br />2. AI: [(Past procurement cycle + Lead time + Order quantity not refunded within lead time) / (Number of business days in the past) * (Number of business days in future procurement cycle) - Total available inventory in store] / Packaging unit, rounded up.<br />3. Order: (Sum of quantities within lead time + order preparation time) / Packaging unit, rounded up.',
    fixed: 'Fixed',
    ai: 'AI',
    calculateTheSalesVolumeOfGoodsBasedOnThePastProcurementCycle:
      'Calculate the sales volume of goods based on the past procurement cycle + lead time + stocking time.',
    purchasingCycle: 'Purchasing cycle',
    purchaseTime: 'Purchase time',
    stockingTime: 'Stocking time',
    days: 'Days',
    actualInventoryOfTheStore:
      'Total store Inventory =  shelf inventory + location inventory (automatically convert packaging units to basic units).',
  },
  purchaseManage: {
    purchaseNumber: 'Purchase number',
    notReviewed: 'Not reviewed',
    notDelivered: 'Not delivered',
    partialArrived: 'Partial arrived',
    allArrived: 'All arrived',
    manuallyComplete: 'Manually complete',
    createPurchaseOrder: 'Create purchase order',
    totalPurchaseQuantity: 'Total purchase quantity',
    remark: 'Remark',
    createEmployee: 'Create employee',
    review: 'Review',
    finish: 'Finish',
    newPurchaseOrder: 'New purchase order',
    barcode: 'Barcode',
    productType: 'Product type',
    selectProducts: 'Select products',
    selectRawMaterials: 'Select raw materials',
    product: 'Product',
    rawMaterials: 'Raw materials',
    scanProductOrRawMaterialBarcode: 'Scan product or raw material barcode',
    alreadyExistsInTheList: 'Already exists in the list',
    productName: 'Product Name',
    rawMaterialName: 'Raw material name',
    productClassification: 'Product classification',
    classificationOfRawMaterials: 'Classification of raw materials',
    productNumber: 'Item no',
    completedPurchaseOrder: 'Completed purchase order',
    reminderPurchaseOrdersCompleted:
      'Reminder: Purchase orders completed: subsequent storage operations will not be possible, unreceived goods will be canceled, and received goods will be recorded as actual inventory.',
    quantityReceived: 'Quantity received',
    pleaseCarefullyCheckTheReceivedGoods:
      'Please carefully check the received goods. Once the verification is successful, the purchase order cannot be modified.',
    pleaseCarefullyCheckThePurchasedGoods:
      'Please carefully check the purchased goods. After successful rechecking, the purchase order cannot be modified.',
    ifTheRawMaterialHasAPackagingUnit:
      'If the raw material has a packaging unit, the displayed is the packaging unit.',
    type: 'Type',
    scanBarcode: 'Scan barcode',
    rechargeCard: 'Recharge card',
    selectRechargeCard: 'Select recharge card',
    name: 'Name',
    rechargeCardName: 'Recharge card name',
  },
  warehouseManage: {
    shopWarehouse: 'Store warehouse',
    warehouseArea: 'Warehouse area',
    location: 'Location',
    pleaseSelectAShopWarehouseOrAnyWarehouseArea:
      'Please select a store warehouse or any warehouse area',
    addNewWarehouseArea: 'Add new warehouse area',
    editWarehouseArea: 'Edit warehouse area',
    deleteWarehouseArea: 'Delete warehouse area',
    warehouseAreaCode: 'Warehouse area code',
    oneTo10Characters: '1 to 10 characters',
    pleaseSelectAnyWarehouseAreaOrAnyLocation:
      'Please select any warehouse area or any location',
    unableToDeleteTheWarehouseArea:
      'Unable to delete the warehouse area, please delete all warehouse locations under the warehouse area first',
    confirmDeletionOfSelectedWarehouseArea:
      'Confirm deletion of selected warehouse area',
    addNewWarehouseLocation: 'Add new warehouse location',
    editWarehouseLocation: 'Edit warehouse location',
    deleteWarehouseLocation: 'Delete warehouse location',
    locationCode: 'Location code',
    confirmDeletionOfSelectedWarehouseLocation:
      'Confirm deletion of selected warehouse location',
    product: 'Product',
    rawMaterials: 'Raw materials',
    pleaseSelectTheWarehouseLocationFirst:
      'Please select the warehouse location first',
    productId: 'Product ID',
    rawMaterialId: 'Raw material ID',
    productImage: 'Product image',
    image: 'Image',
    productName: 'Product Name',
    rawMaterialName: 'Raw material name',
    specifications: 'Specifications',
    unit: 'Unit',
    stockInTheWarehouseArea: 'Warehouse Stock',
    warehouseLocation: 'Warehouse location',
    type: 'Type',
    inboundNumber: 'Inbound number',
    purchaseNumber: 'Purchase Number',
    purchaseReceipt: 'Purchase receipt',
    shelfReturn: 'Shelf return',
    time: 'Time',
    today: 'Today',
    nearly7Days: 'Nearly 7 days',
    nearly30Days: 'Nearly 30 days',
    nearly90Days: 'Nearly 90 days',
    threeMonthsAgo: 'Three months ago',
    totalReceipt: 'Total Receipt',
    createEmployee: 'Create employee',
    purchaseOrderInformation: 'Purchase order information',
    selectPurchaseOrder: 'Select purchase order',
    noContent: 'No content',
    purchaseTime: 'Purchase time',
    purchaseCreator: 'Purchase creator',
    searchPurchaseOrderNumber: 'Search purchase order number',
    pleaseEnterThePurchaseOrderNumber: 'Please enter the purchase order number',
    notDelivered: 'Not delivered',
    partialDelivery: 'Partial delivery',
    totalPurchaseQuantity: 'Total purchase quantity',
    receiptCount: 'Receipt count',
    name: 'Name',
    ifTheProductHasEnabledPackagingUnits:
      'If the product has enabled packaging units, then what is displayed is the packaging unit.',
    purchaseQuantity: 'Purchase quantity',
    quantityToBereceived: 'Quantity to be received',
    actualQuantityReceived: 'Actual quantity received',
    barcode: 'Barcode',
    productNumber: 'Item no',
    isRawMaterials: 'Is raw materials',
    warehouseAreaLocation: 'Warehouse area/location',
    shelfArrival: 'Shelf arrival',
    shelfArrivalAuto: 'Shelf arrival(Auto)',
    outboundNumber: 'Outbound number',
    outboundDate: 'Outbound date',
    sourceWarehouseAreaLocation: 'Source warehouse area/location',
    targetShelf: 'Target shelf',
    inboundDate: 'Inbound date',
    inboundCreator: 'Inbound creator',
    purchaseDate: 'Purchase date',
    inboundQuantity: 'Inbound quantity',
    sourceShelf: 'Source shelf',
    inboundProductDetails: 'inbound Product Details',
    quantityOfOutbound: 'Quantity outbound',
    rechargeCard: 'Recharge card',
    createOutboundOrder: 'Create outbound order',
    damage: 'Damage',
    expired: 'Expired',
    outboundType: 'Outbound type',
    newOutboundOrder: 'New outbound order',
    batchTransfer: 'Batch Transfer',
    pleaseSelectTheProduct: 'Please select the product',
    selectedProduct: 'Selected product',
    targetLocation: 'Target location',
    item: 'item',
    batchTransferSuccessfulTips:
      'Batch transfer successful, move the goods to the new correct storage location',
    pleaseSelectTheTargetWarehouseLocation:
      'Please select the target warehouse location',
    batchSettingsAllArrival: 'Batch settings all arrival',
    batchSettingWarehouseAreaLocation: 'Batch setting warehouse area/location',
    batchSettingsAllArrivalTip:
      'Are you sure you want to set all checked items as fully arrived?',
    batchSettingsAllArrivalTip1:
      'After successful setting, the actual arrival quantity = the quantity to be received',
  },
  shelfManage: {
    newArea: 'New Area',
    newShelf: 'New Shelf Number',
    editShelf: 'Edit Shelf Number',
    nameOfArea: 'Name of area',
    shelfNumberName: 'Shelf number name',
    creationTime: 'Creation Time',
    lengthShouldBeBetween1And25: 'Length should be between 1 and 25',
    relationGood: 'Related products',
    areaDetail: 'Area details',
    goodNo: 'Item No',
    areaLocation: 'Area location',
    shelfLocation: 'Shelf location',
    shelfLocal: 'Shelf location',
    shelfTotalArea: 'Shelf total area',
    away: 'Aisle',
    bay: 'Bay',
    addAisle: 'Add aisle',
    addBay: 'Add bay',
    pleaseSelectTheOverallAreaOfTheShelfOrAnyAisle:
      'Please select the overall area of the shelf or any aisle',
    oneTo10Characters: '1 to 10 characters',
    pleaseChooseAnyAisleOrAnyBay: 'Please choose any aisle or any bay',
    editAisle: 'Edit aisle',
    editBay: 'Edit bay',
    unableToDeleteTheAisle:
      'Unable to delete the aisle, please delete all bays under the aisle first',
    aisleNumber: 'Aisle number',
    deleteAisle: 'Delete aisle',
    confirmToDeleteTheSelectedAisle: 'Confirm to delete the selected aisle',
    bayNumber: 'Bay number',
    deleteBay: 'Delete bay',
    confirmDeletingSelectedBay: 'Confirm deleting selected bay',
    pleaseSelectTheBayFirst: 'Please select the bay first',
    productNumber: 'Item no',
    image: 'Image',
    productName: 'Product Name',
    specifications: 'Specifications',
    price: 'Price',
    unit: 'Unit',
    shelfStock: 'Shelf stock',
    productType: 'Product type',
    product: 'Product',
    rawMaterialProduct: 'Raw material commodities',
    putOnTheShelf: 'Put on the shelf',
    placeTheGoodsInAnOrderlyMannerOnTheShelvesForCustomersToChoose:
      'Place the goods in an orderly manner on the shelves for customers to choose.',
    numberOfShelves: 'Number of shelf',
    warehouseStockAvailable: 'Warehouse stock available',
    more: 'More',
    shopStock: 'Store stock',
    shelfReplenishment: 'Shelf replenishment',
    returnToWarehouse: 'Return to warehouse',
    returnQuantity: 'Return quantity',
    targetWarehouseArea: 'Target warehouse area',
    productId: 'Product ID',
    warningCondition: 'Warning condition',
    aboveWarningLimit: 'Above warning limit',
    belowWarningLimit: 'Below warning limit',
    theUnitDisplayedHereIsTheBaseUnit:
      'The unit displayed here is the base unit',
    lowerLimit: 'Lower limit',
    upperLimit: 'Upper limit',
    whetherAlertIsSetOrNot: 'Whether alert is set or not',
    productNameSpecification: 'Product name/Specification',
    setShelfAlert: 'Set shelf alert',
    batchSet: 'Batch set',
    batchSetShelfAlert: 'Batch set shelf alert',
    batchAlertValueSetting: 'Batch alert value setting',
    rawMaterialProductsAreAffectedByRawMaterialsAndAreNotDisplayedOnTheShelvesAsAWarning:
      'Raw material products are affected by raw materials and are not displayed on the shelves as a warning.',
    warehouseStock: 'Warehouse stock',
    storeStock: 'Store stock',
    enableTheInitialQuantityOnTheShelf:
      'Enable the initial quantity on the shelf',
    whenYouEnableTheInitialQuantityOfShelves:
      'When you enable the initial quantity of shelves, it applies to those products that are already placed on the shelves. After successful activation, the system will increase the additional inventory quantity.',
    initialQuantity: 'Initial quantity',
    thisUnitDisplaysThePackagingUnit: 'This unit displays the packaging unit',
    name: 'Name',
    parValue: 'Par value',
    rechargeCard: 'Recharge card',
    placeTheRechargeCardsNeatlyOnTheShelvesForCustomersToChooseFrom:
      'Place the recharge cards neatly on the shelves for customers to choose from.',
    refuelingCard: 'Refueling card',
    universalRechargeCard: 'Universal recharge card',
    type: 'Type',
    rechargeCardOnShelves: 'Recharge card on shelves',
    editRechargeCard: 'Edit recharge card',
    rechargeCardDetails: 'Recharge Card Details',
    theShelfPositionCannotBeEmpty: 'The shelf position cannot be empty.',
    theShelfQuantityCannotBeEmpty: 'The shelf quantity cannot be empty.',
    returnToWarehouseCannotBeEmpty: 'Return to warehouse cannot be empty.',
    theProductWasNotFoundInTheWarehouse:
      'The product was not found in the warehouse. Please add the product to the warehouse first, and then return it to the warehouse.',
    theProductWasNotFoundInTheWarehouse1:
      'The product was not found in the warehouse. Please add the product to the warehouse first, and then replenish it.',
    batchTransfer: 'Batch Transfer',
    pleaseSelectTheProduct: 'Please select the product',
    selectedProduct: 'Selected product',
    targetLocation: 'Target shelf',
    item: 'item',
    batchTransferSuccessfulTips:
      'Batch transfer successful, move the goods to the new correct storage shelf',
    pleaseSelectTheTargetWarehouseLocation: 'Please select the target shelf',
    remove: 'Remove',
    removeTip: 'Remove Tip',
    removeTip1:
      'Some specifications of the product are not selected. After the operation is successful, the system will automatically remove all specifications of this product.',
    removeSuccess: 'Remove success',
    removeNotAllowTip:
      'This product has been put on the online store and cannot be removed.',
    batchPutOnTheShelf: 'Batch put on the shelf',
    batchPutOnTheShelfTip:
      'Applicable to products that are placed on the same shelf. Fill in the quantity to place the products on the shelves in batches.',
    editPutOnTheShelf: 'Edit put on the shelf',
    editPutOnTheShelfTip:
      'Specify the product to be edited and put on the shelves. Only one product can be put on the shelves at a time.',
    putOnTheShelfMethod: 'Put on shelves method',
    onSale: 'On Sale',
    numberOfShelvesIncorrect: 'Number of shelf incorrect',
    removeTip2: 'Recharge cards are in shelf stock and removal is not allowed.',
    removeTip3:
      'Some specifications of the product have shelf stock and removal is not allowed.',
  },
  guaranteeManage: {
    guaranteeType: 'Guarantee type',
    establishAGoodTypeOfGuaranteeSoThatYouCanEasilyViewVariousGuaranteeServicesAndFacilitateManagement:
      'Establish a good type of guarantee so that you can easily view various guarantee services and facilitate management.',
    associatedGuaranteeServices: 'Associated guarantee services',
    addGuaranteeType: 'Add Guarantee Type',
    guaranteeTypeName: 'Guarantee type name',
    pleaseEnterTheTypeOfGuarantee: 'Please enter the type of guarantee',
    oneTo50Characters: '1 to 50 characters',
    editGuaranteeType: 'Edit Guarantee Type',
    guaranteeName: 'Guarantee name',
    ifYourProductSupportsGuaranteeServices:
      'If your product supports guarantee services, please fill in the relevant information about the guarantee services.',
    pleaseEnterTheName: 'Please enter the name',
    price: 'Price',
    validityPeriodDays: 'Validity Period(Days)',
    serviceIntroduction: 'Service Introduction',
    describeTheServiceToLetUsersKnowWhatGuaranteesAreIncludedInThisService:
      'Describe the service to let users know what guarantees are included in this service',
    oneTo5Characters: '1 to 5 characters',
  },
  settingsManage: {
    automaticOutboundNotification: 'Automatic outbound notification',
    whenTheInventoryOnTheShelvesIsSoldOut:
      'When the inventory on the shelves is sold out, if a customer comes to check out with the product, the system will allow the checkout process to be completed. During this process, the system will automatically deduct the corresponding product from the inventory and immediately notify the designated staff below to verify the inventory situation and ensure accurate information.',
  },
  notifyManage: {
    automaticOutboundNotification: 'Automatic outbound notification',
    verified: 'Verified',
    notVerified: 'Not verified',
    quantityOfOutbound: 'Quantity of outbound',
    notice: 'Notice',
    subject: 'Subject',
    automaticUpdateNotificationForProductOutbound:
      'Automatic update notification for product outbound',
    dearAdministrator:
      'Dear administrator, I hope you are well. Our automated system has detected a recent inventory event that requires your attention.',
    eventDetails: 'Event details',
    triggerShelfStockDepleted: 'Trigger: Shelf inventory depleted.',
    requiredOperation: 'Required operation',
    verifyInventoryPleaseCarefullyCheckTheCurrentInventoryToEnsureAccuracy:
      'Verify inventory: Please carefully check the current inventory to ensure accuracy. Cross-check system records with actual inventory.',
    adjustmentsWhenNecessaryIfAnyDiscrepanciesAreFound:
      'Adjustments when necessary: If any discrepancies are found, please make the necessary adjustments in the system immediately to reflect the correct inventory levels.',
    confirmAndIgnoreIfTheInventoryHasBeenConfirmedAndNoDiscrepanciesAreFound:
      'Confirm and Ignore: If the inventory has been confirmed and no discrepancies are found, please ignore this notification. Your confirmation ensures that our records are up to date.',
    thankYouVeryMuchForYourPromptAttentionToThisMatter:
      'Thank you very much for your prompt attention to this matter.',
    thankYouForYourDedicationToMaintainingAccurateInventoryRecords:
      'Thank you for your dedication to maintaining accurate inventory records.',
  },
  cashierManagement: {
    pleaseSelectCashier: 'Please select Cashier',
    unbindWarning: 'Unbind warning',
    pleaseEnsureCashierNotUse: 'Please ensure that the cashier is not in use',
    afterSuccessfulUnbindingCashierWillNotAbleFunctionProperly:
      'After successful unbinding, the cashier will not be able to function properly',
  },
  data: {
    time: 'Time',
    today: 'Today',
    yesterday: 'Yesterday',
    nearly7Days: 'Nearly 7 days',
    nearly30Days: 'Nearly 30 days',
    receiptStructure: 'Receipt structure',
    type: 'Type',
    turnover: 'Turnover',
    turnoverTip:
      'Turnover = Actual income and expenditure + Macou income and expenditure, orders include online orders and offline orders (excluding refund orders)',
    actualIncomeAndExpenditure: 'Actual income and expenditure',
    actualIncomeAndExpenditureTip:
      'Actual income and expenditure: Order statistics (subtotal, taxes, tips, additional charges, delivery fees) - third-party handling fees - platform commission ($) - order amount minus refund amount (actual amount received by the merchant + a certain percentage of handling fees)',
    macouIncomeAndExpenditure: 'Macou income and expenditure',
    macouIncomeAndExpenditureTip:
      'Macou income and expenditure: Order deduction Macou part - Platform commission (Macou) - Merchant rebate Macou - Order refund Macou (Macou actually received by the merchant)',
    comprehensiveStatistics: 'Comprehensive Statistics',
    refundStatistics: 'Refund Statistics',
    refundStatisticsTip1:
      'Refund statistics: statistics based on the refund time agreed by the merchant.',
    refundStatisticsTip2:
      '1. Number of refunded orders: the number of refunded orders. If an order is refunded twice, it is recorded as 2.',
    refundStatisticsTip3:
      '2. Refund amount: the amount refunded to the user (the actual amount received by the merchant + a certain percentage of handling fee).',
    refundStatisticsTip4:
      '3. Refund Macou: the amount of Macou that needs to be refunded to the user (the actual amount received by the merchant).',
    refundStatisticsTip5:
      '4. Macou rebate: the amount of Macou returned by the user to the merchant.',
    numberOfRefundedOrders: 'Number of refunded orders',
    orderRefundAmount: 'Order refund amount',
    orderRefundAmountTip:
      'Count the refund amount based on the time when the merchant agrees to refund',
    refundMacou: 'Refund Macou',
    macouRebate: 'Macou Rebate',
    details: 'Details',
    monthlyReport: 'Monthly Report',
    customTime: 'Custom time',
    date: 'Date',
    tip: 'Tip',
    taxes: 'Taxes',
    thirdPartyFees: 'Third-party fees',
    platformCommission: 'Platform commission',
    platformCommissionTip1:
      'Only the actual USD portion is recorded. Platform Commission ($) = Sales Order Commission',
    platformCommissionTip2:
      'Platform Commission (Macou) = Sales Order Commission',
    cash: 'Cash',
    cardPayment: 'Card payment',
    venmo: 'Venmo',
    zelle: 'Zelle',
    payPal: 'PayPal',
    cashAPP: 'CashAPP',
    orderPaymentAmount: 'Order payment amount',
    orderPaymentAmountTip: 'Total orders in the period',
    total: 'Total',
    orderType: 'Order type',
    offlineOrder: 'Offline order',
    onlineOrder: 'Online order',
    payType: 'Pay Type',
    orderNumber: 'Order number',
    paymentTime: 'Payment Time',
    orderPaymentMacou: 'Order payment Macou',
    orderTotal: 'Order Total',
    actualAmountReceived: 'Actual amount received',
    actualAmountReceivedTip:
      'Actual amount received = order total - third-party handling fee - platform commission ($)',
    actualReceiptMacou: 'Actual receipt Macou',
    operator: 'Operator',
    transactionType: 'Transaction Type',
    productRefunds: 'Product Refunds',
    productOrder: 'Product order',
    totalData: 'Total data',
    totalReceipts: 'Total Receipts',
    querying: 'Querying',
    tradingTime: 'Trading time',
    incomeAndExpenditureType: 'Income and Expenditure Type',
    expenditure: 'Expenditure',
    income: 'Income',
    orderTime: 'Order time',
    refundTime: 'Refund Time',
    refundAmount: 'Refund amount',
    refundMacouTip:
      'After the user successfully refunds, Macou needs to be returned',
    refundMethod: 'Refund method',
    macouRebateTip:
      'After the user successfully refunds, the Macou rebate needs to be returned to the merchant',
    totalTip: 'The total sum of each field within the selected period',
  },
}
