import {
  getCollectionComposition,
  getRefundStatistics,
  getSummaryTable,
  getSummaryTableCount,
  getOrderDeductionDetails,
  getChannelDetails,
  getChannelDetailsCount,
  getTipDetails,
  getTipDetailsCount,
  getRefundDetails,
  getTaxDetails,
  getTaxDetailsCount,
} from '@/api/shop/data'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getCollectionComposition(context, params) {
      return await getCollectionComposition(params)
    },
    async getRefundStatistics(context, params) {
      return await getRefundStatistics(params)
    },
    async getSummaryTable(context, params) {
      return await getSummaryTable(params)
    },
    async getSummaryTableCount(context, params) {
      return await getSummaryTableCount(params)
    },
    async getOrderDeductionDetails(context, params) {
      return await getOrderDeductionDetails(params)
    },
    async getChannelDetails(context, params) {
      return await getChannelDetails(params)
    },
    async getChannelDetailsCount(context, params) {
      return await getChannelDetailsCount(params)
    },
    async getTipDetails(context, params) {
      return await getTipDetails(params)
    },
    async getTipDetailsCount(context, params) {
      return await getTipDetailsCount(params)
    },
    async getRefundDetails(context, params) {
      return await getRefundDetails(params)
    },
    async getTaxDetails(context, params) {
      return await getTaxDetails(params)
    },
    async getTaxDetailsCount(context, params) {
      return await getTaxDetailsCount(params)
    },
  },
}
