import {
  getOnlineGoodsList,
  getGoodsListByOnline,
  onlineGoodsByOffline,
  batchUp,
  batchDown,
  getOnlineGoodsDesById,
  getPlatformClassifyList,
  uploadOnlineGoods,
  downOnlineGoods,
  getRebutReason,
  deleteOnlineGoods,
  getSkuListById,
  onlineGoodsReplenish,
  getPlatformCategoryListDes,
  addPlatformCategory,
  getPlatformCategoryDesById,
  updatePlatformCategory,
  deletePlatformCategoryById,
  getUnpublishedGoods,
  publishGoods,
  getGoodsOlDetails,
  getUnPublishGoodsDetails,
  accurateReleaseGoods,
  editOnLineGoods,
  bulkOperationGoodsOl,
  deleteGoodsOl,
  getSkuSalesList,
  addSalesLimit,
  getDeliveryConfigured,
  getOnlineSkuInfo,
  getUnpublishedRechargeCardList,
  publishRechargeCard,
  getOfflineRechargeCardDetails,
  getRechargeCardDetails,
  editPublishRechargeCard,
  editOnLineRechargeCard,
  ifOnShelf,
} from '@/api/shop/onlineGoods'

export default {
  namespaced: true,
  state: () => ({
    onlineGoodsList: {
      total: 0,
      list: [],
    },
  }),
  mutations: {
    setOnlineGoodsList(state, onlineGoodsList) {
      state.onlineGoodsList = onlineGoodsList
    },
  },
  actions: {
    // 获取在线商品列表
    async getOnlineGoodsList(context, params) {
      return await getOnlineGoodsList(params)
    },
    async getGoodsListByOnline(context, params) {
      return await getGoodsListByOnline(params)
    },
    async onlineGoodsByOffline(context, data) {
      return await onlineGoodsByOffline(data)
    },
    async batchUp(context, data) {
      return await batchUp(data)
    },
    async batchDown(context, data) {
      return await batchDown(data)
    },
    async getOnlineGoodsDesById(context, id) {
      return await getOnlineGoodsDesById(id)
    },
    async getPlatformClassifyList(context) {
      return await getPlatformClassifyList()
    },
    async uploadOnlineGoods(context, data) {
      return await uploadOnlineGoods(data)
    },
    async downOnlineGoods(context, id) {
      return await downOnlineGoods(id)
    },
    async getRebutReason(context, id) {
      return await getRebutReason(id)
    },
    async deleteOnlineGoods(context, id) {
      return await deleteOnlineGoods(id)
    },
    async getSkuListById(context, id) {
      return await getSkuListById(id)
    },
    async onlineGoodsReplenish(context, id) {
      return await onlineGoodsReplenish(id)
    },
    async getPlatformCategoryListDes(context, params) {
      return await getPlatformCategoryListDes(params)
    },
    async addPlatformCategory(context, data) {
      return await addPlatformCategory(data)
    },
    async getPlatformCategoryDesById(context, id) {
      return await getPlatformCategoryDesById(id)
    },
    async updatePlatformCategory(context, data) {
      return await updatePlatformCategory(data)
    },
    async deletePlatformCategoryById(context, id) {
      return await deletePlatformCategoryById(id)
    },
    async getUnpublishedGoods(context, params) {
      return await getUnpublishedGoods(params)
    },
    async publishGoods(context, data) {
      return await publishGoods(data)
    },
    async getGoodsOlDetails(context, id) {
      return await getGoodsOlDetails(id)
    },
    async getUnPublishGoodsDetails(context, id) {
      return await getUnPublishGoodsDetails(id)
    },
    async accurateReleaseGoods(context, data) {
      return await accurateReleaseGoods(data)
    },
    async editOnLineGoods(context, data) {
      return await editOnLineGoods(data)
    },
    async bulkOperationGoodsOl(context, data) {
      return await bulkOperationGoodsOl(data)
    },
    async deleteGoodsOl(context, id) {
      return await deleteGoodsOl(id)
    },
    async getSkuSalesList(context, id) {
      return await getSkuSalesList(id)
    },
    async addSalesLimit(context, data) {
      return await addSalesLimit(data)
    },
    async getDeliveryConfigured(context) {
      return await getDeliveryConfigured()
    },
    async getOnlineSkuInfo(context, params) {
      return await getOnlineSkuInfo(params)
    },
    async getUnpublishedRechargeCardList(context, params) {
      return await getUnpublishedRechargeCardList(params)
    },
    async publishRechargeCard(context, data) {
      return await publishRechargeCard(data)
    },
    async getOfflineRechargeCardDetails(context, id) {
      return await getOfflineRechargeCardDetails(id)
    },
    async getRechargeCardDetails(context, id) {
      return await getRechargeCardDetails(id)
    },
    async editPublishRechargeCard(context, data) {
      return await editPublishRechargeCard(data)
    },
    async editOnLineRechargeCard(context, data) {
      return await editOnLineRechargeCard(data)
    },
    async ifOnShelf(context, id) {
      return await ifOnShelf(id)
    },
  },
}
