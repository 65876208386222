import request from '@/utils/request'

/**
 * 获取店铺列表
 * @returns 店铺列表
 */
export const getShopList = (params) => {
  return request({
    url: '/org/getShopList',
    method: 'GET',
    params,
  })
}

/**
 * 获取状态为营业中的店铺
 */
export const getShopStatusInBusiness = () => {
  return request({
    url: '/org/getShopStatusInBusiness',
    method: 'GET',
  })
}

/**
 * 获取用户列表
 * @returns 用户列表
 */
export const getUserList = (params) => {
  return request({
    url: '/org/getUserList',
    method: 'GET',
    params,
  })
}

/**
 * 获取用户页面使用的角色
 */
export const getUserRoleList = () => {
  return request({
    url: '/org/getUserRoleList',
    method: 'GET',
  })
}

/**
 * 获取用户详情
 */
export const getUserDes = (id) => {
  return request({
    url: `/org/getUserDes/${id}`,
    method: 'GET',
  })
}

/**
 * 新建用户
 */
export const addUser = (data) => {
  return request({
    url: '/org/addUser',
    method: 'POST',
    data,
  })
}

/**
 * 更新用户
 */
export const updateUser = (data) => {
  return request({
    url: '/org/updateUser',
    method: 'POST',
    data,
  })
}

/**
 * 删除用户
 */
export const deleteUser = (id) => {
  return request({
    url: `/org/deleteUser/${id}`,
    method: 'DELETE',
  })
}

/**
 * 用户状态
 */
export const userStatus = (data) => {
  return request({
    url: '/org/userStatus',
    method: 'POST',
    data,
  })
}

/**
 * 获取角色列表
 * @returns 角色列表
 */
export const getRoleList = (params) => {
  return request({
    url: '/org/getRoleList',
    method: 'GET',
    params,
  })
}

/**
 * 新建角色
 */
export const addRole = (data) => {
  return request({
    url: '/org/addRole',
    method: 'POST',
    data,
  })
}

/**
 * 获取角色资源
 */
export const getRoleResList = () => {
  return request({
    url: '/org/getRoleResList',
    method: 'GET',
  })
}

/**
 * 获取角色详情
 */
export const getRoleDes = (id) => {
  return request({
    url: `/org/getRoleDes/${id}`,
    method: 'GET',
  })
}

/**
 * 更新角色
 */
export const updateRole = (data) => {
  return request({
    url: '/org/updateRole',
    method: 'POST',
    data,
  })
}

/**
 * 获取角色下对应用户列表
 */
export const getRoleAccountList = (id) => {
  return request({
    url: `/org/getRoleAccountList/${id}`,
    method: 'GET',
  })
}

/**
 * 获取店铺角色列表
 * @returns 店铺角色列表
 */
export const getShopRoleList = (params) => {
  return request({
    url: '/org/getShopRoleList',
    method: 'GET',
    params,
  })
}

/**
 * 获取店铺角色资源列表
 */
export const getShopRoleResList = () => {
  return request({
    url: '/org/getShopRoleResList',
    method: 'GET',
  })
}

/**
 * 新建店铺角色
 */
export const addShopRole = (data) => {
  return request({
    url: '/org/addShopRole',
    method: 'POST',
    data,
  })
}

/**
 * 获取店铺角色详情列表
 * @returns 店铺角色详情列表
 */
export const getShopRoleDesList = (params) => {
  return request({
    url: '/org/getShopRoleDesList',
    method: 'GET',
    params,
  })
}

/**
 * 获取店铺角色资源
 */
export const getShopRoleRes = (id) => {
  return request({
    url: `/org/getShopRoleRes/${id}`,
    method: 'GET',
  })
}

/**
 * 获取店铺角色详情
 */
export const getShopRoleDes = (id) => {
  return request({
    url: `/org/getShopRoleDes/${id}`,
    method: 'GET',
  })
}

/**
 * 更新店铺角色
 */
export const updateShopRole = (data) => {
  return request({
    url: '/org/updateShopRole',
    method: 'POST',
    data,
  })
}

/**
 * 删除店铺角色
 */
export const deleteShopRole = (id) => {
  return request({
    url: `/org/deleteShopRole/${id}`,
    method: 'DELETE',
  })
}

/**
 * 自动补全地址
 */
export const automaticCompletionOfAddress = (params) => {
  return request({
    url: '/map/automaticCompletionOfAddress',
    method: 'GET',
    params,
  })
}

/**
 * 查询地址详情
 */
export const queryAddressDetails = (params) => {
  return request({
    url: '/map/queryAddressDetails',
    method: 'GET',
    params,
  })
}

/**
 * 根据域名获取店铺信息
 * @returns 店铺信息
 */
export const getShopInfoByDomain = (domain) => {
  return request({
    url: `/org/getShopInfoByDomain/${domain}`,
    method: 'GET',
  })
}
