import { SHOP_ID, TOKEN } from '@/constant'
import store from '@/store'
import { getCookie } from '@/utils/cookies'
import { loadStoreNoLoading } from '@/utils/store'
import router, { orgDefaultPrefix, shopDefaultPrefix } from '..'

const baseWhiteList = [
  '/tmp',
  '/privacyPolicy',
  '/userProtocol',
  '/technicalSupport',
]

// eslint-disable-next-line no-unused-vars
export const whiteList = ['/login', '/register', ...baseWhiteList]

/**
 * 路由前置守卫
 */
export const auth = async (to, from, next) => {
  await routeIntercept(to, from, next)
}

const routeIntercept = async (to, from, next) => {
  // 设置当前所在结构页面位置
  currentLocation(to)

  // 基础白名单
  if (baseWhiteList.indexOf(to.path) > -1) {
    next()
    return
  }

  // 1. 用户已登录，不允许进入 login
  // 2. 用户未登录，只允许进入 指定页面 login
  if (getCookie(TOKEN)) {
    // #region 用户登陆之后可以做的一些事情
    // 请求时区信息
    await loadTimeZone()
    // #endregion

    // #region 纠正假 404 的路径
    if (to.fullPath === '/404' && to.redirectedFrom) {
      // 假 404 的情况：
      // 1. 当前在店铺页面，因为店铺页面肯定时组织页面进来的，按理说组织页面的路由肯定有了，但是如果在店铺页面 f5 了之后，组织页面的路由就没了
      //    这时再返回组织页面，也是会前往 404，这里并不是真的 404，而是因为组织页面的路由从未加载过，所以要手动前往一下组织

      // 真正的 404 情况：
      // 1. 如果要访问的是组织的路由，在组织路由已存在的情况下，就真要跳转 404 了
      // 2. 如果要访问的是店铺的路由，在店铺路由不存在的情况下，就真要跳转 404 了
      if (to.redirectedFrom.path.startsWith(shopDefaultPrefix)) {
        if (store.getters.permission[getCookie(SHOP_ID)]) {
          next()
          return
        } else {
          if (
            await loadRouteAndToDefaultFroShop(next, to.redirectedFrom.path)
          ) {
            return
          }
        }
      } else {
        if (store.getters.permission.orgPerm) {
          next()
          return
        } else {
          if (await loadRouteAndToDefaultForOrg(next, to.redirectedFrom.path)) {
            return
          }
        }
      }
      next(to.redirectedFrom.fullPath)
      return
    }
    // #endregion

    if (to.path === '/login') {
      next('/')
      return
    }

    // #region 当前用户信息不存在就加载
    if (!store.getters.hasUserInfo) {
      // 不存在用户信息
      await loadStoreNoLoading('user/getUserInfo')
    }
    // #endregion

    // #region 不存在店铺就去注册
    if (to.path !== '/register' && !store.getters.userInfo.existShop) {
      next('/register')
      return
    }
    // #endregion

    // 判断是否存在权限，分组织和权限不同
    if (to.path.startsWith(shopDefaultPrefix)) {
      // 当前是在店铺中
      if (!store.getters.permission[getCookie(SHOP_ID)]) {
        if (await loadRouteAndToDefaultFroShop(next, to.path)) {
          return
        }

        next(to.fullPath)
        return
      }
    } else {
      // 当前是在组织中
      // 判断组织权限是否已经获取
      if (!store.getters.permission.orgPerm) {
        // 组织权限为空，获取组织权限
        if (await loadRouteAndToDefaultForOrg(next, to.path)) {
          return
        }

        next(to.fullPath)
        return
      }

      const paths = router.getRoutes().map((route) => route.path)
      if (!paths.includes(to.path)) {
        // 权限不包含对应路径，那就访问基础路径
        next(store.getters.orgDefaultIndex)
        return
      }
    }
    next()
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
      return
    }
    // 未登录
    next('/login')
  }
}

const loadRouteAndToDefaultForOrg = async (next, srcPath) => {
  await loadStoreNoLoading('user/getOrgPerm')

  const paths = router.getRoutes().map((route) => route.path)
  if (!paths.includes(srcPath)) {
    // 权限不包含对应路径，那就访问基础路径
    next(store.getters.orgDefaultIndex)
    return true
  }
  return false
}

const loadRouteAndToDefaultFroShop = async (next, srcPath) => {
  await loadStoreNoLoading('user/getShopPerm', getCookie(SHOP_ID))

  const paths = router.getRoutes().map((route) => route.path)
  if (!paths.includes(srcPath)) {
    // 权限不包含对应路径，那就访问基础路径
    next(store.getters.shopDefaultIndex)
    return true
  }
  return false
}

const timeZoneHandeler = new Map([
  [
    0,
    async () => {
      await loadStoreNoLoading('user/getUserTimeZone')
    },
  ],
  [
    1,
    async () => {
      await loadStoreNoLoading('user/getUserTimeZone')
    },
  ],
  [
    2,
    async () => {
      await loadStoreNoLoading('user/getShopTimeZone')
    },
  ],
])

const loadTimeZone = async () => {
  if (!store.getters.timeZone || store.getters.currLocationChange) {
    const handler = timeZoneHandeler.get(store.getters.currLocation)
    await handler()
  }
}

const currentLocation = (to) => {
  if (to.path.startsWith(shopDefaultPrefix)) {
    store.commit('app/setCurrlocation', 2)
  } else if (to.path.startsWith(orgDefaultPrefix)) {
    store.commit('app/setCurrlocation', 1)
  } else {
    store.commit('app/setCurrlocation', 0)
  }
}
