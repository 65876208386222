import {
  shelfAwayList,
  shelfBayList,
  shelfGoodsList,
  addShelfAway,
  updateShelfAway,
  addShelfBay,
  updateShelfBay,
  deleteShelfAway,
  deleteShelfBay,
  queryGoodsCategoryList,
  queryNotInShelfGoodsList,
  toShelfGoodsDetails,
  toSelf,
  shelfGoodsDetails,
  editShelfGoods,
  replenishmentDetails,
  replenishment,
  returnWarehouseDetails,
  returnWarehouse,
  skuWarnList,
  replenishmentDetailsWarn,
  replenishmentWarn,
  spuList,
  skuSettingList,
  setting,
  queryNotInShelfCardList,
  toShelfCardDetails,
  shelfCardDetails,
  toSelfCard,
  editShelfCard,
  cardReplenishmentDetails,
  cardReplenishment,
  returnWarehouseCardDetails,
  cardReturnWarehouse,
  shelfOutList,
  shelfOutDetail,
  shelfOutItemByCode,
  getGoodsCategoryList,
  shelfOutItemPage,
  addShelfOut,
  batchTransfer,
  newSorting,
  removeFromShelf,
  removeMultipleSpecificationsFromShelf,
  batchShelfProductList,
  toSelfBatch,
  getInventoryProductList,
} from '@/api/shop/shelf'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {
    setAisleAndBay(state, data) {
      state.aisleAndBay = data
    },
    // 首页货架预警跳转时使用
    setShelfWarningFlag(state) {
      // 3: 低于预警值
      state.shelfWarningFlag = 3
    },
  },
  actions: {
    async shelfAwayList(context) {
      return await shelfAwayList()
    },
    async shelfBayList(context, id) {
      return await shelfBayList(id)
    },
    async shelfGoodsList(context, params) {
      return await shelfGoodsList(params)
    },
    async addShelfAway(context, data) {
      return await addShelfAway(data)
    },
    async updateShelfAway(context, data) {
      return await updateShelfAway(data)
    },
    async addShelfBay(context, data) {
      return await addShelfBay(data)
    },
    async updateShelfBay(context, data) {
      return await updateShelfBay(data)
    },
    async deleteShelfAway(context, id) {
      return await deleteShelfAway(id)
    },
    async deleteShelfBay(context, id) {
      return await deleteShelfBay(id)
    },
    async queryGoodsCategoryList(context) {
      return await queryGoodsCategoryList()
    },
    async queryNotInShelfGoodsList(context, params) {
      return await queryNotInShelfGoodsList(params)
    },
    async toShelfGoodsDetails(context, id) {
      return await toShelfGoodsDetails(id)
    },
    async toSelf(context, data) {
      return await toSelf(data)
    },
    async shelfGoodsDetails(context, id) {
      return await shelfGoodsDetails(id)
    },
    async editShelfGoods(context, data) {
      return await editShelfGoods(data)
    },
    async replenishmentDetails(context, id) {
      return await replenishmentDetails(id)
    },
    async replenishment(context, data) {
      return await replenishment(data)
    },
    async returnWarehouseDetails(context, id) {
      return await returnWarehouseDetails(id)
    },
    async returnWarehouse(context, data) {
      return await returnWarehouse(data)
    },
    async skuWarnList(context, params) {
      return await skuWarnList(params)
    },
    async replenishmentDetailsWarn(context, id) {
      return await replenishmentDetailsWarn(id)
    },
    async replenishmentWarn(context, data) {
      return await replenishmentWarn(data)
    },
    async spuList(context, params) {
      return await spuList(params)
    },
    async skuSettingList(context, params) {
      const id = params.id
      delete params.id
      return await skuSettingList(id, params)
    },
    async setting(context, data) {
      return await setting(data)
    },
    async queryNotInShelfCardList(context, params) {
      return await queryNotInShelfCardList(params)
    },
    async toShelfCardDetails(context, id) {
      return await toShelfCardDetails(id)
    },
    async shelfCardDetails(context, id) {
      return await shelfCardDetails(id)
    },
    async toSelfCard(context, data) {
      return await toSelfCard(data)
    },
    async editShelfCard(context, data) {
      return await editShelfCard(data)
    },
    async cardReplenishmentDetails(context, id) {
      return await cardReplenishmentDetails(id)
    },
    async cardReplenishment(context, data) {
      return await cardReplenishment(data)
    },
    async returnWarehouseCardDetails(context, id) {
      return await returnWarehouseCardDetails(id)
    },
    async cardReturnWarehouse(context, data) {
      return await cardReturnWarehouse(data)
    },
    async shelfOutList(context, params) {
      return await shelfOutList(params)
    },
    async shelfOutDetail(context, id) {
      return await shelfOutDetail(id)
    },
    async shelfOutItemByCode(context, params) {
      params.code = params.id
      delete params.id
      return await shelfOutItemByCode(params)
    },
    async getGoodsCategoryList(context) {
      return await getGoodsCategoryList()
    },
    async shelfOutItemPage(context, params) {
      return await shelfOutItemPage(params)
    },
    async addShelfOut(context, data) {
      return await addShelfOut(data)
    },
    async batchTransfer(context, data) {
      return await batchTransfer(data)
    },
    async newSorting(context, data) {
      return await newSorting(data)
    },
    async removeFromShelf(context, data) {
      return await removeFromShelf(data)
    },
    async removeMultipleSpecificationsFromShelf(context, data) {
      return await removeMultipleSpecificationsFromShelf(data)
    },
    async batchShelfProductList(context, data) {
      return await batchShelfProductList(data)
    },
    async toSelfBatch(context, data) {
      return await toSelfBatch(data)
    },
    async getInventoryProductList(context, id) {
      return await getInventoryProductList(id)
    },
  },
}
