import request from '@/utils/request'

/**
 * 获取用户是否填写过经营人信息
 */
export const checkWriteOwn = () => {
  return request({
    url: '/user/checkWriteOwn',
    method: 'GET',
  })
}

/**
 * 上传签名照
 */
export const uploadSignature = (data) => {
  return request({
    url: '/user/uploadSignature',
    method: 'POST',
    data,
  })
}

/**
 * 保存经营信息
 */
export const saveBusinessInfo = (data) => {
  return request({
    url: '/user/saveBusinessInfo',
    method: 'POST',
    data,
  })
}

/**
 * 获取个人和经营信息
 */
export const ownInfo = () => {
  return request({
    url: '/user/ownInfo',
    method: 'GET',
  })
}

/**
 * 修改经营信息
 */
export const updateBusinessInfo = (data) => {
  return request({
    url: '/user/updateBusinessInfo',
    method: 'POST',
    data,
  })
}
