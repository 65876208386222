<template>
  <!-- 顶级 Menu 菜单 -->
  <el-menu
    :collapse="!$store.getters.sidebarOpened"
    :default-active="activeMenu"
    :unique-opened="true"
    :background-color="$store.getters.cssVar.menuBg"
    :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.cssVar.menuActiveText"
    router
  >
    <!-- 子级菜单 -->
    <SidebarItem
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></SidebarItem>
  </el-menu>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { filterRouters, generateMenus } from '@/utils/route'
import SidebarItem from './SidebarItem.vue'

const router = useRouter()
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes())
  let routerss = generateMenus(filterRoutes)
  for (let i = 0; i < routerss.length; i++) {
    for (let j = 0; j < filterRoutes.length; j++) {
      if (routerss[i].path === filterRoutes[j].path) {
        routerss[i].meta.sortFlag = filterRoutes[j].meta.sortFlag
      } else if (
        filterRoutes[j].children &&
        filterRoutes[j].children.findIndex(
          (value) => value.path === routerss[i].path,
        ) !== -1
      ) {
        routerss[i].meta.sortFlag = filterRoutes[j].meta.sortFlag
      }
    }
  }
  routerss = routerss.sort((a, b) => a.meta.sortFlag - b.meta.sortFlag)
  return routerss
})

// 取出刷新前最后的点击项
const route = useRoute()
const activeMenu = ref('')
watch(
  route,
  () => {
    const { path, matched } = route
    activeMenu.value = matched.length > 2 ? matched[1].path : path
  },
  {
    immediate: true,
  },
)
</script>

<style lang="scss" scoped></style>
