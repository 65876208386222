<template>
  <div class="hamburger-container" @click="toggleClick">
    <SvgIcon id="guide-hamburger" class="hamburger" :icon="icon"></SvgIcon>
  </div>
</template>

<script setup>
import store from '@/store'
import { computed } from 'vue'
import SvgIcon from '@/components/svg-icon/SvgIcon.vue'

const icon = computed(() =>
  store.getters.sidebarOpened ? 'hamburger-opened' : 'hamburger-closed',
)

const toggleClick = () => {
  store.commit('app/triggerSidebarOpened')
}
</script>

<style lang="scss" scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
</style>
