import {
  getProcureList,
  getGoodsByCode,
  getGoodsList,
  getRawMaterialList,
  getShopCategoryList,
  getRawMateList,
  addProcure,
  delProcure,
  endProcure,
  getProcureDetails,
  editProcure,
  delProcureItem,
  download,
  getFuelCardList,
} from '@/api/shop/purchase'
import { downloadToLocal } from '@/utils/request'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getProcureList(context, params) {
      return await getProcureList(params)
    },
    async getGoodsByCode(context, params) {
      const code = params.id
      delete params.id
      return await getGoodsByCode(code, params)
    },
    async getGoodsList(context, params) {
      return await getGoodsList(params)
    },
    async getRawMaterialList(context, params) {
      return await getRawMaterialList(params)
    },
    async getShopCategoryList(context) {
      return await getShopCategoryList()
    },
    async getRawMateList(context) {
      return await getRawMateList()
    },
    async addProcure(context, data) {
      return await addProcure(data)
    },
    async delProcure(context, id) {
      return await delProcure(id)
    },
    async endProcure(context, id) {
      return await endProcure(id)
    },
    async getProcureDetails(context, id) {
      return await getProcureDetails(id)
    },
    async editProcure(context, data) {
      return await editProcure(data)
    },
    async delProcureItem(context, id) {
      return await delProcureItem(id)
    },
    async download(context, params) {
      const res = await download(params.id)
      downloadToLocal(res.data, params.name + '.pdf')
    },
    async getFuelCardList(context, params) {
      return await getFuelCardList(params)
    },
  },
}
