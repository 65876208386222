import variables from '@/styles/variables.module.scss'

const getters = {
  // 用户信息是否存在
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  // 用户信息
  userInfo: (state) => state.user.userInfo,
  // 用户权限信息
  permission: (state) => state.user.permission,
  // 根据权限设定默认访问组织首页是什么
  orgDefaultIndex: (state) => state.user.orgDefaultIndex,
  // 根据权限设定默认访问店铺首页是什么
  shopDefaultIndex: (state) => state.user.shopDefaultIndex,
  // CSS 样式变量
  cssVar: (state) => variables,
  // 左边菜单项是否打开
  sidebarOpened: (state) => state.app.sidebarOpened,
  // 语言，国际化
  language: (state) => state.app.language,
  // 当前所在页面位置
  currLocation: (state) => state.app.currLocation,
  // 所在结构页面是否发生变化，组织 => 店铺、店铺 => 组织、其他 => 组织、店铺 => 其他 等等
  currLocationChange: (state) => state.app.currLocationChange,
  // Tags 展示
  tagsViewList: (state) => state.app.tagsViewList,
  // 店铺缓存页面数组
  shopViewKeepAliveList: (state) => state.app.shopViewKeepAliveList,
  // 当前时区
  timeZone: (state) => state.app.timeZone,
  // ======================================
  // ================ Org =================
  // 店铺列表
  shopList: (state) => state.org.shopList,
  // 用户列表
  userList: (state) => state.org.userList,
  // 角色列表
  roleList: (state) => state.org.roleList,
  // 店铺角色列表
  shopRoleList: (state) => state.org.shopRoleList,
  // 店铺角色详情列表
  shopRoleDesList: (state) => state.org.shopRoleDesList,
  // ======================================
  // ============== 店铺首页 ===============
  // 分析报告列表
  analysisReportList: (state) => state.shopIndex.analysisReportList,
  // ======================================
  // ============== 在线商品管理 ===============
  // 商品列表
  onlineGoodsList: (state) => state.shopOnlineGoods.onlineGoodsList,
  // ======================================
  // ============== 库存管理 ================
  // 商品库存列表
  stockList: (state) => state.stock.stockList,
}

export default getters
