<template>
  <div class="app-main">
    <!-- 因为二级路由和三级路由都使用的此 template，所以需要在判断只显示对应级数的路由 -->
    <router-view v-slot="twoRoute">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="keepAliveIncludesForTwo">
          <component
            v-if="twoRoute.route.matched.length === 2"
            :is="twoRoute.Component"
            :key="twoRoute.route.path"
          />
        </keep-alive>
      </transition>

      <router-view v-slot="three">
        <transition name="fade-transform" mode="out-in">
          <keep-alive :include="keepAliveIncludesForThree">
            <component
              v-if="three.route.matched.length === 3"
              :is="three.Component"
              :key="three.route.path"
            />
          </keep-alive>
        </transition>
      </router-view>
    </router-view>
  </div>
</template>

<script setup>
const keepAliveIncludesForTwo = []
const keepAliveIncludesForThree = []
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px - 34px);
  width: 100%;
  min-width: 1605px;
  position: relative;
  overflow: hidden;
  padding: 65px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
