import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import org from './modules/org'
import orgUser from './modules/org/user'
import orgAccount from './modules/org/account'
import shopIndex from './modules/shop/index'
import shopGoods from './modules/shop/goods'
import shopOnlineGoods from './modules/shop/onlineGoods'
import shopManage from './modules/shop/shopManage'
import shopOrder from './modules/shop/order'
import shopAfterSales from './modules/shop/afterSales'
import shopWallet from './modules/shop/wallet'
import shopStock from './modules/shop/stock'
import shopGuarantee from './modules/shop/guarantee'
import shopSettings from './modules/shop/settings'
import shopWarehouse from './modules/shop/warehouse'
import shopShelf from './modules/shop/shelf'
import shopPurchase from './modules/shop/purchase'
import shopNotify from './modules/shop/notify'
import getters from './getters'
import pickingTask from './modules/shop/pickingTask'
import onlineOrder from './modules/shop/onlineOrder'
import shopData from './modules/shop/data'

export default createStore({
  getters,
  modules: {
    user,
    app,
    org,
    orgUser,
    orgAccount,
    shopIndex,
    shopGoods,
    shopOnlineGoods,
    shopManage,
    shopOrder,
    shopAfterSales,
    shopWallet,
    shopStock,
    shopGuarantee,
    shopSettings,
    shopWarehouse,
    shopShelf,
    shopPurchase,
    shopNotify,
    pickingTask,
    onlineOrder,
    shopData,
  },
})
