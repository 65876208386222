import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import installIcons from '@/icons'
import installLottie from './plugins/lottie'
import installVxeTable from './plugins/vxe-table'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './styles/index.scss'
import i18n from '@/i18n'
import vueDompurifyHTMLPlugin from 'vue-dompurify-html'
import '@/assets/aliicons/iconfont.css'
import '@/assets/rem/index'
import installSize from '@/directive/size'
import installPerm from '@/directive/perm'

const app = createApp(App)
// Element Plus ICON 插件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// Element Plus 插件
installElementPlus(app)
// 自定义 SVG
installIcons(app)
// 动画
installLottie(app)
// 表格
installVxeTable(app)

// 全局异常捕获
app.config.errorHandler = (err, instance, port) => {
  console.error('errorHandler 全局异常捕获, err: ', err)
  console.error('errorHandler 全局异常捕获, instance: ', instance)
  console.error('errorHandler 全局异常捕获, port: ', port)
}

window.onunhandledrejection = (event) => {
  console.error('onunhandledrejection 全局异常捕获, err: ', event)
}

window.onerror = (event) => {
  console.error('onerror 全局异常捕获, err: ', event)
}

// xss
app.use(vueDompurifyHTMLPlugin)

// 自定义指令
installSize(app)
installPerm(app)

app.use(store).use(router).use(i18n).mount('#app')
