import {
  getSelfPickOrderListDes,
  operSelfPickOrder,
  getSelfPickPrintNum,
  getSelfPickOrderDesById,
  operSelfPickOrderStatus,
  getDeliveryOrderListDes,
  operDeliveryOrder,
  getDeliveryPrintNum,
  getDeliveryOrderDesById,
  operDeliveryOrderStatus,
  getOfflineOrderListDes,
  getOfflineOrderDesById,
  getOfflineGoods,
  getRate,
  getGoodsClassify,
  paid,
  startPayment,
  showTotalPrice,
  getPlaceOrder,
  requestRefund,
  getRefundOrder,
  getOfflineOrderPrintInfo,
  getSkuInfoByOrderId,
  checkOrderTimeExpire,
  getOfflineOrderDesByIdV2,
  getRefundDesById,
  printTicket,
  getGuaranteeServicesListOnSku,
  guaranteeServiceList,
  getMacouUseList,
  getMacouRebateList,
  printRefundTicket,
  getOfflineAmountCollected,
  getMacouBalance,
  getPaymentManyDetail,
  showDeductionValue,
  getRefundMacouUseList,
  getRefundMacouRebateList,
} from '@/api/shop/order'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getSelfPickOrderListDes(context, params) {
      return await getSelfPickOrderListDes(params)
    },
    async operSelfPickOrder(context, data) {
      return await operSelfPickOrder(data)
    },
    async getSelfPickPrintNum(context, id) {
      return await getSelfPickPrintNum(id)
    },
    async getSelfPickOrderDesById(context, id) {
      return await getSelfPickOrderDesById(id)
    },
    async operSelfPickOrderStatus(context, data) {
      return await operSelfPickOrderStatus(data)
    },
    async getDeliveryOrderListDes(context, params) {
      return await getDeliveryOrderListDes(params)
    },
    async operDeliveryOrder(context, data) {
      return await operDeliveryOrder(data)
    },
    async getDeliveryPrintNum(context, id) {
      return await getDeliveryPrintNum(id)
    },
    async getDeliveryOrderDesById(context, id) {
      return await getDeliveryOrderDesById(id)
    },
    async operDeliveryOrderStatus(context, data) {
      return await operDeliveryOrderStatus(data)
    },
    async getOfflineOrderListDes(context, params) {
      return await getOfflineOrderListDes(params)
    },
    async getOfflineOrderDesById(context, id) {
      return await getOfflineOrderDesById(id)
    },
    async getOfflineGoods(context, params) {
      return await getOfflineGoods(params)
    },
    async getRate(context) {
      return await getRate()
    },
    async getGoodsClassify(context) {
      return await getGoodsClassify()
    },
    async paid(context, data) {
      return await paid(data)
    },
    async startPayment(context, data) {
      return await startPayment(data)
    },
    async showTotalPrice(context, data) {
      return await showTotalPrice(data)
    },
    async getPlaceOrder(context, id) {
      return await getPlaceOrder(id)
    },
    async requestRefund(context, data) {
      return await requestRefund(data)
    },
    async getRefundOrder(context, id) {
      return await getRefundOrder(id)
    },
    async getOfflineOrderPrintInfo(context, params) {
      return await getOfflineOrderPrintInfo(params)
    },
    async getSkuInfoByOrderId(context, id) {
      return await getSkuInfoByOrderId(id)
    },
    async checkOrderTimeExpire(context, id) {
      return await checkOrderTimeExpire(id)
    },
    async getOfflineOrderDesByIdV2(context, id) {
      return await getOfflineOrderDesByIdV2(id)
    },
    async getRefundDesById(context, id) {
      return await getRefundDesById(id)
    },
    async printTicket(context, data) {
      return await printTicket(data)
    },
    async getGuaranteeServicesListOnSku(context, id) {
      return await getGuaranteeServicesListOnSku(id)
    },
    async guaranteeServiceList(context, id) {
      return await guaranteeServiceList(id)
    },
    async getMacouUseList(context, id) {
      return await getMacouUseList(id)
    },
    async getMacouRebateList(context, id) {
      return await getMacouRebateList(id)
    },
    async printRefundTicket(context, data) {
      return await printRefundTicket(data)
    },
    async getOfflineAmountCollected(context, id) {
      return await getOfflineAmountCollected(id)
    },
    async getMacouBalance(context, code) {
      return await getMacouBalance(code)
    },
    async getPaymentManyDetail(context, id) {
      return await getPaymentManyDetail(id)
    },
    async showDeductionValue(context, data) {
      return await showDeductionValue(data)
    },
    async getRefundMacouUseList(context, id) {
      return await getRefundMacouUseList(id)
    },
    async getRefundMacouRebateList(context, id) {
      return await getRefundMacouRebateList(id)
    },
  },
}
