import {
  getAfterSalesListDes,
  operAfterSales,
  getAfterSalesDesById,
} from '@/api/shop/afterSales'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getAfterSalesListDes(context, params) {
      return await getAfterSalesListDes(params)
    },
    async operAfterSales(context, data) {
      return await operAfterSales(data)
    },
    async getAfterSalesDesById(context, id) {
      return await getAfterSalesDesById(id)
    },
  },
}
