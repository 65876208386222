import i18n from '@/i18n'
import { getAllPerm } from '@/utils/perm'
import { ElMessage } from 'element-plus'

export default (app) => {
  app.directive('perm', {
    mounted: permMonitor,
  })
}

/**
 * 资源权限、接口权限都算计进去
 *  资源权限：代表着一个个页面。有些页面（比如A）存在一些按钮，可以直接跳转到（B页面），但是只具有 A 页面的权限，并没有 B 页面的权限，所以这时 A 页面去往 B 页面的按钮就不该显示。
 *  接口权限：代表着页面上一个个按钮，比如新增、详情、删除等。
 */
const permMonitor = (el, binding, vnode, prevNode) => {
  if (binding.modifiers.noClick) {
    // 没有对应权限，不给点击，写法：v-perm.noClick="'orgViewOrgRole'"

    if (!isTherePermission(binding)) {
      el.addEventListener('click', (event) => {
        ElMessage.warning(
          i18n.global.t('msg.common.sorryInsufficientPermissions'),
        )
        event.stopPropagation()
      })
    }
  } else {
    // 常规权限校验，写法：v-perm:orgViewOrgRole

    if (!isTherePermission(binding)) {
      el.remove()
    }
  }
}

/**
 * 验证是否拥有权限
 *
 * 参考：https://cn.vuejs.org/guide/reusability/custom-directives.html#hook-arguments
 *
 * @param {*} binding 指令绑定的值，包含了需要验证的权限
 */
const isTherePermission = (binding) => {
  const permArr = getAllPerm()

  if (binding.arg === 'or') {
    // 任意一个权限
    if (!binding.value.some((item) => permArr.includes(item))) {
      return false
    }
  } else if (binding.arg === 'and') {
    // 所有权限
    if (!binding.value.every((item) => permArr.includes(item))) {
      return false
    }
  } else {
    // 固定单个权限
    if (!permArr.includes(binding.arg)) {
      return false
    }
  }

  return true
}
