import {
  warehouseAreaList,
  warehouseLocationList,
  addWarehouseArea,
  updateWarehouseArea,
  deleteWarehouseArea,
  addWarehouseLocation,
  updateWarehouseLocation,
  deleteWarehouseLocation,
  warehouseLocationGoodsList,
  warehouseInList,
  purcOrderList,
  purcOrderDetail,
  warehouseAreaListIn,
  warehouseLocationListIn,
  purcOrderInWarehouse,
  warehouseOutList,
  warehouseInDetail,
  warehouseOutDetail,
  download,
  getGoodsCategoryList,
  getRawMateCategoryList,
  warehouseOutItemByCode,
  warehouseOutItemPage,
  addWarehouseOut,
  batchTransfer,
  newSorting,
} from '@/api/shop/warehouse'
import { downloadToLocal } from '@/utils/request'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async warehouseAreaList(context) {
      return await warehouseAreaList()
    },
    async warehouseLocationList(context, id) {
      return await warehouseLocationList(id)
    },
    async addWarehouseArea(context, data) {
      return await addWarehouseArea(data)
    },
    async updateWarehouseArea(context, data) {
      return await updateWarehouseArea(data)
    },
    async deleteWarehouseArea(context, id) {
      return await deleteWarehouseArea(id)
    },
    async addWarehouseLocation(context, data) {
      return await addWarehouseLocation(data)
    },
    async updateWarehouseLocation(context, data) {
      return await updateWarehouseLocation(data)
    },
    async deleteWarehouseLocation(context, id) {
      return await deleteWarehouseLocation(id)
    },
    async warehouseLocationGoodsList(context, params) {
      return await warehouseLocationGoodsList(params)
    },
    async warehouseInList(context, params) {
      return await warehouseInList(params)
    },
    async purcOrderList(context, params) {
      return await purcOrderList(params)
    },
    async purcOrderDetail(context, id) {
      return await purcOrderDetail(id)
    },
    async warehouseAreaListIn(context) {
      return await warehouseAreaListIn()
    },
    async warehouseLocationListIn(context, id) {
      return await warehouseLocationListIn(id)
    },
    async purcOrderInWarehouse(context, data) {
      return await purcOrderInWarehouse(data)
    },
    async warehouseOutList(context, params) {
      return await warehouseOutList(params)
    },
    async warehouseInDetail(context, id) {
      return await warehouseInDetail(id)
    },
    async warehouseOutDetail(context, id) {
      return await warehouseOutDetail(id)
    },
    async download(context, params) {
      const res = await download(params.id)
      downloadToLocal(res.data, params.name + '.pdf')
    },
    async getGoodsCategoryList(context) {
      return await getGoodsCategoryList()
    },
    async getRawMateCategoryList(context) {
      return await getRawMateCategoryList()
    },
    async warehouseOutItemByCode(context, params) {
      params.code = params.id
      delete params.id
      return await warehouseOutItemByCode(params)
    },
    async warehouseOutItemPage(context, params) {
      return await warehouseOutItemPage(params)
    },
    async addWarehouseOut(context, data) {
      return await addWarehouseOut(data)
    },
    async batchTransfer(context, data) {
      return await batchTransfer(data)
    },
    async newSorting(context, data) {
      return await newSorting(data)
    },
  },
}
