import { useCookies } from 'vue3-cookies'
import { LANG } from '@/constant'
import { jsonBig } from './json'

const { cookies } = useCookies()

export const setCookie = (key, value, expireTimes = -1, path = '/', domain) => {
  if (!domain) {
    domain = process.env.VUE_APP_BASE_COOKIE_DOMAIN
  }

  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  cookies.set(key, value, expireTimes, path, domain)
}

export const getCookie = (key) => {
  const data = cookies.get(key)
  try {
    return jsonBig.parse(data)
  } catch (err) {
    return data
  }
}

export const removeCookie = (key) => {
  cookies.remove(key)
}

/**
 * 至少保留国际化
 */
export const removeAllCookies = (keepKeys = [LANG]) => {
  const keepVal = {}
  if (keepKeys) {
    keepKeys.forEach((key) => {
      const value = getCookie(key)
      if (value) {
        keepVal[key] = value
      }
    })
  }

  const allCookies = cookies.getAll()
  Object.keys(allCookies).forEach((key) => {
    cookies.remove(key)
  })

  for (const val in keepVal) {
    setCookie(val, keepVal[val])
  }
}
