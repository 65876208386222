import request from '@/utils/request'

/**
 * 通知计数
 */
export const notificationCount = () => {
  return request({
    url: '/shop/systemConfiguration/notificationCount',
    method: 'GET',
  })
}

/**
 * 通知列表
 */
export const notificationList = (params) => {
  return request({
    url: '/shop/systemConfiguration/notificationList',
    method: 'GET',
    params,
  })
}

/**
 * 核实通知
 */
export const verificationNotification = (id) => {
  return request({
    url: `/shop/systemConfiguration/verificationNotification/${id}`,
    method: 'POST',
  })
}
