import {
  getGuaranteeServiceTypeList,
  configurationInformation,
  configuredInformation,
  saveNotification,
} from '@/api/shop/settings'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getGuaranteeServiceTypeList(context) {
      return await getGuaranteeServiceTypeList()
    },
    async configurationInformation(context) {
      return await configurationInformation()
    },
    async configuredInformation(context) {
      return await configuredInformation()
    },
    async saveNotification(context, data) {
      return await saveNotification(data)
    },
  },
}
