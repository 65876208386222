<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { computed } from 'vue'
import store from './store'
// 导入 Element Plus 语言包
import zh from 'element-plus/dist/locale/zh-cn'
import en from 'element-plus/dist/locale/en'

const language = computed(() => store.getters.language)
const lang = {
  'zh-CN': zh,
  en,
}

const locale = computed(() => {
  return lang[language.value]
})
</script>

<style></style>
