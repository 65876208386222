import OrgLayoutView from '@/layout/org/LayoutView'
import { orgDefaultIndex } from '.'

/**
 * 注意：
 *  1. 不要乱修改 path，如果要修改，就全局搜一下
 *  2. resFlag 属性的作用：第一次动态生成路由后，如果切换店铺，需要把 resFlag = true 的都清理掉，重新生成，不然多个店铺之间切换会出现本不该属于其的资源权限。
 */

/**
 * 独立路由器
 */
export const orgIndependentRouters = [
  {
    path: '/settings',
    name: 'SettingsViewView',
    component: () => import('@/layout/settings/SettingsView.vue'),
    redirect: '/settings/accountSecurity',
    children: [
      {
        path: '/settings/personalCenter',
        name: 'PersonalCenterView',
        component: () =>
          import('@/views/settings/personal-center/PersonalCenterView.vue'),
      },
      {
        path: '/settings/accountSecurity',
        name: 'AccountSecurityView',
        component: () =>
          import('@/views/settings/account-security/AccountSecurityView.vue'),
      },
      {
        path: '/settings/loginProtection',
        name: 'LoginProtectionView',
        component: () =>
          import('@/views/settings/login-protection/LoginProtectionView.vue'),
      },
    ],
  },
]

/**
 * 私有路由表
 */
export const orgPrivateRoutes = {
  // #region 店铺选择
  OrgLayoutShopSelectView: {
    path: orgDefaultIndex,
    name: 'OrgLayoutShopSelectView',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: OrgLayoutView,
    meta: {
      sortFlag: 1,
    },
    children: [],
  },
  // 店铺选择
  ShopSelectView: {
    path: '/org/shopSelect',
    name: 'ShopSelectView',
    p: 'OrgLayoutShopSelectView',
    component: () => import('@/views/shop-select/ShopSelectView'),
    meta: {
      title: 'shopSelect',
      icon: 'icon-massho-b-xuanze',
      sortFlag: 1,
      resFlag: true,
    },
  },
  // #endregion
  // #region 账号管理
  OrgLayoutUserManageView: {
    path: '/org/account',
    name: 'OrgLayoutUserManageView',
    component: OrgLayoutView,
    meta: {
      title: 'account',
      icon: 'icon-massho-b-zhanghaoguanli',
      sortFlag: 2,
    },
    children: [],
  },
  // 用户管理
  UserManageView: {
    path: '/org/account/user-manage',
    name: 'UserManageView',
    p: 'OrgLayoutUserManageView',
    component: () => import('@/views/account/user-manage/UserManageView'),
    meta: {
      title: 'userManage',
      icon: 'icon-massho-b-yonghu',
      resFlag: true,
    },
  },
  // 组织角色管理
  RoleManageView: {
    path: '/org/account/role-manage',
    name: 'RoleManageView',
    p: 'OrgLayoutUserManageView',
    component: () => import('@/views/account/role-manage/RoleManageView'),
    meta: {
      title: 'roleManage',
      icon: 'icon-massho-b-zuzhiqunzu',
      resFlag: true,
    },
  },
  // 店铺角色管理
  ShopRoleManageView: {
    path: '/org/account/shop-role-manage',
    name: 'ShopRoleManageView',
    p: 'OrgLayoutUserManageView',
    component: () =>
      import('@/views/account/shop-role-manage/ShopRoleManageView'),
    meta: {
      title: 'shopRoleManage',
      icon: 'icon-massho-b-dianpu',
      resFlag: true,
    },
    children: [
      // 店铺角色
      {
        path: '/org/account/shop-role',
        name: 'ShopRoleView',
        component: () =>
          import('@/views/account/shop-role-manage/ShopRoleView'),
        meta: {
          title: 'storePosition',
        },
      },
    ],
  },
  // #endregion
}
