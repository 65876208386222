import request, { requestDownload } from '@/utils/request'

/**
 * 库区列表
 */
export const warehouseAreaList = () => {
  return request({
    url: '/shop/warehouse/warehouseAreaList',
    method: 'GET',
  })
}

/**
 * 库位列表
 */
export const warehouseLocationList = (id) => {
  return request({
    url: `/shop/warehouse/warehouseLocationList/${id}`,
    method: 'GET',
  })
}

/**
 * 库区新增
 */
export const addWarehouseArea = (data) => {
  return request({
    url: '/shop/warehouse/addWarehouseArea',
    method: 'POST',
    data,
  })
}

/**
 * 库区修改
 */
export const updateWarehouseArea = (data) => {
  return request({
    url: '/shop/warehouse/updateWarehouseArea',
    method: 'POST',
    data,
  })
}

/**
 * 库区删除
 */
export const deleteWarehouseArea = (id) => {
  return request({
    url: `/shop/warehouse/deleteWarehouseArea/${id}`,
    method: 'DELETE',
  })
}

/**
 * 库位新增
 */
export const addWarehouseLocation = (data) => {
  return request({
    url: '/shop/warehouse/addWarehouseLocation',
    method: 'POST',
    data,
  })
}

/**
 * 库位修改
 */
export const updateWarehouseLocation = (data) => {
  return request({
    url: '/shop/warehouse/updateWarehouseLocation',
    method: 'POST',
    data,
  })
}

/**
 * 库位删除
 */
export const deleteWarehouseLocation = (id) => {
  return request({
    url: `/shop/warehouse/deleteWarehouseLocation/${id}`,
    method: 'DELETE',
  })
}

/**
 * 库位商品列表
 */
export const warehouseLocationGoodsList = (params) => {
  return request({
    url: '/shop/warehouse/warehouseLocationGoodsList',
    method: 'GET',
    params,
  })
}

/**
 * 入库信息分页
 */
export const warehouseInList = (params) => {
  return request({
    url: '/shop/warehousein/warehouseInList',
    method: 'GET',
    params,
  })
}

/**
 * 查询采购订单列表
 */
export const purcOrderList = (params) => {
  return request({
    url: '/shop/warehousein/purcOrderList',
    method: 'GET',
    params,
  })
}

/**
 * 根据采购订单id查询采购订单详情
 */
export const purcOrderDetail = (id) => {
  return request({
    url: `/shop/warehousein/purcOrderDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 库区下拉框
 */
export const warehouseAreaListIn = () => {
  return request({
    url: '/shop/warehousein/warehouseAreaList',
    method: 'GET',
  })
}

/**
 * 库位下拉框
 */
export const warehouseLocationListIn = (id) => {
  return request({
    url: `/shop/warehousein/warehouseLocationList/${id}`,
    method: 'GET',
  })
}

/**
 * 采购入库
 */
export const purcOrderInWarehouse = (data) => {
  return request({
    url: '/shop/warehousein/purcOrderInWarehouse',
    method: 'POST',
    data,
  })
}

/**
 * 出库信息分页
 */
export const warehouseOutList = (params) => {
  return request({
    url: '/shop/warehouseout/warehouseOutList',
    method: 'GET',
    params,
  })
}

/**
 * 入库信息详情
 */
export const warehouseInDetail = (id) => {
  return request({
    url: `/shop/warehousein/warehouseInDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 出库信息详情
 */
export const warehouseOutDetail = (id) => {
  return request({
    url: `/shop/warehouseout/warehouseOutDetail/${id}`,
    method: 'GET',
  })
}

/**
 * 入库单下载
 */
export const download = (id) => {
  return requestDownload({
    url: `/shop/warehousein/download/${id}`,
    method: 'GET',
    responseType: 'blob',
  })
}

/**
 * 商品分类列表
 */
export const getGoodsCategoryList = () => {
  return request({
    url: '/shop/warehouseout/getGoodsCategoryList',
    method: 'GET',
  })
}

/**
 * 原材料分类列表
 */
export const getRawMateCategoryList = () => {
  return request({
    url: '/shop/warehouseout/getRawMateCategoryList',
    method: 'GET',
  })
}

/**
 * 根据条码查询对应的商品/原材料/充值卡
 */
export const warehouseOutItemByCode = (params) => {
  return request({
    url: '/shop/warehouseout/warehouseOutItemByCode',
    method: 'GET',
    params,
  })
}

/**
 * 出库新建筛选商品/原材料/充值卡分页
 */
export const warehouseOutItemPage = (params) => {
  return request({
    url: '/shop/warehouseout/warehouseOutItemPage',
    method: 'GET',
    params,
  })
}

/**
 * 新增出库单
 */
export const addWarehouseOut = (data) => {
  return request({
    url: '/shop/warehouseout/addWarehouseOut',
    method: 'POST',
    data,
  })
}

/**
 * 批量转移
 */
export const batchTransfer = (data) => {
  return request({
    url: '/shop/warehouse/batchTransfer',
    method: 'POST',
    data,
  })
}

/**
 * 库区库位拖拽排序
 */
export const newSorting = (data) => {
  return request({
    url: '/shop/warehouse/newSorting',
    method: 'POST',
    data,
  })
}
