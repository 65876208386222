import {
  getOnlineGoodsList,
  getTimePeriod,
  getShopConfig,
  getCards,
  saveShopConfig,
  addCard,
  getServiceIndustryList,
  getShopInfo,
  sendEmailCode,
  checkingEmailCode,
  sendShopPhoneCode,
  checkingShopPhoneCode,
  saveShopInfo,
  genAuthorizeUrl,
  callback,
  shopConfigurationCompleted,
  getCashierCounterList,
  addCashierCounter,
  bindingPos,
  getAvailableCashierCounterList,
  getAvailableAndPrintCashierCounterList,
  getShopConfigV2,
  saveShopConfigV2,
  getShopInfoV2,
  saveShopInfoV2,
  getPickupSettings,
  pickupSettings,
  getState,
  getCity,
  getZipCode,
  getDeliverySettings,
  searchCityByPostalCode,
  deliverySettings,
  getTicketList,
  reloadTicket,
  cashCounterList,
  addTicket,
  ticketDetails,
  updateTicket,
  printTest,
  copyUrl,
  setTicketList,
  updateMainTicket,
  unBindPos,
  editCashierCounter,
  deleteCashierCounter,
} from '@/api/shop/shopManage'

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async getOnlineGoodsList(context) {
      return await getOnlineGoodsList()
    },
    async getTimePeriod(context) {
      return await getTimePeriod()
    },
    async getShopConfig(context) {
      return await getShopConfig()
    },
    async getCards(context) {
      return await getCards()
    },
    async saveShopConfig(context, data) {
      return await saveShopConfig(data)
    },
    async addCard(context, data) {
      return await addCard(data)
    },
    async getServiceIndustryList(context) {
      return await getServiceIndustryList()
    },
    async getShopInfo(context) {
      return await getShopInfo()
    },
    async sendEmailCode(context, data) {
      return await sendEmailCode(data)
    },
    async checkingEmailCode(context, data) {
      return await checkingEmailCode(data)
    },
    async sendShopPhoneCode(context, data) {
      return await sendShopPhoneCode(data)
    },
    async checkingShopPhoneCode(context, data) {
      return await checkingShopPhoneCode(data)
    },
    async saveShopInfo(context, data) {
      return await saveShopInfo(data)
    },
    async genAuthorizeUrl(context) {
      return await genAuthorizeUrl()
    },
    async callback(context, params) {
      return await callback(params)
    },
    async shopConfigurationCompleted(context) {
      return await shopConfigurationCompleted()
    },
    async getCashierCounterList(context) {
      return await getCashierCounterList()
    },
    async addCashierCounter(context, data) {
      return await addCashierCounter(data)
    },
    async bindingPos(context, id) {
      return await bindingPos(id)
    },
    async getAvailableCashierCounterList(context) {
      return await getAvailableCashierCounterList()
    },
    async getAvailableAndPrintCashierCounterList(context) {
      return await getAvailableAndPrintCashierCounterList()
    },
    async getShopConfigV2(context) {
      return await getShopConfigV2()
    },
    async saveShopConfigV2(context, data) {
      return await saveShopConfigV2(data)
    },
    async getShopInfoV2(context) {
      return await getShopInfoV2()
    },
    async saveShopInfoV2(context, data) {
      return await saveShopInfoV2(data)
    },
    async getPickupSettings(context) {
      return await getPickupSettings()
    },
    async pickupSettings(context, data) {
      return await pickupSettings(data)
    },
    async getState(context) {
      return await getState()
    },
    async getCity(context, id) {
      return await getCity(id)
    },
    async getZipCode(context, id) {
      return await getZipCode(id)
    },
    async getDeliverySettings(context) {
      return await getDeliverySettings()
    },
    async searchCityByPostalCode(context, data) {
      return await searchCityByPostalCode(data)
    },
    async deliverySettings(context, data) {
      return await deliverySettings(data)
    },
    async getTicketList(context, params) {
      return await getTicketList(params)
    },
    async reloadTicket(context, id) {
      return await reloadTicket(id)
    },
    async cashCounterList(context) {
      return await cashCounterList()
    },
    async addTicket(context, data) {
      return await addTicket(data)
    },
    async ticketDetails(context, id) {
      return await ticketDetails(id)
    },
    async updateTicket(context, data) {
      return await updateTicket(data)
    },
    async printTest(context, id) {
      return await printTest(id)
    },
    async copyUrl(context, id) {
      return await copyUrl(id)
    },
    async setTicketList(context) {
      return await setTicketList()
    },
    async updateMainTicket(context, data) {
      return await updateMainTicket(data)
    },
    async unBindPos(context, id) {
      return await unBindPos(id)
    },
    async editCashierCounter(context, data) {
      return await editCashierCounter(data)
    },
    async deleteCashierCounter(context, id) {
      return await deleteCashierCounter(id)
    },
  },
}
