import request from '@/utils/request'

/**
 * 邮箱后缀
 */
export const queryBusiUserEmailSuffix = () => {
  return request({
    url: '/user/sub/queryBusiUserEmailSuffix',
    method: 'GET',
  })
}

/**
 * 组织角色列表
 */
export const queryBusiRoleList = () => {
  return request({
    url: '/user/sub/queryBusiRoleList',
    method: 'GET',
  })
}

/**
 * 用户列表
 */
export const employeeList = (params) => {
  return request({
    url: '/user/sub/employeeList',
    method: 'GET',
    params,
  })
}

/**
 * 新建用户
 */
export const addAccount = (data) => {
  return request({
    url: '/user/sub/addAccount',
    method: 'POST',
    data,
  })
}

/**
 * 变更状态
 */
export const changeStatus = (data) => {
  return request({
    url: '/user/sub/changeStatus',
    method: 'POST',
    data,
  })
}

/**
 * 根据员工id查询详情
 */
export const queryBusiRoleDetailsBySubId = (id) => {
  return request({
    url: `/user/sub/queryBusiRoleDetailsBySubId/${id}`,
    method: 'GET',
  })
}

/**
 * 修改员工
 */
export const updateAccount = (data) => {
  return request({
    url: '/user/sub/updateAccount',
    method: 'POST',
    data,
  })
}

/**
 * 删除员工
 */
export const delEmployeeById = (id) => {
  return request({
    url: `/user/sub/delEmployeeById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 根据员工id查询店铺角色
 */
export const queryShopRoleBySubId = (id) => {
  return request({
    url: `/user/sub/queryShopRoleBySubId/${id}`,
    method: 'GET',
  })
}

/**
 * 修改子账号的店铺角色
 */
export const updateUserSubShopRole = (data) => {
  return request({
    url: '/user/sub/updateUserSubShopRole',
    method: 'POST',
    data,
  })
}

/**
 * 查询店铺所属的角色
 */
export const queryShopRoleByShopId = (id) => {
  return request({
    url: `/user/sub/queryShopRoleByShopId/${id}`,
    method: 'GET',
  })
}

/**
 * 重置密码
 */
export const resetPwd = (data) => {
  return request({
    url: '/user/sub/resetPwd',
    method: 'POST',
    data,
  })
}

/**
 * 组织角色列表
 */
export const busiRoleList = (params) => {
  return request({
    url: '/org/busirole/busiRoleList',
    method: 'GET',
    params,
  })
}

/**
 * 根据角色id查询关联的账号
 */
export const queryBusiRoleRelAccountById = (id) => {
  return request({
    url: `/org/busirole/queryBusiRoleRelAccountById/${id}`,
    method: 'GET',
  })
}

/**
 * 获取组织角色的资源列表
 */
export const getBusiRoleResList = () => {
  return request({
    url: '/org/busirole/getBusiRoleResList',
    method: 'GET',
  })
}

/**
 * 新增组织角色
 */
export const addBusiRole = (data) => {
  return request({
    url: '/org/busirole/addBusiRole',
    method: 'POST',
    data,
  })
}

/**
 * 根据角色id 查询详情
 */
export const queryBusiRoleById = (id) => {
  return request({
    url: `/org/busirole/queryBusiRoleById/${id}`,
    method: 'GET',
  })
}

/**
 * 编辑组织角色
 */
export const updateBusiRole = (data) => {
  return request({
    url: '/org/busirole/updateBusiRole',
    method: 'POST',
    data,
  })
}

/**
 * 删除组织角色
 */
export const delBusiRoleById = (id) => {
  return request({
    url: `/org/busirole/delBusiRoleById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 店铺分页
 */
export const getShopSelect = () => {
  return request({
    url: '/org/shoprole/getShopSelect',
    method: 'GET',
  })
}

/**
 * 店铺角色分页
 */
export const shopRoleList = (params) => {
  return request({
    url: '/org/shoprole/shopRoleList',
    method: 'GET',
    params,
  })
}

/**
 * 根据角色id查询关联的账号
 */
export const queryShopRoleRelAccountById = (id) => {
  return request({
    url: `/org/shoprole/queryShopRoleRelAccountById/${id}`,
    method: 'GET',
  })
}

/**
 * 获取店铺下的资源列表
 */
export const getShopRoleResList = (id) => {
  return request({
    url: `/org/shoprole/getShopRoleResList/${id}`,
    method: 'GET',
  })
}

/**
 * 新增店铺角色
 */
export const addShopRole = (data) => {
  return request({
    url: '/org/shoprole/addShopRole',
    method: 'POST',
    data,
  })
}

/**
 * 查询店铺角色详情
 */
export const queryShopRoleById = (id) => {
  return request({
    url: `/org/shoprole/queryShopRoleById/${id}`,
    method: 'GET',
  })
}

/**
 * 删除店铺角色
 */
export const delShopRoleById = (id) => {
  return request({
    url: `/org/shoprole/delShopRoleById/${id}`,
    method: 'DELETE',
  })
}

/**
 * 店铺分页
 */
export const shopList = (params) => {
  return request({
    url: '/org/shoprole/shopList',
    method: 'GET',
    params,
  })
}

/**
 * 根据店铺id查询关联的账号
 */
export const queryShopRoleRelAccountByShopId = (id) => {
  return request({
    url: `/org/shoprole/queryShopRoleRelAccountByShopId/${id}`,
    method: 'GET',
  })
}

/**
 * 编辑店铺角色
 */
export const updateShopRole = (data) => {
  return request({
    url: '/org/shoprole/updateShopRole',
    method: 'POST',
    data,
  })
}

/**
 * 获取会话超时时间
 */
export const getSessionTime = () => {
  return request({
    url: '/org/busirole/getSessionTime',
    method: 'GET',
  })
}
